export const transformSimpleSearchServerData = serverResult => {
  console.log("Server result", serverResult);
  const { authorityUserList, endCustomerList } = serverResult;
  const dataSet =
    authorityUserList.length > 0 ? authorityUserList : endCustomerList;
  return dataSet;
};


export const transformAdvanceSearchServerData = serverResult => {
  console.log("Server result", serverResult);
  const { authorityUserPage, endCustomerPage } = serverResult;
  const dataPage = authorityUserPage || endCustomerPage;
  if (!dataPage) return null;
  console.log("Available data", dataPage);
  const transformedData = {
    data: [],
    pagination: {
      total: dataPage.totalElements,
      current: dataPage.pageable.pageNumber + 1 || 1
    }
  };
  transformedData.data = dataPage.content;
  return transformedData;
};

export const transformToTableData = unpresentableData => {
  console.log("Unpresentable data", unpresentableData);

  const presentableData = unpresentableData.map(obj => {
    let address = obj.person.addressLine1;
    if (!address){
      address = "";
    }else if (obj.person.addressLine2) {
      address = address + " " + obj.person.addressLine2;
    }
      
    return {
      key: obj.id,
      name: obj.person.firstName,
      phone: obj.person.primaryPhoneNumber,
      userTypeId: obj.userType.id,
      address
    };
  });

  return presentableData;
};
