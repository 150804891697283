import React, { Component } from "react";
import { Form, Row, Col, Button, Table, Spin } from "antd";
import { NavLink } from "react-router-dom";
import { warrantySimpleSearch } from "../../../ApiCalls/warranty-call";

const columns = [
  {
    title: "Serial No",
    dataIndex: "serial",
    fixed: "left",
    width: 150,
    key: "serial"
  },
  {
    title: "Customer Phone Number",
    dataIndex: "cusPhone",
    key: "cusPhone"
  },
  {
    title: "Selling Date",
    dataIndex: "selling",
    key: "selling"
  },
  {
    title: "Start Date",
    dataIndex: "start",
    key: "start"
  },
  {
    title: "Expire Date",
    dataIndex: "expire",
    key: "expire"
  },
  {
    title: "Action",
    key: "action",
    fixed: "right",
    width: 150,
    render: (text, record) => (
      <span>
        <NavLink
          to="/warranty/view-warranty-details"
          onClick={() => sessionStorage.setItem("serial", record.serial)}
        >
          Change Status
        </NavLink>
      </span>
    )
  }
];

class ViewWarrantyTable extends Component {
  state = {
    data: [],
    loading: false
  };

  componentWillMount() {
    this.getWarranty();
  }

  getWarranty = () => {
    this.setState({ loading: true });
    const phoneNo = sessionStorage.getItem("cusPhone");
    warrantySimpleSearch(
      { endCustomerPhoneNumber: phoneNo, uniqueSerialNumber: "" },
      100,
      1
    )
      .then(response => {
        console.log("received Data from server", response);
        this.setTableItem(response.content);
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  setTableItem = data => {
    console.log("received Data", data);
    const dataArray = [];
    for (let i = 0; i < data.length; i++) {
      dataArray.push({
        key: data[i].id,
        serial: data[i].uniqueSerialNumber,
        cusPhone: data[i].endCustomerPhoneNumber,
        selling: data[i].sellDate,
        start: data[i].warrantyStartDate,
        expire: data[i].warrantyEndDate
      });
    }

    this.setState({ data: dataArray });
  };

  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Row type="flex" justify="center">
            <Col xs={24}>
              <Row type="flex" justify="start">
                <Form.Item style={{}}>
                  <Button
                    type="primary"
                    size="large"
                    icon="redo"
                    className="login-form-button"
                    onClick={() => this.getWarranty}
                  >
                    Refresh
                  </Button>
                </Form.Item>
              </Row>
              <Row type="flex" justify="end">
                <p className="formTitle" style={{}}>
                  VIEW WARRANTY
                </p>
              </Row>

              <br />
              <div className="table-container">
                <Table
                  columns={columns}
                  dataSource={this.state.data}
                  bordered
                  className="table-header"
                  scroll={{ x: 1500 }}
                />
              </div>
              <br />
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }
}

export default Form.create()(ViewWarrantyTable);
