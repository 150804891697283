import React from "react";
import { Row, Col, Form, Input, Select, DatePicker, Button } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { fetchUserTypesStartAsync } from "../../../Redux/user-types/user-types.actions";

const { Option } = Select;

class AdvancedUserSearchForm extends React.Component {
  checkDate = {
    addedDateTime: {
      dateTagName: "addedDateTime",
      dateTagValue: "System Added Date"
    },
    lastModifiedDateTime: {
      dateTagName: "lastModifiedDateTime",
      dateTagValue: "Last Modified Date"
    }
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchUserTypesStartAsync();
  }

  onCheckDateChange = () => {
    this.props.form.setFieldsValue({
      startDate: moment(new Date(), "YYYY-MM-DD").subtract(1, "months"),
      endDate: moment(new Date(), "YYYY-MM-DD")
    });
  };

  disabledDate = current => {
    return current > moment().endOf("day");
  };

  formValuesToServerParams = values => {
    return {
      userType: values.userType,
      dateTagName: values.checkDate
        ? this.checkDate[values.checkDate].dateTagName
        : null,
      dateTagValue: values.checkDate
        ? this.checkDate[values.checkDate].dateTagValue
        : null,
      startDate: values.startDate
        ? values.startDate.format("YYYY-MM-DD")
        : null,
      endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null
    };
  };

  handleSubmit = event => {
    event.preventDefault();
    const { form, onSearch } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) return;
      const params = this.formValuesToServerParams(values);
      console.log("Params", params);
      onSearch(params);
    });
  };

  render() {
    const { userTypes, form } = this.props;

    const { getFieldDecorator } = form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="User Type">
              {getFieldDecorator("userType", {
                rules: [
                  {
                    required: true,
                    message: "Please select a user type."
                  }
                ]
              })(
                <Select size="large" placeholder="Select a user type">
                  {userTypes.map(userType => (
                    <Option key={userType.key} value={userType.userType}>
                      {userType.userType}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item label="Check Date">
              {getFieldDecorator("checkDate", {
                rules: [
                  {
                    required: false,
                    message: "Please select a check date"
                  }
                ]
              })(
                <Select
                  size="large"
                  placeholder="Select check date"
                  onChange={this.onCheckDateChange}
                >
                  <Option key="addedDateTime">System Added Date</Option>
                  <Option key="lastModifiedDateTime">Last Modified Date</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="Start Date">
              {getFieldDecorator("startDate", {
                rules: [
                  {
                    required: false,
                    message: "Please input a start date."
                  }
                ]
              })(<DatePicker size="large" />)}
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item label="End Date">
              {getFieldDecorator("endDate", {
                rules: [
                  {
                    required: false,
                    message: "Please input an end date."
                  }
                ]
              })(<DatePicker size="large" disabledDate={this.disabledDate} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button size="large" type="primary" icon="search" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = ({ userTypes }) => ({
  userTypes: userTypes.types
});
const mapDispatchToProps = dispatch => ({
  fetchUserTypesStartAsync: () => {
    dispatch(fetchUserTypesStartAsync());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AdvancedUserSearchForm));
