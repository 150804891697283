import { Card, Icon, Avatar, Form, Row, Col, Button } from 'antd';
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import Swal from "sweetalert2";
const { Meta } = Card;



class ViewPromotionFormAnt extends Component {

  state = {
    data: []
  };

  componentDidMount() {
    var self = this
    axios.get(`/api/promotion/promotion/all`)
      .then(function (response) {
        if (response.data.length > 0) {
          self.setState({ data: response.data });
          cogoToast.success("Successfully loaded.");
        } else {
          Swal.fire("Error!", "No Promotions!", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error!", "Server Failed!", "error");
      });
  }

  render() {
    const promotionlist = this.state.data.map(item => (

      <Col style={{ padding: 5 }} xs={24} md={8}>

        <div onClick={() => {sessionStorage.setItem("itemdetails",JSON.stringify(item))}}>
        <Card
          hoverable
          extra={<button><Link to={'view-promotion/details/'} >View Offer</Link></button>}
          cover={
            <img
            width="300"
            height="170"
              alt="image"
              src={"https://damp-peak-37237.herokuapp.com/api/promotion/promotion"+item.fileDownloadUri}
            />
          }
        >
          <Meta
            title={item.title}
          />
          
          <p></p>
          <p>Validity : {item.endDate}</p>
        </Card>
        </div>
      </Col>
    ))
    return (
      <div>
        {(
          <Row type="flex" justify="center">
            <Col className="fortlmcol" xs={24} sm={21} md={21} lg={21}>
              <p className="formTitle">VIEW PROMOTIONS</p>
            </Col>
          </Row>
        )}
        <br />
        <div>
          <Row >
            {promotionlist}
          </Row>
        </div>
        <br />
      </div>
    );
  }
}
export default Form.create()(ViewPromotionFormAnt);
