import warrantyMoreActionTypes from "./warranty-more.types";

const INITIAL_STATE = {
  isFetching: false,
  data: [],
  fetchError: null
};

const warrantyMoreReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case warrantyMoreActionTypes.FETCH_WARRANTY_MORE_START:
      return { ...state, isFetching: true, fetchError: null, data: [] };
    case warrantyMoreActionTypes.FETCH_WARRANTY_MORE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload
      };
    case warrantyMoreActionTypes.FETCH_USER_TYPES_FAILED:
      return {
        ...state,
        isFetching: false,
        fetchError: action.payload,
        data: []
      };
    default:
      return state;
  }
};

export default warrantyMoreReducer;
