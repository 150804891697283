import React from "react";
import { Card, Row, Col } from "antd";
import AddPromotionForm from "./AddPromotionForm";
import { AppContext } from "../../main/contexts/app.context";
import propTypes from 'prop-types'
import {connect} from 'react-redux'
import loading from "../../../Assests/Images/loading-animations-preloader-gifs-ui-ux-effects-10.gif";

class CreatePromotion extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
    promotionData: null,
      isDone: false,
      loading: false
     };
  }

  static getDerivedStateFromProps(props, state) {
      if (props.promotion_data !== state.promotion_data) {
        return {
          promotion_data: props.promotion_data,
        };
      }
      return null;
  }

  loaddingShow = () => {
      this.setState({loading: true});
  }

  loaddingHide = () => {
      this.setState({loading: false});
  }

  componentDidUpdate(prevProps, prevState) {
      if (this.props.promotion_data !== prevProps.promotion_data) {
        this.setState({promotionData: this.props.promotion_data, isDone: true })
      }
  }

  render() {

    //finishRouting();
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            {this.state.loading ? (
                <div>
                  <img alt="loading" src={loading} className="loading-anim" />
                </div>
              ) : (
              <AddPromotionForm loaddingShow={this.loaddingShow} loaddingHide={this.loaddingHide}/>
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
    promotion_data: state.promotion_data
})

export default connect(mapStateToProps) (CreatePromotion);
