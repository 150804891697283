const INITIAL_STATE = {
  currentuser: null,
  isLoggedIn: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "USER_LOGIN_SUCCESS":
      return { ...state, currentuser: action.payload, isLoggedIn: true };
    default:
      return state;
  }
};

export default userReducer;
