import axios from "axios";
import cogoToast from "cogo-toast";
import Swal from "sweetalert2";
import { templateStringToUrl } from "../Utils/common.util";

export const addCustomer = customer => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/account/user/modifyendcustomer", {
        activity: "create",
        userType: customer.userType,
        password: customer.password,
        firstName: customer.firstName,
        primaryPhoneNumber: customer.primaryPhoneNumber,
        secondaryPhoneNumber: customer.secondaryPhoneNumber,
        primaryEmail: customer.primaryEmail,
        secondaryEmail: customer.secondaryEmail,
        noNIC: customer.noNIC,
        noPassport: customer.noPassport,
        lastName: customer.lastName,
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        city: customer.city,
        zipCode: customer.zipCode,
        createdVia: "Web"
      })
      .then(function(response) {
        if (response.status === 201 || response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                          <tr>
                            <td align="left">First Name :</td>
                            <td align="left" className="leftPad">${response.data.person.firstName}</td>
                          </tr>
                          <tr>
                            <td align="left">Last Name :</td>
                            <td align="left" className="leftPad">${response.data.person.lastName}</td>
                          </tr>
                          <tr>
                            <td align="left">Email Address :</td>
                            <td align="left" className="leftPad">${response.data.person.primaryEmail}</td>
                          </tr>
                          <tr>
                            <td align="left">Phone No :</td>
                            <td align="left" className="leftPad">${response.data.person.primaryPhoneNumber}</td>
                          </tr>
                          <tr>
                            <td align="left">User type :</td>
                            <td align="left" className="leftPad">${response.data.userType.name}</td>
                          </tr>
                      </table>`
          });
        } else {
          Swal.fire("Error!", "Fail to created a customer!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const simpleSearch = phoneNo => {
  return new Promise((resolve, reject) => {
    const requestUri = `/api/account/user/searchsimple?searchVal=${phoneNo}`;
    axios
      .get(templateStringToUrl(requestUri))
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          if (
            response.data.authorityUserList.length > 0 ||
            response.data.endCustomerList.length > 0
          ) {
            Swal.fire(
              "Success!",
              response.data.authorityUserList.length +
                response.data.endCustomerList.length +
                " record are loaded!",
              "success"
            );
          } else {
            Swal.fire(
              "Error!",
              "No user register under this phone number!",
              "error"
            );
          }
        }
        resolve(response);
      })
      .catch(function(error) {
        console.error("Search simple user Error", error);
        if (error && error.response && error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while searching record',
            'error'
          )
        }
        reject(error.response);
      });
  });
};

export const advanceSearch = values => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/account/user/allendcustomers")
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          if (response.data.length > 0) {
            Swal.fire(
              "Success!",
              response.data.length + " record are loaded!",
              "success"
            );
          } else {
            Swal.fire("Error!", "No user registered!", "error");
          }
        }
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while searching record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const getUserById = (userId, usertypeId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/account/user/userbyidandtypeid?userId=${userId}&userTypeId=${usertypeId}`)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
            
        }
        resolve(response)
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while searching record',
            'error'
          )
        }
        reject(error.response)
      });
  })
}

export const addUser = user => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/account/user/modifyauthorityuser", {
        activity: "create",
        userType: user.userType,
        password: user.password,
        firstName: user.firstName,
        primaryPhoneNumber: user.primaryPhoneNumber,
        secondaryPhoneNumber: user.secondaryPhoneNumber,
        primaryEmail: user.primaryEmail,
        secondaryEmail: user.secondaryEmail,
        noNIC: user.noNIC,
        noPassport: user.noPassport,
        lastName: user.lastName,
        addressLine1: user.addressLine1,
        addressLine2: user.addressLine2,
        city: user.city,
        zipCode: user.zipCode,
        createdVia: "Web"
      })
      .then(function(response) {
        if (response.status === 201 || response.status === 200) {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Success!",
            html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                          <tr>
                            <td align="left">First Name :</td>
                            <td align="left" className="leftPad">${response.data.person.firstName}</td>
                          </tr>
                          <tr>
                            <td align="left">Last Name :</td>
                            <td align="left" className="leftPad">${response.data.person.lastName}</td>
                          </tr>
                          <tr>
                            <td align="left">Email Address :</td>
                            <td align="left" className="leftPad">${response.data.person.primaryEmail}</td>
                          </tr>
                          <tr>
                            <td align="left">Phone No :</td>
                            <td align="left" className="leftPad">${response.data.person.primaryPhoneNumber}</td>
                          </tr>
                          <tr>
                            <td align="left">User type :</td>
                            <td align="left" className="leftPad">${response.data.userType.name}</td>
                          </tr>
                      </table>`
          });
        } else {
          Swal.fire("Error!", "Fail to created a user!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        console.log(error.response);
        if (error && error.response && error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while searching record',
            'error'
          )
        }
        reject(error.response);
      });
  });
};
