import React from "react";
import { Table, Input, Form, Button, Select } from "antd";
import cogoToast from "cogo-toast";
import loading from "../../../Assests/Images/loading-animations-preloader-gifs-ui-ux-effects-10.gif";
import { connect } from "react-redux";
import {
  fetchUserTypesStartAsync,
  saveUserTypeStartAsync
} from "../../../Redux/user-types/user-types.actions";

const { Option } = Select;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    const parentValues = this.props.userTypes.filter(({ userType }, index) => {
      return (
        this.props.userTypes.findIndex(item => item.userType === userType) ===
        index
      );
    });

    if (this.props.dataIndex === "parentId") {
      return (
        <Select
          style={{ width: "100%" }}
          placeholder="Select a user type"
          size="large"
        >
          {parentValues.map((e, key) => {
            return (
              <Option key={e.key} value={e.key}>
                {e.userType}
              </Option>
            );
          })}
        </Select>
      );
    }
    return <Input size="large" />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      record,
      index,
      children,
      userTypes,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class UserTypeTable extends React.Component {
  constructor(props) {
    super(props);

    this.rowCount = 0;

    this.columns = [
      {
        title: "User Type",
        dataIndex: "userType",
        width: "200px",
        editable: true
      },
      {
        title: "Parent",
        dataIndex: "parentId",
        width: "200px",
        editable: true
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "200px",
        editable: true
      },
      {
        title: "Action",
        dataIndex: "operation",
        width: "150px",
        render: (text, record) => {
          if (!record.userType) {
            return (
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={event => {
                      event.preventDefault();
                      this.save(form, record.key);
                    }}
                  >
                    Add
                  </Button>
                )}
              </EditableContext.Consumer>
            );
          } else {
            return (
              <div>
                {record.key !== 1 && record.key !== 41 ? (
                  <Button
                    type="primary"
                    icon="setting"
                    onClick={() =>
                      this.props.openPropsTable(
                        record.key,
                        record.userType,
                        record.description
                      )
                    }
                  >
                    Configure
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          }
        }
      }
    ];
  }

  componentDidMount() {
    this.props.fetchUserTypesStartAsync();
  }

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newRow = { ...row, key };
      const { saveUserTypeStartAsync } = this.props;
      saveUserTypeStartAsync(newRow);
      form.resetFields();
    });
  }

  componentDidUpdate(prevProps) {
    const { fetchError, saveError, saveSuccess } = this.props;
    if (prevProps.isFetching && fetchError) {
      cogoToast.error(fetchError);
    }
    if (prevProps.isSaving && saveSuccess) {
      cogoToast.success("User type saved.");
    }
    if (prevProps.isSaving && saveError) {
      cogoToast.error(saveError);
    }
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const { isFetching, userTypes, typesForUserTypeTable } = this.props;

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          userTypes: userTypes,
          editing: !record.userType ? true : false
        })
      };
    });

    return (
      <div>
        <p className="formTitle">USER TYPES</p>
        {isFetching ? (
          <div>
            <img alt="loading" src={loading} className="loading-anim" />
          </div>
        ) : (
          <EditableContext.Provider value={this.props.form}>
            <div className="table-container">
              <Table
                components={components}
                bordered
                dataSource={typesForUserTypeTable}
                columns={columns}
                rowClassName="UserTypeTable-editable-row"
                scroll={{ x: 400 }}
                pagination={{
                  defaultPageSize: 10
                }}
              />
            </div>
          </EditableContext.Provider>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userTypes }) => ({
  userTypes: userTypes.types,
  typesForUserTypeTable: userTypes.typesForUserTypeTable,
  isFetching: userTypes.isFetching,
  fetchError: userTypes.fetchError,
  isSaving: userTypes.isSaving,
  saveSuccess: userTypes.saveSuccess,
  saveError: userTypes.saveError
});

const mapDispatchToProps = dispatch => ({
  fetchUserTypesStartAsync: () => {
    dispatch(fetchUserTypesStartAsync());
  },
  saveUserTypeStartAsync: userTypeData => {
    dispatch(saveUserTypeStartAsync(userTypeData));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({})(UserTypeTable));
