import React from "react";
import { Card, Row, Col } from "antd";
import ViewUserReports from "./ViewUserReports";
import { AppContext } from "../../main/contexts/app.context";

class UserReports extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
              <ViewUserReports/>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default UserReports;
