import React, {Component} from 'react'
import QrReader from 'react-qr-reader'
import cogoToast from 'cogo-toast';

class ScanQR extends Component {

    state = {
        result: 'No result',
    }

    handleScan = data => {
        if (data) {
            this.setState({
                result: data,
            })
            this.props.qrResult(this.state.result)
        }
    }

    handleError = err => {
        console.error(err)
        cogoToast.error('Fail to scan QR')
    }

    render() {

        return (
            <div className="container">
            <br/>
                <QrReader
                    delay={300}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: '100%' }}
                />
                <div className="d-flex justify-content-center">
                    <p>{this.state.result}</p>
                    <p>{this.state.batchNo}</p>
                    <p>{this.state.item}</p>
                </div>
            <br/>
            </div>
        )
    }
}

export default ScanQR;
