import axios from "axios";

export const fetchUsersAdvanceAsync = (
  {
    userType = "End Customer",
    dateTagName = "addedDateTime",
    dateTagValue = "System Added Date",
    startDate = "2020-01-01",
    endDate = "2020-01-31"
  },
  pageSize = "10",
  pageNumber = "1"
) => {
  console.log("parrams", startDate, endDate);

  return new Promise((resolve, reject) => {
    axios
      .post("/api/account/user/search", {
        userType: userType,
        dateTagName: dateTagName,
        dateTagValue: dateTagValue,
        startDate: startDate,
        endDate: endDate,
        pageSize: pageSize,
        pageNumber: pageNumber
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201)
          resolve(response.data);
      })
      .catch(function(error) {
        console.log(error);
        reject(error);
      });
  });
};

export const fetchUserReportAsync = (
  { userType, dateTagName, dateTagValue, year, month },
  pageSize,
  pageNumber
) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/account/report/search", {
        userType: userType,
        dateTagName: dateTagName,
        dateTagValue: dateTagValue,
        year: year,
        month: month,
        pageSize: pageSize,
        pageNumber: pageNumber
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201)
          resolve(response.data);
      })
      .catch(function(error) {
        console.log(error);
        reject(error);
      });
  });
};
