import React from 'react';
import { Row, Col, Card, Table, Form, Button} from 'antd';
import { NavLink } from "react-router-dom";
import { getUserGroupsById } from "../../../../ApiCalls/usergroup-call";

const columns = [
  {
    title: 'First Name',
    dataIndex: 'fname',
    width: '33%',
    editable: true
  },
  {
    title: 'Last Name',
    dataIndex: 'lname',
    width: '33%',
    editable: true
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNo',
    width: '33%',
    editable: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '33%',
    editable: true
  },
  {
    title: 'NIC No',
    dataIndex: 'nic',
    width: '33%',
    editable: true
  },
  {
    title: 'User Type',
    dataIndex: 'userType',
    width: '33%',
    editable: true
  },
  {
    title: "Action",
    key: "action",
    fixed: "right",
    width: 150,
    render: (text, record) => (
      <span>
        <NavLink
          to="/users/view-user" 
          onClick={()=>setIds(record.key, record.userTypeId)}
        >
          View {record.fname}
        </NavLink>
      </span>
    )
  }
];

function setIds(userId, userTypeId) {
  sessionStorage.setItem("viewUserId", userId)
  sessionStorage.setItem("viewUserTypeId", userTypeId)
  sessionStorage.setItem("viewUserBack", "userGroup")
}

class viewUserGroups extends React.Component {

  state = {
    data: []
  }

  componentDidMount(){
    getUserGroupsById(this.props.ugId)
    .then(res => {
        if (res.status === 200 || res.status === 201) {
            this.setIntoTable(res.data)
        }
        this.setState({loading: false})
      }
    ).catch(error => {
        this.setState({loading: false})
      }
    );
  }

  setIntoTable = (data) => {
    var users = [], userData = []

    for (let index = 0; index < data.length; index++) {
      if (data[index].authorityUser !== null) {
        users.push(data[index].authorityUser)
      }

      if (data[index].endCustomer !== null) {
        users.push(data[index].endCustomer)
      }
    }

    for (let index = 0; index < users.length; index++) {
      userData.push({
        key : users[index].id,
        fname : users[index].person.firstName,
        lname : users[index].person.lastName,
        phoneNo : users[index].person.primaryPhoneNumber,
        email : users[index].person.primaryEmail,
        nic : users[index].person.noNIC,
        userType : users[index].userType.name,
        userTypeId : users[index].userType.id,
      })
    }

    this.setState({
      data : userData
    })
  }

  render() {
    return (
        <div>
          <Row type='flex' justify='center'>
              <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item style={{}}>
                      <Button type="primary" size="large" icon="arrow-left" className="login-form-button" onClick={this.props.backToUserGroup}>
                            &nbsp;Back
                      </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <p className="formTitle" style={{}}>USER GROUP VIEW</p>
                </Col>
          </Row>
          <br/>
          <p>Group Name : <b>{this.props.group}</b></p>
          <p>Group Description : <b>{this.props.description}</b></p>
          <br/>
          <br/>
            <div className="table-container">
              <Table
                bordered
                dataSource={this.state.data}
                columns={columns}
                rowClassName='editable-row'
                scroll={{ x: 1500 }}
              />
            </div>
        </div>
    );
  }
}

export default viewUserGroups;
