import axios from "axios";
import Swal from "sweetalert2";
import { templateStringToUrl } from "../Utils/common.util";


export const checkIfSerialExists = (serialValue) =>{
  return axios.get(`/api/warranty/ser/find?serial=${serialValue}`);
}

export const addWarranty = formData => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/warranty/bulb/createx`, formData)
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Success!",
            html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                          <tr>
                            <td align="left">Warranty Status :</td>
                            <td align="left" className="leftPad">${response.data.warrantyState}</td>
                          </tr>
                          <tr>
                            <td align="left">Serial No :</td>
                            <td align="left" className="leftPad">${response.data.uniqueSerialNumber}</td>
                          </tr>
                          <tr>
                            <td align="left">Customer Phone No :</td>
                            <td align="left" className="leftPad">${response.data.endCustomerPhoneNumber}</td>
                          </tr>
                          <tr>
                            <td align="left">Business Unit:</td>
                            <td align="left" className="leftPad">${response.data.businessUnit}</td>
                          </tr>
                          <tr>
                            <td align="left">Product Code :</td>
                            <td align="left" className="leftPad">${response.data.productCode}</td>
                          </tr>
                          <tr>
                            <td align="left">Batch Number :</td>
                            <td align="left" className="leftPad">${response.data.batchNumber}</td>
                          </tr>
                          <tr>
                            <td align="left">Item Number :</td>
                            <td align="left" className="leftPad">${response.data.itemNumber}</td>
                          </tr>
                          <tr>
                            <td align="left">Warranty Start Date :</td>
                            <td align="left" className="leftPad">${response.data.warrantyStartDate}</td>
                          </tr>
                          <tr>
                            <td align="left">Warranty End Date :</td>
                            <td align="left" className="leftPad">${response.data.warrantyEndDate}</td>
                          </tr>
                      </table>`
          });
        } else {
          Swal.fire("Error!", "Fail to created warranty!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const warrantyAdvanceSearch = (
  {
    endCustomerPhoneNumber,
    warrantyState,
    dateTagName,
    dateTagValue,
    startDate,
    endDate
  },
  perPage,
  currentPage
) => {
  console.log("Param Data", dateTagName);

  return new Promise((resolve, reject) => {
    axios
      .post(`/api/warranty/bulb/search`, {
        endCustomerPhoneNumber,
        warrantyState,
        dateTagName,
        dateTagValue,
        startDate,
        endDate,
        pageSize: perPage,
        pageNumber: currentPage
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          // Swal.fire(
          //   'Success!',
          //   response.data.content.length + ' record are loaded!',
          //   'success'
          // )
        }
        resolve(response.data);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while searching record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const warrantySimpleSearch = (
  { endCustomerPhoneNumber, uniqueSerialNumber },
  perPage,
  currentPage
) => {
  const requestUri = `/api/warranty/bulb/searchsimple?
  endCustomerPhoneNumber=${endCustomerPhoneNumber}
  &uniqueSerialNumber=${uniqueSerialNumber}&pageSize=${perPage}&pageNumber=${currentPage}`;

  return new Promise((resolve, reject) => {
    axios
      .get(templateStringToUrl(requestUri))
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          // Swal.fire(
          //   "Success!",
          //   response.data.content.length + " record are loaded!",
          //   "success"
          // );
        }
        resolve(response.data);
      })
      .catch(function(error) {
        console.log("Server Error Warranty Simple Serach", error);
        // if (error.response.data !== undefined && error.response.data !== null) {
        //   if (
        //     error.response.data.message !== undefined &&
        //     error.response.data.message !== null
        //   ) {
        //     Swal.fire(
        //       "Error!",
        //       "Message : " + error.response.data.message,
        //       "error"
        //     );
        //   } else {
        //     Swal.fire("Error!", "Message : " + error.response.data, "error");
        //   }
        // } else {
        //   Swal.fire(
        //     "Error!",
        //     "Message : Unknown error while searching record",
        //     "error"
        //   );
        // }
        reject(error.response);
      });
  });
};

export const getWarrantyCheckDate = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/warranty/report/datetags`)
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while loading record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const getWarrantyAction = (userId, userTypeId, uniqueSerialNumber) => {
  return axios.get(
    `/api/warranty/bulb/actionstatus?userId=${userId}&userTypeId=${userTypeId}&uniqueSerialNumber=${uniqueSerialNumber}`
  );
};
