import axios from "axios";
import cogoToast from "cogo-toast";
import Swal from 'sweetalert2'

export const createUserGroup = userGroup => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/account/usergroup/create?name=${userGroup.userGroup}&description=${userGroup.description}`, {
        name: userGroup.userGroup,
        description: userGroup.description
      })
      .then(function(response) {
        if (response.status === 201 || response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                      <tr>
                        <td align="left">Group Name :</td>
                        <td align="left" className="leftPad">${response.data.name}</td>
                      </tr>
                      <tr>
                        <td align="left">User Group Description :</td>
                        <td align="left" className="leftPad">${response.data.description}</td>
                      </tr>
                  </table>`,
          })
        } else {
          Swal.fire(
            'Error!',
            'Fail to added user group!',
            'error'
          )
        }
        resolve(response)
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while adding record',
            'error'
          )
        }
        reject(error.response)
      });
  })
};

export const getUserGroups = () => {
  return new Promise((resolve, reject) => {
    axios.get("/api/account/usergroup/all")
      .then(function(response) {
        if (response.status === 200) {
        } else {
          Swal.fire(
            'Error!',
            'Fail to load user group!',
            'error'
          )
        }
        resolve(response)
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while loading record',
            'error'
          )
        }
        reject(error.response)
      });
  })
};

export const getUserGroupsMappings = () => {
  return new Promise((resolve, reject) => {
    axios.get("/api/account/usergroup/allmappings")
      .then(function(response) {
        if (response.status === 200) {
        } else {
          Swal.fire(
            'Error!',
            'Fail to load user group!',
            'error'
          )
        }
        resolve(response)
      })
      .catch(function(error) {
        if (error.response === undefined || error.response.data === null) {
          Swal.fire(
            'Error!',
            'Message : Server fail.',
            'error'
          )
        }else{
          if (error.response.data !== undefined || error.response.data !== null) {
            if (error.response.data.message !== undefined && error.response.data.message !== null) {
              Swal.fire(
                'Error!',
                'Message : '+ error.response.data.message,
                'error'
              )
            }else{
              Swal.fire(
                'Error!',
                'Message : '+ error.response.data,
                'error'
              )
            }
          }else {
            Swal.fire(
              'Error!',
              'Message : Unknown error while loading record',
              'error'
            )
          }
        }
        reject(error.response)
      });
  })
};

export const getAttachUserGroups = (userId, userTypeId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/account/usergroup/groupnamesbyuseridandusertypeid?userId=${parseInt(userId)}&userTypeId=${parseInt(userTypeId)}`)
      .then(function(response) {
        if (response.status === 200) {
        } else {
          Swal.fire(
            'Error!',
            'Fail to load user group!',
            'error'
          )
        }
        resolve(response)
      })
      .catch(function(error) {
        if (error.response === undefined || error.response.data === null) {
          Swal.fire(
            'Error!',
            'Message : Server fail.',
            'error'
          )
        }else{
          if (error.response.data !== undefined || error.response.data !== null) {
            if (error.response.data.message !== undefined && error.response.data.message !== null) {
              Swal.fire(
                'Error!',
                'Message : '+ error.response.data.message,
                'error'
              )
            }else{
              Swal.fire(
                'Error!',
                'Message : '+ error.response.data,
                'error'
              )
            }
          }else {
            Swal.fire(
              'Error!',
              'Message : Unknown error while loading record',
              'error'
            )
          }
        }
        reject(error.response)
      });
  })
};

export const addUserIntoUserGroup = (userGroupId, userId, userTypeId) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/account/usergroup/createmapping?userGroupId=${userGroupId}&userTypeId=${userTypeId}&userId=${userId}`)
      .then(function(response) {
        if (response.status === 201 || response.status === 200) {
          console.log(response.data);
          
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                      <tr>
                        <td align="left">Group Name :</td>
                        <td align="left" className="leftPad">${response.data.userGroup.name}</td>
                      </tr>
                      <tr>
                        <td align="left">User Group Description :</td>
                        <td align="left" className="leftPad">${response.data.userGroup.description}</td>
                      </tr>
                  </table>`,
          })
        } else {
          Swal.fire(
            'Error!',
            'Fail to added user group!',
            'error'
          )
        }
        resolve(response)
      })
      .catch(function(error) {
        console.log(error);
        
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while adding record',
            'error'
          )
        }
        reject(error.response)
      });
  })
};


export const getUserGroupsById = (userGroupId) => {
  return new Promise((resolve, reject) => {
    axios.get("/api/account/usergroup/allmappingsbyusergroupid?userGroupId="+userGroupId)
      .then(function(response) {
        if (response.status === 200) {
        } else {
          Swal.fire(
            'Error!',
            'Fail to load user group!',
            'error'
          )
        }
        resolve(response)
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while loading record',
            'error'
          )
        }
        reject(error.response)
      });
  })
};