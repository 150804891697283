import React from "react";
import { Row, Col, Table, Form, Button, Spin, DatePicker, Select } from "antd";
import { connect } from "react-redux";
import { fetchUserTypesStartAsync } from "../../../Redux/user-types/user-types.actions";
import { fetchUserReportAsync } from "../../../ApiCalls/user-search-call";
import {
  transformAdvanceSearchServerData,
  transformToTableData
} from "./user-report.utils";

import UserReportCSV from "./user-report-csv.component";
const { MonthPicker } = DatePicker;
const { Option } = Select;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "33%"
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    width: "33%"
  },
  {
    title: "NIC No",
    dataIndex: "NIC",
    width: "33%"
  }
];

class ViewUserReports extends React.Component {
  checkDate = {
    addedDateTime: {
      dateTagName: "addedDateTime",
      dateTagValue: "System Added Date"
    },
    lastModifiedDateTime: {
      dateTagName: "lastModifiedDateTime",
      dateTagValue: "Last Modified Date"
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: {},
      loading: false,
      isTableLoading: false,
      loadedReportParams: {}
    };
  }

  componentDidMount() {
    this.props.fetchUserTypesStartAsync();
  }

  formValuesToServerParams = values => {
    return {
      userType: values.userType,
      dateTagName: values.checkDate
        ? this.checkDate[values.checkDate].dateTagName
        : null,
      dateTagValue: values.checkDate
        ? this.checkDate[values.checkDate].dateTagValue
        : null,
      year: values.month.format("YYYY"),
      month: values.month.format("M")
    };
  };

  onReportFormSubmit = event => {
    event.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) return;
      const params = this.formValuesToServerParams(values);
      console.log("Params", params);
      this.onLoadReport(params);
    });
  };

  updatePagination = (current = 1, total) => {
    const pager = { ...this.state.pagination };
    pager.current = current;
    pager.total = total;
    pager.showTotal = (total, range) =>
      `${range[0]}-${range[1]} of ${total} items`;
    this.setState({
      pagination: pager
    });
  };

  onLoadReport = async (values, currentPage = 1, perPage = 10) => {
    console.log("Report Params", values);
    this.setState({ isTableLoading: true });
    try {
      const searchResult = await fetchUserReportAsync(
        values,
        perPage,
        currentPage
      );
      const transformedData = transformAdvanceSearchServerData(searchResult);
      const pagination = transformedData.pagination;
      this.updatePagination(pagination.current, pagination.total);
      console.log("Pagination", pagination);
      const tableData = transformToTableData(transformedData.data);
      this.setState({
        data: tableData,
        loadedReportParams: values,
        isTableLoading: false
      });
      console.log("Table Data", tableData);
    } catch (error) {
      this.updatePagination(0);
      this.setState({
        loadedReportParams: {},
        isTableLoading: false
      });
    }
  };

  updateTable = (pagination, filters, sorter) => {
    this.onLoadReport(this.state.loadedReportParams, pagination.current);
  };

  render() {
    const { userTypes, form } = this.props;
    const { getFieldDecorator } = form;
    const { loadedReportParams } = this.state;
    return (
      <>
        <Spin spinning={this.state.loading}>
          <p className="formTitle" style={{ paddingTop: "40px" }}>
            USER REPORTS
          </p>
          <Form onSubmit={this.onReportFormSubmit}>
            <Row gutter={16}>
              <Col xs={24} md={8}>
                <Form.Item label="User Type">
                  {getFieldDecorator("userType", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a user type."
                      }
                    ]
                  })(
                    <Select size="large" placeholder="Select a user type">
                      {userTypes.map(userType => (
                        <Option key={userType.key} value={userType.userType}>
                          {userType.userType}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Check Date">
                  {getFieldDecorator("checkDate", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a check Date."
                      }
                    ]
                  })(
                    <Select size="large" placeholder="Select a user type">
                      <Option key="addedDateTime">System Added Date</Option>
                      <Option key="lastModifiedDateTime">
                        Last Modified Date
                      </Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Month">
                  {getFieldDecorator("month", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a month."
                      }
                    ]
                  })(<MonthPicker size="large" placeholder="Select month" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Col>
                <Button
                  size="large"
                  type="primary"
                  icon="search"
                  htmlType="submit"
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          <div className="table-container">
            <Table
              bordered
              loading={this.state.isTableLoading}
              dataSource={this.state.data}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.updateTable}
              rowClassName="editable-row"
            />
          </div>
        </Spin>
        <UserReportCSV searchParams={loadedReportParams}></UserReportCSV>
      </>
    );
  }
}

const mapStateToProps = ({ userTypes }) => ({
  userTypes: userTypes.types
});
const mapDispatchToProps = dispatch => ({
  fetchUserTypesStartAsync: () => {
    dispatch(fetchUserTypesStartAsync());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ViewUserReports));
