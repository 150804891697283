import React from "react";
import { Card, Row, Col, Modal } from "antd";
import AddWarrantyForm from "./AddWarrantyForm";
import { AppContext } from "../../main/contexts/app.context";
import ScanQR from './ScanQR'

import logo from '../../../Assests/Images/d_logo_png.png';
import backTop from '../../../Assests/Images/910.png';

class CreateWarranty extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      scanqr: false,
      serialNo: '',
      warrantyData: null,
      isDone: false
    };
  }

  componentDidMount() {
    this.props.hideShell();
  }

  qrOpen(){
      this.setState({scanqr: true})
  }

  qrResult(serial) {
      this.setState({serialNo: serial, scanqr: false})
  }

  render() {
    return (
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]} type='flex' justify='center' className="valign loginbg" style={{paddingLeft: '-100px'}}>
        <Col xs={24} sm={24} md={24} lg={19}>
          <div className='topBar'>
            <img src={backTop} alt='backTop' className='backTopImage' />
            <h2 className="dimotitle">DIMO - Diesel & Motor Engineering PLC</h2>
            <img src={logo} alt='logo' className='logoImage' />
          </div>

          <Row type='flex' justify='center'>
            <Col xs={19} sm={19}>
              <div className="heading">
                <h1 style={{fontSize: '22px'}}>LED Warranty Management Portal</h1>
              </div>
              <Card bordered={false}>
                  <Modal
                    title="Scan QR"
                    centered
                    visible={this.state.scanqr}
                    onOk={() => this.setState({scanqr: false })}
                    onCancel={() => this.setState({scanqr: false })}
                    cancelButtonProps={{ style: { display: 'none' } }}
                  >
                    <ScanQR qrResult={this.qrResult.bind(this)}/>
                  </Modal>
                  <AddWarrantyForm
                      addWarranty={this.props.addWarranty}
                      qrOpen={this.qrOpen.bind(this)}
                      serialNo={this.state.serialNo}
                      loaddingShow={this.loaddingShow}
                      loaddingHide={this.loaddingHide}
                  />
              </Card>
              </Col>
            </Row>
        </Col>
      </Row>
    );
  }
}

export default (CreateWarranty);
