import axios from "axios";
import cogoToast from "cogo-toast";
import Swal from "sweetalert2";

export const createUserType = userType => {
  axios
    .post("/api/account/usertype/create", {
      name: userType.userType,
      description: userType.description
    })
    .then(function(response) {
      if (response.status === 201) {
        cogoToast.success("Successfully added user type.");
      } else {
        cogoToast.error("Fail to added user type.");
      }
    })
    .catch(function(error) {
      cogoToast.error("Server failed");
    });
};

export const getUserTypes = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/account/usertypeconfig/all")
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
        } else {
          Swal.fire("Error!", "Fail to load user types!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        alert("Server Error");
        console.log(error);
        // if (error.response.data !== undefined && error.response.data !== null) {
        //   if (error.response.data.message !== undefined && error.response.data.message !== null) {
        //     Swal.fire(
        //       'Error!',
        //       'Message : '+ error.response.data.message,
        //       'error'
        //     )
        //   }else{
        //     Swal.fire(
        //       'Error!',
        //       'Message : '+ error.response.data,
        //       'error'
        //     )
        //   }
        // }else {
        //   Swal.fire(
        //     'Error!',
        //     'Message : Unknown error while loading record',
        //     'error'
        //   )
        // }
        reject(error.response);
      });
  });
};
