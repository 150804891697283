import React from 'react';
import { Table, Form, Button, Select, Input, Spin, Row, Col } from 'antd';
import uuid from 'uuid/v4';
import { getPropertyTypes, createPropType, deleteProps } from "../../../../ApiCalls/proptype-call";

const data = [];

const { Option } = Select;
const { TextArea } = Input;

const EditableContext = React.createContext();

var propNames = []

class EditableCell extends React.Component {

  constructor(props){
    super(props)
    this.getPropertyName = this.getPropertyName.bind(this);
  }

  getPropertyName(value){
    var data = this.props.propTypes.filter(item => item.propertyType === value)
    propNames = []
    for (var i = 0; i < data.length; i++) {
        propNames.push(data[i].propertyName)
    }
  }

  getInput = () => {
    var self = this
    if (this.props.title === 'Property Type') {
      const distinctValues = this.props.propTypes.filter(
        ({ propertyType }, index) => {
          return this.props.propTypes.findIndex(item => item.propertyType === propertyType) === index;
      });

      return <Select
                style={{ width: 200 }}
                placeholder="Select a property type"
                size="large"
                onChange={this.getPropertyName}
              >
              {distinctValues.map((e, key) => {
                  return <option key={e.id} value={e.propertyType}>{e.propertyType}</option>;
              })}
              </Select>;
    }else if(this.props.title === 'Property Name'){
          return <Select
                    style={{ width: 200 }}
                    placeholder="Select a property name"
                    size="large"
                  >
                  {propNames.length !== 0 ? propNames.map((e, key) => {
                      return <option key={e} value={e}>{e}</option>;
                  }) : ""}
                  </Select>;

    }else if (this.props.title === 'Status') {
        return <Select
                 style={{ width: 200 }}
                 placeholder="Select a status"
                 size="large"
               >
                 <Option value="Enable">Enable</Option>
                 <Option value="Disable">Disable</Option>
               </Select>;
    }
  };



  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class PropTypeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...data, { key: uuid() }],
      loading: false
    };

    this.rowCount = 0;

    this.columns = [
      {
        title: 'Property Type',
        dataIndex: 'propType',
        width: '40%',
        editable: true
      },
      {
        title: 'Property Name',
        dataIndex: 'propName',
        width: '40%',
        editable: true
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: '35%',
        editable: true
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        fixed: 'right',
        width: 120,
        render: (text, record) => {
          if (!record.propType) {
            return (
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type='primary'
                    icon='plus'
                    onClick={() => this.save(form, record.key)}
                  >
                    Add
                  </Button>
                )}
              </EditableContext.Consumer>
            );
          } else {
            return (
              <Button type='danger' icon='delete' onClick={() => this.delete(record.key)}>
                Delete
              </Button>
            );
          }
        }
      }
    ];
  }

  delete = key => {
    this.setState({loading: true})
    deleteProps(key)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        this.getAllPropertyTypes()
      }
        this.setState({loading: false})
    }).catch(error => {
          this.setState({loading: false})
    });
  }

  getAllPropertyTypes = () => {
    this.setState({loading: true, data: [...data, { key: uuid() }]})
    getPropertyTypes(this.props.utId)
    .then(res => {
          if (res.status === 200) {
              if (res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                  this.setState(state => {
                  const list = state.data.push({
                    key: res.data[i].id,
                    propName: res.data[i].propertyName,
                    propType: res.data[i].propertyType,
                    status: res.data[i].status
                  });
                  return {
                    list
                  };
                  });
                }
              }
          }
          this.setState({loading: false})
    }).catch(error => {
          this.setState({loading: false})
    });
  }

  componentDidMount(){
    this.getAllPropertyTypes()
  }

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newRow = { ...row, key };
      const previousData = [...this.state.data];
      const index = previousData.findIndex(item => key === item.key);

      previousData.splice(index, 1, newRow);

      this.setState({loading: true})
      createPropType(this.props.utId, newRow)
      .then(res => {
            if (res.status === 201 || res.status === 200) {
                this.setState(state => {
                const list = state.data.push({
                  key: res.data.id,
                  propName: res.data.propertyName,
                  propType: res.data.propertyType,
                  status: res.data.status
                });
                return {
                  list
                };
                });
                form.resetFields();
            }
            this.setState({loading: false})
        }
      ).catch(error => {
            this.setState({loading: false})
        }
      );
    });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          propTypes: this.props.propTypes,
          editing: !record.propType ? true : false
        })
      };
    });

    return (
      <Spin spinning={this.state.loading}>
          <div>
            <Row type='flex' justify='center'>
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item style={{}}>
                        <Button type="primary" size="large" icon="arrow-left" className="login-form-button" onClick={this.props.backToUserType}>
                              &nbsp;Back
                        </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    <p className="formTitle" style={{}}>PROPERTY TYPES</p>
                  </Col>
            </Row>
            <br/>
            <p>{this.props.type}</p>
            <TextArea rows={4} defaultValue={this.props.description} />
            <br/>
            <br/>
            <EditableContext.Provider value={this.props.form} propTypes={this.props.propTypes}>
              <div className="table-container">
                <Table
                  components={components}
                  bordered
                  dataSource={this.state.data}
                  columns={columns}
                  rowClassName='editable-row'
                  scroll={{ x: 400 }}
                />
              </div>
            </EditableContext.Provider>
          </div>
      </Spin>
    );
  }
}

export default Form.create()(PropTypeTable);
