import React from "react";
import { Card, Row, Col } from "antd";
import ViewProducts from "./ViewProducts";
import ProductUploadForm from "./product-upload-form";
import { AppContext } from "../../main/contexts/app.context";
import "./Product.scss";

class Product extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <ViewProducts />
          </Card>
          <Card bordered={false} className="Product-upload-form-card">
            <ProductUploadForm />
          </Card>
        </Col>
      </Row>
    );
  }
}
export default Product;
