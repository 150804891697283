import React, { Component } from "react";
import { Form, Input, Icon, Row, Col, Button, Table, Spin } from "antd";
import { NavLink } from "react-router-dom";
import { simpleSearch } from "../../../ApiCalls/user-call";

import { fetchUsersAdvanceAsync } from "../../../ApiCalls/user-search-call";
import AdvancedUserSearchForm from "./AdvancedUserSearchForm.component";
import {
  transformSimpleSearchServerData,
  transformAdvanceSearchServerData,
  transformToTableData
} from "./search-user.utils";
import SearchUserCSV from "./search-user-csv.component";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Phone No",
    dataIndex: "phone",
    key: "phone"
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "Action",
    key: "action",
    fixed: "right",
    width: 150,
    render: (text, record) => (
      <span>
        <NavLink
          to="/users/view-user"
          onClick={() => setIds(record.key, record.userTypeId)}
        >
          View {record.name}
        </NavLink>
      </span>
    )
  }
];

function setIds(userId, userTypeId) {
  sessionStorage.setItem("viewUserId", userId);
  sessionStorage.setItem("viewUserTypeId", userTypeId);
  sessionStorage.setItem("viewUserBack", "searchUser");
}

class SearchUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSimple: true,
      isAdvance: false,
      users: [],
      data: [],
      userTypes: [],
      csvData: [],
      loading: false,
      pagination: {},
      isTableLoading: false,
      searchParams: { type: undefined, params: undefined }
    };
  }

  resetPagination = () => {
    this.setState({ pagination: { total: 0 } });
  };

  updatePagination = (current = 1, total) => {
    const pager = { ...this.state.pagination };
    pager.current = current;
    pager.total = total;
    pager.showTotal = (total, range) =>
      `${range[0]}-${range[1]} of ${total} items`;
    this.setState({
      pagination: pager
    });
  };

  onAdvanceUserSearch = async (values, currentPage = 1, perPage = 10) => {
    this.setState({ isTableLoading: true });
    console.log("Advanced User search Values", values);
    try {
      const searchResult = await fetchUsersAdvanceAsync(
        values,
        perPage,
        currentPage
      );
      const transformedData = transformAdvanceSearchServerData(searchResult);
      const pagination = transformedData.pagination;
      this.updatePagination(pagination.current, pagination.total);
      console.log("Pagination", pagination);
      const tableData = transformToTableData(transformedData.data);
      this.setState({
        data: tableData,
        searchParams: { type: "advance", params: values },
        isTableLoading: false
      });
      console.log(tableData);
    } catch (error) {
      this.updatePagination(0);
      this.setState({
        advanceSearchParams: {},
        isTableLoading: false
      });
    }
  };

  searchSimple = values => {
    this.updatePagination(0);
    simpleSearch(values.searchValue)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data);
          const availdableData = transformSimpleSearchServerData(response.data);
          console.log("availdableData", availdableData);
          const tableData = transformToTableData(availdableData);
          this.setState({
            data: tableData,
            searchParams: { type: "simple", params: values },
            isTableLoading: false
          });
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ isTableLoading: false, loading: false });
      });
  };

  updateTable = (pagination, filters, sorter) => {
    console.log("Updated Pagination", pagination);
    const { searchParams } = this.state;
    if (searchParams.type === "advance") {
      this.onAdvanceUserSearch(searchParams.params, pagination.current);
      console.log("saved params:", searchParams);
    } else if (searchParams.type === "simple") {
      this.searchSimple(searchParams.params);
    }
  };

  simpleSearchSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Simple Search Form Values", values.searchValue);

        this.setState({ isTableLoading: true });
        console.log("Simple User search Values", values);

        this.setState({ loading: true });
        this.searchSimple(values);
      }
    });
  };

  openAdvance = e => {
    e.preventDefault();
    this.setState({ isAdvance: true, isSimple: false });
  };

  openSimple = e => {
    e.preventDefault();
    this.setState({ isAdvance: false, isSimple: true });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { getPermission } = this.props;
    const { searchParams } = this.state;

    return (
      <>
        <Spin spinning={this.state.loading}>
          <Form onSubmit={this.simpleSearchSubmit}>
            {getPermission("UI Pane", "user_search_simple") && (
              <Row type="flex" justify="center" gutter={16}>
                <Col xs={24}>
                  <p className="formTitle">SEARCH USERS</p>
                  {this.state.isSimple ? (
                    <div>
                      <div
                        className="form_separtion"
                        style={{ cursor: "pointer" }}
                      >
                        <Icon type="caret-down" /> Simple Search
                      </div>
                      <Row type="flex" justify="center" gutter={16}>
                        <Col xs={24}>
                          <Form.Item label="Simple Search">
                            {getFieldDecorator("searchValue", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please enter search value!"
                                }
                              ]
                            })(
                              <Input
                                size="large"
                                placeholder="Please enter Phone No/NIC No/Passport No"
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex" justify="end" gutter={16}>
                        <Button
                          size="large"
                          type="primary"
                          icon="search"
                          htmlType="submit"
                          style={{ marginRight: "5px" }}
                        >
                          Search
                        </Button>
                      </Row>
                    </div>
                  ) : (
                    <div
                      className="form_separtion"
                      style={{ cursor: "pointer" }}
                      onClick={this.openSimple.bind(this)}
                    >
                      <Icon type="caret-right" /> Simple Search
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Form>

          {/* Advance form start here */}
          {getPermission("UI Pane", "user_search_advance") && (
            <Row type="flex" justify="center">
              <Col xs={24}>
                {this.state.isAdvance ? (
                  <>
                    <div
                      className="form_separtion"
                      style={{ cursor: "pointer" }}
                    >
                      <Icon type="caret-down" /> Advance Search
                    </div>
                    <AdvancedUserSearchForm
                      onSearch={this.onAdvanceUserSearch}
                    ></AdvancedUserSearchForm>
                  </>
                ) : (
                  <div
                    className="form_separtion"
                    style={{ cursor: "pointer" }}
                    onClick={this.openAdvance.bind(this)}
                  >
                    <Icon type="caret-right" /> Advance Search
                  </div>
                )}
              </Col>
            </Row>
          )}
          {/* Advance form End here */}
          <Row type="flex" justify="center">
            <Col xs={24}>
              <br />
              <div className="table-container">
                <Table
                  {...this.state}
                  columns={columns}
                  dataSource={this.state.data}
                  bordered
                  className="table-header"
                  loading={this.state.isTableLoading}
                  pagination={this.state.pagination}
                  onChange={this.updateTable}
                  scroll={{ x: 500 }}
                />
              </div>
              <br />
            </Col>
          </Row>
        </Spin>
        <Row type="flex" justify="end">
          <Col>
            <SearchUserCSV searchParams={searchParams}></SearchUserCSV>
          </Col>
        </Row>
      </>
    );
  }
}

export default Form.create()(SearchUserForm);
