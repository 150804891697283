import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Modal,
  Input,
  Table,
  Spin
} from "antd";
import {
  getActions,
  addIncident,
  addValidate,
  getWarrantyDetails
} from "../../../ApiCalls/warranty-action-call";
import ReplaceBulb from "./ReplaceBulb.js";
import ScanQR from "./ScanQR";
import { Link } from "react-router-dom";
import WarrantyMore from "../warranty-more/warranty-more.component";
import "./viewWarrantyDetails.styles.scss";
import ViewImage from "./view-image.component";
import { withRouter } from "react-router";

const { Option } = Select;

const columns = [
  {
    title: "Title",
    dataIndex: "title"
  },
  {
    title: "Value",
    dataIndex: "value"
  }
];

class ViewWarrantyDetails extends Component {
  state = {
    data: {},
    showModal: false,
    replaceBulb: false,
    claimType: "",
    actionMap: [],
    scanqr: false,
    serialNo: "",
    loading: true,
    status: "",
    reasonMap: "",
    showReason: false,
    typeReason: false,
    claimTypeText: "",
    loading: false,
    showMore: false,
    userMap: []
  };

  componentDidMount() {
    this.setState({ loading: true });
    const userMap = JSON.parse(sessionStorage.getItem("usermap"));
    if (userMap !== null) {
      this.setState({ userMap: userMap });
    }

    this.loadWarrantyActions();
    getWarrantyDetails()
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          this.setState({
            data: res.data[0]
          });
          console.log("Data from", this.state.data);
          console.log("Image Name", res.data.invoiceImageName);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
    this.loadWarrantyDetails();
  }

  getPermission = (propertyType, propertyName) => {
    const permission = this.state.userMap.find(element => {
      return (
        element.propertyType === propertyType &&
        element.propertyName === propertyName
      );
    });
    return permission === undefined || permission.status === "Enable"
      ? true
      : false;
  };

  loadWarrantyDetails = () => {
    getWarrantyDetails()
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          this.setState({
            data: res.data[0]
          });
          console.log("Data from", this.state.data);
          console.log("Image Name", res.data.invoiceImageName);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  loadWarrantyActions() {
    getActions(
      sessionStorage.getItem("userId"),
      sessionStorage.getItem("userTypeId"),
      sessionStorage.getItem("serial")
    )
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          console.log("Warranty Actions", res.data);
          this.setState({
            actionMap: res.data
          });
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  setStatus = status => {
    this.setState({ status: status, showReason: true, typeReason: false });

    const permission = this.state.actionMap.find(element => {
      return (
        element.name === this.state.claimType ||
        element.text === this.state.claimType
      );
    });

    if (status === "PASSED") {
      this.setState({ reasonMap: permission.passReasons });
    } else if (status === "FAILED") {
      this.setState({ reasonMap: permission.failReasons });
    }
  };

  enableOption = name => {
    const permission = this.state.actionMap.find(element => {
      return element.name === name || element.text === name;
    });
    return permission === undefined || permission.value === "Enable"
      ? true
      : false;
  };

  setModal1Visible(e) {
    e.preventDefault();
    this.setState({ showModal: !this.state.showModal, typeReason: false });
    this.props.form.setFieldsValue({
      status: "",
      reason: "",
      reasonInput: ""
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["warrantyClaim"],
      (err, values) => {
        if (!err) {
          this.setState({ claimType: values.warrantyClaim, showReason: false });
          console.log("Selected value", values);
          const selectedActionObj = this.state.actionMap.find(element => {
            return element.name === values.warrantyClaim;
          });
          this.setState({
            claimTypeText: selectedActionObj.text
          });
          console.log("selectedActionObj", selectedActionObj);
          if (values.warrantyClaim === "REPLACE_BULB") {
            this.setState({
              replaceBulb: true,
              claimType: values.warrantyClaim,
              claimTypeText: selectedActionObj.text
            });
            sessionStorage.setItem(
              "warrantyDetails",
              JSON.stringify(this.state.data)
            );
          } else {
            this.setState({ showModal: true });
          }
        }
      }
    );
  };

  newIncident = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      ["status", "reason"],
      (err, values) => {
        const { data } = this.state;
        this.setState({ loading: true });
        var reason = "";
        if (values.reason === "" || values.reason === null) {
          reason = values.reasonInput;
        } else {
          reason = values.reason;
        }

        if (this.state.claimType === "WARRANTY_CLAIM") {
          addIncident(
            data.id,
            values.warrantyClaim,
            data.sellerId,
            "PASSED",
            reason
          )
            .then(res => {
              if (res.status === 200 || res.status === 201) {
                this.loadWarrantyActions();
                this.loadWarrantyDetails();
              }
              this.setState({ loading: false });
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        } else if (this.state.claimType === "VALIDATE") {
          addValidate(data, values.status, reason)
            .then(res => {
              if (res.status === 200 || res.status === 201) {
                this.loadWarrantyActions();
                this.loadWarrantyDetails();
              }
              this.setState({ loading: false });
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        } else {
          addIncident(
            data.id,
            this.state.claimType,
            data.sellerId,
            values.status,
            reason
          )
            .then(res => {
              if (res.status === 200 || res.status === 201) {
                this.loadWarrantyActions();
                this.loadWarrantyDetails();
              }
              this.setState({ loading: false });
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        }
      }
    );
    this.setState({ showModal: false });
  };

  qrOpen = () => {
    this.setState({ scanqr: true });
  };

  qrResult(serial) {
    this.setState({ serialNo: serial, scanqr: false });
  }

  showInput = value => {
    if (value === "other") {
      this.setState({ typeReason: true });
    }
  };

  onViewMore = event => {
    event.stopPropagation();
    this.viewMore.showModal(this.state.data.uniqueSerialNumber);
  };

  onViewImage = event => {
    event.stopPropagation();
    const { uniqueSerialNumber, invoiceImageName } = this.state.data;
    this.viewImage.showModal(uniqueSerialNumber, invoiceImageName);
  };

  onGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data, reasonMap, claimTypeText } = this.state;

    return (
      <>
        <Spin spinning={this.state.loading}>
          <Modal
            destroyOnClose={true}
            title="Scan QR"
            centered
            visible={this.state.scanqr}
            onOk={() => this.setState({ scanqr: false })}
            onCancel={() => this.setState({ scanqr: false })}
            cancelButtonProps={{ style: { display: "none" } }}
          >
            <ScanQR qrResult={this.qrResult.bind(this)} />
          </Modal>
          {this.state.replaceBulb ? (
            <ReplaceBulb
              warrantyData={this.state.data}
              qrOpen={this.qrOpen}
              batchNo={this.state.batchNo}
              itemNo={this.state.itemNo}
              serialNo={this.state.serialNo}
              addReplaceBulb={this.props.addReplaceBulb}
            />
          ) : (
            <div>
              <Modal
                destroyOnClose={true}
                title=""
                centered
                visible={this.state.showModal}
                onOk={this.newIncident}
                onCancel={this.setModal1Visible.bind(this)}
                cancelButtonProps={{ style: { color: "white" } }}
              >
                <Form>
                  <h4>
                    {claimTypeText + "  " + this.state.data.uniqueSerialNumber}
                  </h4>
                  <Row>
                    <Col>
                      <Form.Item label="Status">
                        {getFieldDecorator("status", {
                          rules: [
                            {
                              required: true,
                              message: "Please select status!"
                            }
                          ]
                        })(
                          <Select
                            size="large"
                            value={this.state.claimType}
                            placeholder="Please select a status"
                            onChange={this.setStatus}
                          >
                            <Option value="PASSED">PASSED</Option>
                            <Option value="FAILED">FAILED</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col>
                      {this.state.showReason ? (
                        <Form.Item label="Reason">
                          {getFieldDecorator("reason", {
                            rules: [
                              {
                                required: true,
                                message: "Please select reason!"
                              }
                            ]
                          })(
                            <Select
                              size="large"
                              placeholder="Please select a reason"
                              onChange={this.showInput}
                            >
                              {reasonMap.map(reason => (
                                <Option value={reason}>{reason}</Option>
                              ))}
                              <Option value="other">Other</Option>
                            </Select>
                          )}
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col>
                      {this.state.typeReason ? (
                        <Form.Item label="Enter New Reason">
                          {getFieldDecorator("reasonInput", {
                            rules: [
                              {
                                required: true,
                                message: "Please select reason!"
                              }
                            ]
                          })(
                            <Input
                              size="large"
                              name="reason"
                              placeholder="Reason for claim"
                            />
                          )}
                        </Form.Item>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Form>
              </Modal>
              <Row type="flex" justify="center">
                <Col className="fortlmcol" xs={24} sm={21} md={21} lg={21}>
                  <Row type="flex" justify="center">
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Form.Item
                        style={{ paddingTop: "40px", paddingLeft: "40px" }}
                      >
                        <Button
                          type="primary"
                          size="large"
                          icon="arrow-left"
                          className="login-form-button"
                          onClick={this.onGoBack}
                        >
                          Back
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <p className="formTitle" style={{ paddingTop: "40px" }}>
                        VIEW WARRANTY
                      </p>
                    </Col>
                  </Row>
                  <Form onSubmit={this.handleSubmit}>
                    <Row type="flex" justify="center">
                      <Col
                        className="inlineCol"
                        xs={18}
                        sm={18}
                        md={18}
                        lg={18}
                      >
                        <Form.Item>
                          {getFieldDecorator("warrantyClaim", {
                            rules: [
                              {
                                required: true,
                                message: "Please select your warranty claim!"
                              }
                            ]
                          })(
                            <Select
                              size="large"
                              placeholder="Please select a warranty claim type"
                            >
                              {this.state.actionMap.map((e, key) => {
                                return (
                                  this.enableOption(e.name) && (
                                    <Option
                                      style={{ color: "green" }}
                                      key={e.id}
                                      value={e.name}
                                    >
                                      {e.text === "" ? e.name : e.text}
                                    </Option>
                                  )
                                );
                              })}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                      <Col className="inlineCol" xs={4} sm={4} md={4} lg={4}>
                        <Form.Item>
                          <Button size="large" type="primary" htmlType="submit">
                            GO
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>

                  {this.state.data.uniqueSerialNumber &&
                    this.getPermission("UI Pane", "warranty_view_more") && (
                      <Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="button"
                          className="ViewWarrantyDetails-view-more-btn"
                          onClick={this.onViewMore}
                        >
                          View More
                        </Button>
                      </Form.Item>
                    )}
                  <Table
                    className="ant-view-table-warranty"
                    columns={columns}
                    dataSource={
                      data === null
                        ? []
                        : [
                            {
                              title: "",
                              value: ""
                            },
                            {
                              title: "Warranty Status",
                              value: data.warrantyState
                            },
                            {
                              title: "Serial Number",
                              value: data.uniqueSerialNumber
                            },
                            {
                              title: "Batch Number",
                              value: data.batchNumber
                            },
                            {
                              title: "Item Number",
                              value: data.itemNumber
                            },
                            {
                              title: "Warramty Start Date",
                              value: data.warrantyStartDate
                            },
                            {
                              title: "Warramty End Date",
                              value: data.warrantyEndDate
                            },
                            {
                              title: "",
                              value: ""
                            },
                            {
                              title: "Customer ID",
                              value: data.endCustomerId
                            },
                            {
                              title: "Seller ID",
                              value: data.sellerId
                            },
                            {
                              title: "Sell Date",
                              value: data.sellDate
                            },
                            {
                              title: "",
                              value: ""
                            },
                            {
                              title: "Replaced Bulb ID",
                              value: data.replacementBulbId
                            },
                            {
                              title: "Replace Bulb Serial Number",
                              value: data.replacementBulbUniqueSerialNumber
                            }
                          ]
                    }
                    bordered
                    pagination={false}
                  />
                  {data.invoiceImageName && (
                    <Button
                      size="large"
                      type="primary"
                      htmlType="button"
                      className="ViewWarrantyDetails-view-image-btn"
                      onClick={this.onViewImage}
                    >
                      View Image
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Spin>

        <WarrantyMore onRef={ref => (this.viewMore = ref)}></WarrantyMore>
        <ViewImage onRef={ref => (this.viewImage = ref)}></ViewImage>
      </>
    );
  }
}

export default withRouter(Form.create()(ViewWarrantyDetails));
