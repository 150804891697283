import React from "react";
import { Card, Row, Col, Form, Input, Button } from "antd";
import { login } from "../../../ApiCalls/auth-call";
import { Link } from "react-router-dom";

import logo from "../../../Assests/Images/d_logo_png.png";
import backTop from "../../../Assests/Images/910.png";
import Username from "../../../Assests/Images/un.png";
import Password from "../../../Assests/Images/pw.png";
import Warranty from "../../../Assests/Images/warranty.png";
import SMS from "../../../Assests/Images/sms.png";
import Sales from "../../../Assests/Images/sales.png";
import Report from "../../../Assests/Images/report.png";
import MultiLevel from "../../../Assests/Images/multilevel.png";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.hideShell();
    sessionStorage.setItem("cusPhone", "");
  }

  componentWillUnmount() {
    this.props.showShell();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        login(values);
        console.log("Received values of form: ", values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row
        gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]}
        type="flex"
        justify="center"
        className="valign loginbg"
        style={{ paddingLeft: "-100px" }}
      >
        <Col xs={24} sm={24} md={24} lg={19}>
          <div className="topBar">
            <img src={backTop} alt="backTop" className="backTopImage" />
            <h2 className="dimotitle">DIMO - Diesel & Motor Engineering PLC</h2>
            <img src={logo} alt="logo" className="logoImage" />
          </div>

          <Row type="flex" justify="center">
            <Col xs={19} sm={19}>
              <div className="heading">
                <h1 style={{ fontSize: "22px" }}>
                  LED Warranty Management Portal
                </h1>
              </div>

              <Card bordered={true} className="login-card">
                <Row type="flex" justify="center">
                  <Col xs={24} sm={24}>
                    <Link
                      to="add-warranty"
                      className="warrantyLink"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      Add Warranty Now!
                    </Link>
                    <br />
                    <br />
                  </Col>

                  <Col xs={24} sm={10} className="loginSep">
                    <p className="formTitle loginTitle">Log In</p>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                      <Form.Item>
                        {getFieldDecorator("userName", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your phone No!"
                            }
                          ]
                        })(
                          <Input
                            prefix={
                              <img
                                src={Username}
                                alt="username"
                                className="iconimg"
                              />
                            }
                            size="large"
                            placeholder="Phone No"
                            className="inputLogin"
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please input your Password!"
                            }
                          ]
                        })(
                          <Input.Password
                            prefix={
                              <img
                                src={Password}
                                alt="password"
                                className="iconimg"
                              />
                            }
                            size="large"
                            type="password"
                            placeholder="Password"
                            className="inputLogin"
                          />
                        )}
                      </Form.Item>
                      <Link to="/forgot-password" className="login-form-forgot">
                        Forgot password
                      </Link>
                      <br />
                      <br />
                      <Form.Item style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          className="login-form-button"
                          style={{ align: "right" }}
                        >
                          Log in
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>

                  <Col xs={0} sm={1}>
                    <div className="vl"></div>
                  </Col>
                  <Col xs={0} sm={10}>
                    <div className="features">
                      <h3>
                        <img
                          src={Warranty}
                          alt="warranty"
                          style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "15px"
                          }}
                        />{" "}
                        Warranty Activation & Claim Approval
                      </h3>
                      <br />
                      <h3>
                        <img
                          src={MultiLevel}
                          alt="multilevel"
                          style={{ width: "35px", marginRight: "15px" }}
                        />{" "}
                        Multi Level User Management
                      </h3>
                      <br />
                      <h3>
                        <img
                          src={Sales}
                          alt="sales"
                          style={{ width: "35px", marginRight: "15px" }}
                        />{" "}
                        Create & View Sales Promotions
                      </h3>
                      <br />
                      <h3>
                        <img
                          src={Report}
                          alt="report"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "17px"
                          }}
                        />{" "}
                        Report Generation
                      </h3>
                      <br />
                      <h3>
                        <img
                          src={SMS}
                          alt="sms"
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "17px"
                          }}
                        />{" "}
                        SMS Interaction
                      </h3>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(Login);
