import React from "react";
import { Row, Col, Card } from "antd";
import UserTypeTable from "./UserTypeTable";
import PropTypeTable from "./prop-types/PropTypeTable";
import axios from "axios";
import cogoToast from "cogo-toast";
import { getUserTypes } from "../../../ApiCalls/usertype-call";

class UserTypes extends React.Component {
  state = {
    userType: true,
    type: "",
    description: "",
    userTypeId: "",
    propTypes: [],
    loading: false
  };

  componentDidMount() {
      getUserTypes()
      .then(res => {
            if (res.status === 200 || res.status === 201) {
                if (res.data.length > 0) {
                  this.setState({propTypes: res.data})
                }
            }
            this.setState({loading: false})
        }
      ).catch(error => {
            this.setState({loading: false})
        }
      );
  }

  openPropsTable(userTypeId, usertype, userDescription) {
    this.setState({
      userTypeId: userTypeId,
      userType: false,
      type: usertype,
      description: userDescription
    });
  }

  backToUserType = () => {
    this.setState({userType: true})
  }

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            {this.state.userType ? (
              <UserTypeTable
                openPropsTable={this.openPropsTable.bind(this)}
              ></UserTypeTable>
            ) : (
              <PropTypeTable
                backToUserType={this.backToUserType}
                utId={this.state.userTypeId}
                type={this.state.type}
                description={this.state.description}
                propTypes={this.state.propTypes}
              />
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default UserTypes;
