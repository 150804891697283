import React from "react";
import { Card, Row, Col } from "antd";
import ViewPromotionForm from "./ViewPromotionFormAnt";
import { AppContext } from "../../main/contexts/app.context";

class ViewPromotion extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { userMap, getPermission } = this.props;
    return (
      <Row type="flex" justify="center">
        <Col xs={24} md={24}>
          <Card bordered={false}>
            <ViewPromotionForm userMap={userMap} getPermission={getPermission} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ViewPromotion;
