import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppShell from './Component/main/app-shell/AppShell';
import { AppContextProvider } from './Component/main/contexts/app.context';

class App extends React.Component {
  componentDidMount() {
    this.hideLodingIndicator();
  }

  hideLodingIndicator() {
    const loadingIndicator = document.getElementById('page-loader');
    setTimeout(() => {
      loadingIndicator.classList.add('hide-page-loader');
      setTimeout(() => {
        loadingIndicator.remove();
      }, 500);
    }, 1500);
  }

  render() {
    return (
      <Router>
        <AppContextProvider>
          <AppShell />
        </AppContextProvider>
      </Router>
    );
  }
}

export default App;
