import React from 'react';
import { Row, Col, Card } from 'antd';
import UserGroupTable from './UserGroupTable';
import ViewUserGroup from './usergroup-view/ViewUserGroups';

class UserGroups extends React.Component {

  state = {
    userGroup: true,
    group: '',
    description: '',
    userGroupId: ''
  }

  viewUserGroups = (userGroupId, usergroup, userDescription) => {
    this.setState({userGroupId: userGroupId, userGroup: false, group: usergroup, description: userDescription})
  }

  backToUserGroup = () => {
    this.setState({userGroup: true})
  }

  render() {
    return (
      <Row type='flex' justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            {
              this.state.userGroup ?
              <UserGroupTable viewUserGroups={this.viewUserGroups}></UserGroupTable> :
              <ViewUserGroup backToUserGroup={this.backToUserGroup} ugId={this.state.userGroupId} group={this.state.group} description={this.state.description}/>
            }

          </Card>
        </Col>
      </Row>
    );
  }
}

export default UserGroups;
