export const transformToTableData = serverData => {
  console.log("Server Data", serverData);
  return serverData.map(obj => {
    return {
      key: obj.id,
      type: obj.type,
      code: obj.code,
      bussiness: obj.businessUnit,
      refference: obj.referenceNumber,
      period: obj.warrantyPeriod,
      status: obj.activateWarrantyByDefault.toString()
    };
  });
};
