import React from "react";
import { Card, Row, Col } from "antd";
import ViewWarrantyDetails from "./ViewWarrantyDetails";
import { AppContext } from "../../main/contexts/app.context";
import { addReplaceBulb } from "../../../ApiCalls/warranty-action-call";
import propTypes from 'prop-types'
import {connect} from 'react-redux'


class WarrantyDetails extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {

    };
  }

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <ViewWarrantyDetails addReplaceBulb={this.props.addReplaceBulb}/>
          </Card>
        </Col>
      </Row>
    );
  }
}

WarrantyDetails.propTypes = {
    addReplaceBulb: propTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, {addReplaceBulb}) (WarrantyDetails);
