import React from "react";
import { Row, Col, Table, Form, Button, Spin, DatePicker, Select } from "antd";
import moment from "moment";
import { reportSearch } from "../../../ApiCalls/warranty/warranty-report-call";
import WarrantyReportCSV from "./warranty-report-csv.component";

const { MonthPicker } = DatePicker;
const { Option } = Select;

var tagName = "";

const columns = [
  {
    title: "Serial No",
    dataIndex: "serial",
    fixed: "left",
    width: 150,
    key: "serial"
  },
  {
    title: "Customer Phone",
    dataIndex: "cusPhone",
    key: "cusid"
  },
  {
    title: "Selling Date",
    dataIndex: "selling",
    key: "selling"
  },
  {
    title: "Start Date",
    dataIndex: "expire",
    key: "expire"
  },
  {
    title: "Expire Date",
    dataIndex: "expire",
    key: "status"
  }
];

class ViewWarrantyReports extends React.Component {
  state = {
    data: [],
    csvData: [],
    loading: false,
    month: "",
    year: "",
    params: {},
    pagination: {}
  };

  setTableData = data => {
    console.log(data);

    const transformedData = [];

    for (let i = 0; i < data.length; i++) {
      transformedData.push({
        key: data[i].id,
        serial: data[i].uniqueSerialNumber,
        cusPhone: data[i].endCustomerPhoneNumber,
        selling: data[i].sellDate,
        start: data[i].warrantyEndDate,
        expire: data[i].warrantyStartDate
      });
    }

    this.setState({
      data: transformedData
    });
  };

  resetPagination = () => {
    this.setState({ pagination: { total: 0 } });
  };

  updatePagination = (current, numOfItems) => {
    const pager = { ...this.state.pagination };
    pager.current = current;
    pager.total = numOfItems;
    pager.showTotal = (total, range) =>
      `${range[0]}-${range[1]} of ${total} items`;
    this.setState({
      pagination: pager
    });
  };

  fetchTableDataAsync = (pageSize = 10, pageNumber = 1) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        reportSearch(
          values,
          tagName,
          this.state.month,
          this.state.year,
          pageSize,
          pageNumber
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              if (response.data.content.length > 0) {
                this.setTableData(response.data.content);
                this.updatePagination(pageNumber, response.data.totalElements);
                this.setState({
                  params: {
                    values,
                    tagName,
                    month: this.state.month,
                    year: this.state.year
                  }
                });
              } else {
                this.setState({ data: [], csvData: [], params: {} });
              }
            }
            this.setState({ loading: false });
          })
          .catch(error => {
            console.log(error);
            this.setState({ loading: false });
          });
      }
    });
  };

  updateTable = (pagination, filters, sorter) => {
    this.fetchTableDataAsync(pagination.pageSize, pagination.current);
  };

  reportSearch = e => {
    e.preventDefault();
    this.resetPagination();
    this.fetchTableDataAsync();
  };

  getMonth(date, dateString) {
    var check = moment(dateString, "YYYY/MM/DD");

    var month = check.format("M");
    var year = check.format("YYYY");

    this.setState({ month: month, year: year });
  }

  getTagName = value => {
    var data = this.props.dateTags.filter(item => item.value === value);

    tagName = data[0].name;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { params } = this.state;

    return (
      <>
        <Spin spinning={this.state.loading}>
          <p className="formTitle" style={{ paddingTop: "40px" }}>
            WARRANTY REPORTS
          </p>
          <Form onSubmit={this.reportSearch}>
            <Row type="flex" justify="center" gutter={16}>
              <Col xs={24} md={10}>
                <Form.Item label="Search Tag">
                  {getFieldDecorator("tagValue", {
                    rules: [
                      {
                        required: true,
                        message: "Please choose search tag!"
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select a option and change input text above"
                      size="large"
                      onChange={this.getTagName}
                    >
                      {this.props.dateTags.map((e, key) => {
                        return (
                          <Option key={e.id} value={e.value}>
                            {e.value}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={10}>
                <Form.Item label="Month">
                  {getFieldDecorator("month", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a month!"
                      }
                    ]
                  })(
                    <MonthPicker
                      size="large"
                      placeholder="Select month"
                      onChange={this.getMonth.bind(this)}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="center" gutter={16}>
              <Button
                size="large"
                type="primary"
                icon="search"
                htmlType="submit"
              >
                Search
              </Button>
            </Row>
          </Form>
          <br />
          <div className="table-container">
            <Table
              bordered
              dataSource={this.state.data}
              columns={columns}
              rowClassName="editable-row"
              pagination={this.state.pagination}
              onChange={this.updateTable}
            />
          </div>
        </Spin>
        <br></br>
        <WarrantyReportCSV
          style={{ marginTop: "8px" }}
          searchParams={params}
        ></WarrantyReportCSV>
      </>
    );
  }
}

export default Form.create()(ViewWarrantyReports);
