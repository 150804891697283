import React from "react";
import { Modal, Button } from "antd";
import "./view-image.styles.scss";
import axios from "axios";

class ViewImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      imageUri: null,
      serialNo: null,
      imageSrc: null
    };
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  showModal(serialNo, imageUri) {
    console.log("Image Uri", imageUri);
    this.setState({
      showModal: true,
      serialNo,
      imageUri
    });
    this.getBase64(
      `https://damp-peak-37237.herokuapp.com/api/warranty/bulb${imageUri}`
    );
  }

  getBase64 = async url => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        const imageDataUrl = reader.result;
        console.log("Imagesrc", imageDataUrl);
        if (!this.state.imageSrc) this.setState({ imageSrc: imageDataUrl });
      };
    } catch (error) {
      console.error("Failed loading image", error);
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      imageUri: null,
      imageSrc: null
    });
  };

  render() {
    const { serialNo, showModal, imageSrc } = this.state;

    return (
      <Modal
        title={`Image of ${serialNo}`}
        visible={showModal}
        destroyOnClose={true}
        closable={false}
        footer={null}
      >
        <Button
          className="ViewImage-modal-close-btn"
          shape="circle"
          ghost
          htmlType="button"
          onClick={this.closeModal}
          icon="close"
        />
        {imageSrc && (
          <img
            className="ViewImage-modal-image"
            src={imageSrc}
            alt={`${serialNo}`}
          ></img>
        )}
      </Modal>
    );
  }
}

export default ViewImage;
