import axios from "axios";
import cogoToast from "cogo-toast";

export const login = values => {
  axios
    .post("/authenticate", {
      userName: values.userName,
      password: values.password
    })
    .then(function(response) {
      if (response.status === 200) {
        console.log("AuthData", response.data);
        var usermap = response.data.userTypeMappings;
        sessionStorage.setItem("usermap", JSON.stringify(usermap));
        if (response.data.authorityUser === null) {
          sessionStorage.setItem(
            "userName",
            response.data.endCustomer.userName
          );
          sessionStorage.setItem("userId", response.data.endCustomer.id);
          sessionStorage.setItem(
            "cusPhone",
            response.data.endCustomer.person.primaryPhoneNumber
          );
          sessionStorage.setItem(
            "personId",
            response.data.endCustomer.person.id
          );
        } else {
          sessionStorage.setItem(
            "userName",
            response.data.authorityUser.userName
          );
          sessionStorage.setItem("userId", response.data.authorityUser.id);
          sessionStorage.setItem(
            "cusPhone",
            response.data.authorityUser.person.primaryPhoneNumber
          );
          sessionStorage.setItem(
            "personId",
            response.data.authorityUser.person.id
          );
        }
        const userTypeData = response.data.userType;
        sessionStorage.setItem("userTypeData", JSON.stringify(userTypeData));
        sessionStorage.setItem("userType", response.data.userType.name);
        sessionStorage.setItem("userTypeId", response.data.userType.id);
        sessionStorage.setItem("auth", "Bearer " + response.data.token);
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem(
          "auth"
        );
        window.location.href = "/";
      } else {
        cogoToast.error("Fail to login.");
      }
    })
    .catch(function(error) {
      console.log(error);
      cogoToast.error("Invalid username or password");
    });
};
