import React from 'react';
import { Card, Row, Col, Form, Input, Button, Spin } from 'antd';
import {sendCode, getCode} from '../../../ApiCalls/forgot-password-call'
import {Link} from 'react-router-dom'

import logo from '../../../Assests/Images/d_logo_png.png';
import backTop from '../../../Assests/Images/910.png';

class CodeVarify extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.props.hideShell();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true})
        sendCode(values.code)
        .then(res => {
            this.setState({loading: false})
            sessionStorage.setItem('newAuth', "")
            sessionStorage.setItem('newAuth', res.data)
            this.props.goResetPassword()
          }
        ).catch(error => {
            this.setState({loading: false})
          }
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.loading}>
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]} type='flex' justify='center' className="valign loginbg" style={{paddingLeft: '-100px'}}>
          <Col xs={24} sm={24} md={24} lg={19}>
            <div className="topBar">
              <img src={backTop} alt="backTop" className="backTopImage" />
              <Link to="/login">
                <h2 className="dimotitle">
                  DIMO - Diesel & Motor Engineering PLC
                </h2>
              </Link>
              <Link to="/login">
                <img src={logo} alt="logo" className="logoImage" />
              </Link>
            </div>
            <br />
            <Row type="flex" justify="center">
              <Col xs={8} sm={8}>
                <Link to="/login">
                  <div className="heading">
                    <h1 style={{ fontSize: "22px" }}>
                      LED Warranty Management Portal
                    </h1>
                  </div>
                </Link>
              <br/>
              <Card bordered={true} className="login-card">
                <Row type='flex' justify='center'>
                  <Col xs={24} sm={24} className='loginSep'>
                    <Form onSubmit={this.handleSubmit} className='login-form'>
                      <Form.Item label="Enter the code : ">
                        {getFieldDecorator('code', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input code!'
                            },
                            {
                              len: 6,
                              message: 'Verify code must be exactly 6 characters'
                            }
                          ]
                        })(
                          <Input
                            size='large'
                            placeholder='Verify Code'
                            className='inputLogin'
                          />
                        )}
                      </Form.Item>
                      <br/>
                      <Form.Item style={{ textAlign: 'center' }}>
                        <Button
                          type='primary'
                          htmlType='submit'
                          size="large"
                          className='login-form-button'
                        >
                          Submit
                        </Button>
                      </Form.Item>
                      <Row>
                        <Col sm={12}>
                          <Link to="/forgot-password" style={{display: 'flex', justifyContent: 'flex-start' }} className="login-form-forgot">
                            Resubmit Phone No
                          </Link>
                        </Col>
                        <Col sm={12}>
                          <Link to="/varify-code" style={{display: 'flex', justifyContent: 'flex-end'}} className="login-form-forgot" onClick={()=>{getCode(sessionStorage.getItem('vaifyPhoneNo'))}}>
                            Resend Code
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      </Spin>
    );
  }
}

export default Form.create()(CodeVarify);
