import React, { Component } from 'react'
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    Spin
  } from 'antd';
import { addWarranty } from "../../../ApiCalls/warranty-call";

class AddWarrantyForm extends Component {
      state = {
          sellingDate: '',
          loading: false
      };

      handleSubmit = e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
              this.setState({loading: true})
              addWarranty(values, this.state.sellingDate)
              .then(res => {
                    if (res.status === 200 || res.status === 201) {
                      this.props.form.resetFields()
                      this.setState({
                        serialNo: ''
                      })
                    }
                    this.setState({loading: false})
                }
              ).catch(error => {
                    console.log(error);
                    this.setState({loading: false})
                }
              );
            }
          });
      };

      sellingDate(date, dateString) {
        this.setState({sellingDate: date._d})
      }

      render() {
        const { getFieldDecorator } = this.props.form;

        return (
          <Spin spinning={this.state.loading}>
            <Form onSubmit={this.handleSubmit}>
              <Row type='flex' justify='center'>
                  <Col className="formcol" xs={24} sm={21} md={21} lg={21}>
                      <p className="formTitle">ADD WARRANTY</p>
                      <div className="form_separtion">Item Details</div>
                  </Col>
              </Row>
              <Row type='flex' justify='center'>
                  <Col className="formcol" xs={24} sm={21} md={21} lg={21}>
                      <Button block icon="qrcode" size="large" type="primary" onClick={this.props.qrOpen}>Scan QR</Button>
                  </Col>
              </Row>
              <Row type='flex' justify='center'>
                  <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                      <Form.Item  label="Serial No">
                      {getFieldDecorator('serialNo', {
                          initialValue: this.state.serialNo || this.props.serialNo,
                          rules: [
                          {
                              required: true,
                              message: 'Please input serial number!',
                          },
                          {
                              len: 15,
                              message: 'Serial number must be exactly 15 characters'
                          },
                          ],
                      })(<Input size="large" placeholder="Enter serial no"/>)}
                      </Form.Item>
                  </Col>
                  <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                      <Form.Item  label="Selling Date">
                      {getFieldDecorator('sellingDate', {
                          rules: [
                          {
                              required: true,
                              message: 'Please select selling date!',
                          },
                          ],
                      })(<DatePicker size="large" onChange={this.sellingDate.bind(this)} />)}
                      </Form.Item>
                  </Col>
              </Row>
              <Row type='flex' justify='center'>
                  <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                      {
                        sessionStorage.getItem("cusPhone") !== '' ?
                        <Form.Item  label="Customer Phone No">
                        {getFieldDecorator('phoneNo', {
                            initialValue: sessionStorage.getItem("cusPhone"),
                            rules: [
                              {
                                  required: true,
                                  message: 'Please input phone no!',
                              }
                            ],
                        })(<Input size="large" placeholder="Enter phone no" disabled={true} />)}
                        </Form.Item> :
                        <Form.Item  label="Customer Phone No">
                        {getFieldDecorator('phoneNo', {
                            rules: [
                              {
                                  required: true,
                                  message: 'Please input phone no!',
                              },
                              {
                                len: 10,
                                message: "Phone Number must be exactly 10 digits"
                              },
                              {
                                pattern: new RegExp(/^[0-9]+$/),
                                message: "Please input numbers only"
                              }
                            ],
                        })(<Input size="large" placeholder="Enter phone no" />)}
                        </Form.Item>
                      }
                  </Col>
                  <Col className="formcol" xs={24} sm={21} md={10} lg={10}>

                  </Col>
              </Row>
              <Row type='flex' justify='center'>
                  <Col className="formcol" xs={12} sm={10} md={10} lg={10}>
                      <Col xs={12} sm={10} md={10} lg={10}>
                          <Form.Item>
                            <Button icon="camera" type="primary" size='large'>
                              Take Image
                            </Button>
                          </Form.Item>
                      </Col>
                      <Col className="formcol" xs={12} sm={5} md={5} lg={5}>
                          <Form.Item>
                            <Button icon="cloud-upload" type="primary" size='large'>
                              Upload
                            </Button>
                          </Form.Item>
                      </Col>
                  </Col>
                  <Col className="formcol" xs={12} sm={10} md={10} lg={10}>

                  </Col>
              </Row>
              <Row type='flex' justify='center'>
                  <Col className="formcol" xs={12} sm={10} md={10} lg={10}>
                      <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" className="submit-btn">
                          Save
                        </Button>
                      </Form.Item>
                  </Col>
                  <Col className="formcol" xs={12} sm={10} md={10} lg={10}>

                  </Col>
              </Row>
            </Form>
          </Spin>
        );
      }
    }

export default Form.create()(AddWarrantyForm);
