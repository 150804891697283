import React from 'react';
import { Card, Row, Col, Form, Input, Button } from 'antd';
import axios from "axios";
import {resetPassword} from '../../../ApiCalls/forgot-password-call'
import { Link } from "react-router-dom";

import logo from '../../../Assests/Images/d_logo_png.png';
import backTop from '../../../Assests/Images/910.png';

class PasswordReset extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.hideShell();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        resetPassword(values.newPass)
        .then(res => {
            this.props.goLogin()
          }
        ).catch(error => {
            console.log(error);
          }
        );
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]} type='flex' justify='center' className="valign loginbg" style={{paddingLeft: '-100px'}}>
        <Col xs={24} sm={24} md={24} lg={19}>
            <div className="topBar">
              <img src={backTop} alt="backTop" className="backTopImage" />
              <Link to="/login">
                <h2 className="dimotitle">
                  DIMO - Diesel & Motor Engineering PLC
                </h2>
              </Link>
              <Link to="/login">
                <img src={logo} alt="logo" className="logoImage" />
              </Link>
            </div>
            <br />
            <Row type="flex" justify="center">
              <Col xs={8} sm={8}>
                <Link to="/login">
                  <div className="heading">
                    <h1 style={{ fontSize: "22px" }}>
                      LED Warranty Management Portal
                    </h1>
                  </div>
                </Link>
              <br/>
              <Card bordered={true} className="login-card">
                <Row type='flex' justify='center'>
                  <Col xs={24} sm={24} className='loginSep'>
                    <Form onSubmit={this.handleSubmit} className='login-form'>
                      <Form.Item label="Enter new password : ">
                        {getFieldDecorator('newPass', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input new password!'
                            }
                          ]
                        })(
                          <Input.Password
                              size='large'
                              placeholder='New Password'
                              className='inputLogin'
                          />
                        )}
                      </Form.Item>
                      <Form.Item label="Enter confirm password : ">
                        {getFieldDecorator('confirmPass', {
                          rules: [
                            {
                              required: true,
                              message: 'Please input confirm password!'
                            }
                          ]
                        })(
                          <Input.Password
                              size='large'
                              placeholder='Confirm Password'
                              className='inputLogin'
                          />
                        )}
                      </Form.Item>
                      <br/>
                      <Form.Item style={{ textAlign: 'center' }}>
                        <Button
                          type='primary'
                          htmlType='submit'
                          size="large"
                          className='login-form-button'
                          style={{ align: 'right' }}
                        >
                            Save
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(PasswordReset);
