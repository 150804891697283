import React from "react";
import { Card, Row, Col } from "antd";
import SearchUserForm from "./SearchUserForm";
import { AppContext } from "../../main/contexts/app.context";

class SearchUser extends React.Component {
  static contextType = AppContext;

  render() {
    const { userMap, getPermission } = this.props;

    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <SearchUserForm
              userMap={userMap}
              getPermission={getPermission}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default SearchUser;
