import warrantyMoreActionTypes from "./warranty-more.types";
import axios from "axios";

export const fetchWarrantyMoreStart = () => ({
  type: warrantyMoreActionTypes.FETCH_WARRANTY_MORE_START
});

export const fetchWarrantyMoreSuccess = (types = []) => ({
  type: warrantyMoreActionTypes.FETCH_WARRANTY_MORE_SUCCESS,
  payload: types
});

export const fetchWarrantyMoreFailed = (errorMsg = undefined) => ({
  type: warrantyMoreActionTypes.FETCH_WARRANTY_MORE_FAILED,
  payload: errorMsg
});

export const fetchWarrantyMoreStartAsync = serialNo => {
  return dispatch => {
    dispatch(fetchWarrantyMoreStart());
    axios
      .get(`/api/warranty/bulb/more?uniqueSerialNumber=${serialNo}`)
      .then(function(response) {
        dispatch(fetchWarrantyMoreSuccess(response.data));
      })
      .catch(function(error) {
        console.log(error);
        dispatch(fetchWarrantyMoreFailed("Failed loading more details."));
      });
  };
};
