import axios from "axios";
import cogoToast from "cogo-toast";
import Swal from "sweetalert2";

import { templateStringToUrl } from "../Utils/common.util";

export const getProducts = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/warranty/product/all")
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while loading record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const createProduct = values => {
  const requestUrl = `/api/warranty/product/create?
  type=${values.adding_type}
  &code=${values.adding_code}&businessUnit=${values.adding_bussiness}
  &referenceNumber=${values.adding_refference}
  &warrantyPeriodInMonths=${values.adding_period}
  &activateWarrantyByDefault=${values.adding_status}`;

  return new Promise((resolve, reject) => {
    axios
      .post(templateStringToUrl(requestUrl))
      .then(function(response) {
        resolve(response.data);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const updateProduct = (id, values) => {
  const requestUrl = `/api/warranty/product/update?id=${id}&type=${values.type}
  &code=${values.code}&businessUnit=${values.bussiness}
  &referenceNumber=${values.refference}
  &warrantyPeriodInMonths=${values.period}
  &activateWarrantyByDefault=${values.status}`;

  return new Promise((resolve, reject) => {
    axios
      .post(templateStringToUrl(requestUrl))
      .then(function(response) {
        if (response.status === 201 || response.status === 200) {
          cogoToast.success("Successfully updated product.");
        } else {
          cogoToast.error("Fail to updated product.");
        }
        resolve(response);
      })
      .catch(function(error) {
        cogoToast.error("Server failed");
        reject(error);
      });
  });
};

export const deleteProduct = id => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        axios
          .delete(`/api/warranty/product/delete?id=${id}`)
          .then(function(response) {
            if (response.status === 201 || response.status === 200) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Failed!", "Fail to deleted product.", "error");
            }
            resolve(response);
          })
          .catch(function(error) {
            if (
              error.response.data !== undefined &&
              error.response.data !== null
            ) {
              if (
                error.response.data.message !== undefined &&
                error.response.data.message !== null
              ) {
                Swal.fire(
                  "Error!",
                  "Message : " + error.response.data.message,
                  "error"
                );
              } else {
                Swal.fire(
                  "Error!",
                  "Message : " + error.response.data,
                  "error"
                );
              }
            } else {
              Swal.fire(
                "Error!",
                "Message : Unknown error while deleting record",
                "error"
              );
            }
            reject(error);
          });
      } else {
        reject("Error");
      }
    });
  });
};


export const uploadProductData = formData => {
  return axios
    .post("/api/warranty/ser/create", formData);
};