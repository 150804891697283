import axios from "axios";
import Swal from "sweetalert2";

export const getEndCustomer = (userId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/account/user/searchsimple?searchVal=${userId}`)
    .then(function(response) {
      resolve(response)
    })
    .catch(function(error) {
      if (error.response.data !== undefined && error.response.data !== null) {
        if (error.response.data.message !== undefined && error.response.data.message !== null) {
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data.message,
            'error'
          )
        }else{
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data,
            'error'
          )
        }
      }else {
        Swal.fire(
          'Error!',
          'Message : Unknown error while loading record',
          'error'
        )
      }
      reject(error.response)
    });
  })
};