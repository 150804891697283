import React, { Component } from "react";
import { Form, Input, Row, Col, Button, Spin } from "antd";
import { getEndCustomer } from "../../ApiCalls/mydetails-call";
import { connect } from "react-redux";
import { fetchUserTypesStartAsync } from "../../Redux/user-types/user-types.actions";
import { updateUserDetails } from "../../ApiCalls/my-details-call";
import Swal from "sweetalert2";

class UpdateMyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      isEditable: false,
      formValues: undefined,
      updateUser: false
    };
  }

  setValues = data => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        firstName: data.person.firstName,
        lastName: data.person.lastName,
        noNIC: data.person.noNIC,
        noPassport: data.person.noPassport,
        addressLine1: data.person.addressLine1,
        addressLine2: data.person.addressLine2,
        city: data.person.city,
        zipCode: data.person.zipCode,
        primaryPhoneNumber: data.person.primaryPhoneNumber,
        secondaryPhoneNumber: data.person.secondaryPhoneNumber,
        primaryEmail: data.person.primaryEmail,
        secondaryEmail: data.person.secondaryEmail
      });
    }, 40);
  };

  loadUserInfo() {
    getEndCustomer(sessionStorage.getItem("cusPhone"))
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          if (res.data.authorityUserList.length > 0) {
            this.setValues(res.data.authorityUserList[0]);
          } else {
            this.setValues(res.data.endCustomerList[0]);
          }
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    this.props.fetchUserTypesStartAsync();
    this.loadUserInfo();
  }

  getSuitableUri = userTypes => {
    const endCustomer = userTypes.find(
      userType => userType.userType === "End Customer"
    );

    const authUserType = JSON.parse(sessionStorage.getItem("userTypeData"));

    console.log("authUserType", authUserType);
    console.log("endCustomer", endCustomer);

    if (endCustomer.permission <= authUserType.permission) {
      return "modifyendcustomer";
    } else {
      return "modifyauthorityuser";
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { userTypes } = this.props;
    const { updateUser, isEditable, formValues } = this.state;
    const authUserType = JSON.parse(sessionStorage.getItem("userTypeData"));
    const userId = sessionStorage.getItem("userId");
    const userTypeId = sessionStorage.getItem("userTypeId");
    const persoinId = sessionStorage.getItem("personId");
    console.log("User rypes", userTypes);
    if (updateUser && isEditable && userTypes.length > 0) {
      const uriEnding = this.getSuitableUri(userTypes);
      console.log("Uri", uriEnding);
      try {
        await updateUserDetails(
          formValues,
          uriEnding,
          userId,
          userTypeId,
          persoinId
        );
        Swal.fire("Success!", "User info updated.", "success");
        this.setState({ isEditable: false, updateUser: false });
        this.loadUserInfo();
      } catch (error) {
        Swal.fire("Error!", "Failed updating user info.", "error");
      }
    } else if (
      updateUser &&
      isEditable &&
      authUserType.name === "End Customer"
    ) {
      try {
        await updateUserDetails(
          formValues,
          "modifyendcustomer",
          userId,
          userTypeId,
          persoinId
        );
        Swal.fire("Success!", "User info updated.", "success");
        this.setState({ isEditable: false, updateUser: false });
        this.loadUserInfo();
      } catch (error) {
        Swal.fire("Error!", "Failed updating user info.", "error");
      }
    }
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      console.log(values);
      this.setState({ formValues: values, updateUser: true });
    });
  };

  toggleEditable = event => {
    event.preventDefault();
    this.setState(state => {
      console.log(state);
      return { isEditable: !state.isEditable, updateUser: false };
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isEditable } = this.state;

    return (
      <Spin spinning={this.state.loading}>
        <Form onSubmit={this.handleSubmit}>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24}>
              <p className="formTitle">My Details</p>
              <div className="form_separtion">User Details</div>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="First Name">
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your first name!"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Please input characters only"
                    }
                  ]
                })(
                  <Input
                    autoFocus
                    size="large"
                    placeholder="Enter first name"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Last Name">
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your last name!"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Please input characters only"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter last name"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="NIC No">
                {getFieldDecorator("noNIC", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your nic no!"
                    },
                    {
                      min: 10,
                      max: 12,
                      message: "Invalid nic no!"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter nic no"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Passport No">
                {getFieldDecorator(
                  "noPassport",
                  {}
                )(
                  <Input
                    size="large"
                    placeholder="Enter passport no"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24}>
              <Form.Item label="Address 1">
                {getFieldDecorator("addressLine1", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your address 1!"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter address 1"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Address 2">
                {getFieldDecorator(
                  "addressLine2",
                  {}
                )(
                  <Input
                    size="large"
                    placeholder="Enter address 2"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="City">
                {getFieldDecorator("city", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your city!"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Please input characters only"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter city"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Zip Code">
                {getFieldDecorator("zipCode", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your zip code!"
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input numbers only"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter zip code"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24}>
              <div className="form_separtion">Other Details</div>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Primary Phone No">
                {getFieldDecorator("primaryPhoneNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your primary phone no!"
                    },
                    {
                      len: 10,
                      message: "Phone Number must be exactly 10 digits"
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input numbers only"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter phone no"
                    style={{ width: "100%" }}
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Secondary Phone No">
                {getFieldDecorator("secondaryPhoneNumber", {
                  rules: [
                    {
                      len: 10,
                      message: "Phone Number must be exactly 10 digits"
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input numbers only"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter phone no"
                    style={{ width: "100%" }}
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Primary Email">
                {getFieldDecorator("primaryEmail", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your primary email address!"
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter email address"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Secondary Email">
                {getFieldDecorator("secondaryEmail", {
                  rules: [
                    {
                      type: "email",
                      message: "The input is not valid E-mail!"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter email address"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!"
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z0-9._-]+$/),
                      message:
                        "Password should only include a-z, A-Z, 0-9, _, -, . characters only."
                    },
                    {
                      validator: this.validateToNextPassword
                    }
                  ]
                })(
                  <Input.Password
                    size="large"
                    placeholder="Enter password"
                    disabled={!isEditable}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Confirm Password">
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password!"
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(
                  <Input.Password
                    size="large"
                    placeholder="Enter password"
                    disabled={!isEditable}
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          {isEditable && (
            <Row type="flex" justify="center" gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item>
                  <Button size="large" type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </Col>
              <Col xs={12} md={12}></Col>
            </Row>
          )}
          {!isEditable && (
            <Row type="flex" justify="start">
              <Col xs={24} md={12}>
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="button"
                    onClick={this.toggleEditable}
                  >
                    Edit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = ({ userTypes }) => ({
  userTypes: userTypes.types
});
const mapDispatchToProps = dispatch => ({
  fetchUserTypesStartAsync: () => {
    dispatch(fetchUserTypesStartAsync());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(UpdateMyDetails));
