import axios from "axios";
import Swal from "sweetalert2";

export const addReplaceBulb = (
  values,
  oldId,
  oldUniqueSerialNumber
) => async dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/api/warranty/bulb/replace?batchNumber=" +
          values.batchNo +
          "&itemNumber=" +
          values.itemNo +
          "&uniqueSerialNumber=" +
          values.serialNo +
          "&endCustomerPhoneNumber=" +
          values.phoneNo +
          "&oldId=" +
          oldId +
          "&oldUniqueSerialNumber=" +
          oldUniqueSerialNumber +
          ""
      )
      .then(function(response) {
        if (response.status === 200) {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Success!",
            html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                        <tr>
                          <td align="left">Warranty Status :</td>
                          <td align="left" className="leftPad">${response.data.warrantyState}</td>
                        </tr>
                        <tr>
                          <td align="left">Serial No :</td>
                          <td align="left" className="leftPad">${response.data.uniqueSerialNumber}</td>
                        </tr>
                        <tr>
                          <td align="left">Customer Phone No :</td>
                          <td align="left" className="leftPad">${response.data.endCustomerPhoneNumber}</td>
                        </tr>
                        <tr>
                          <td align="left">Business Unit:</td>
                          <td align="left" className="leftPad">${response.data.businessUnit}</td>
                        </tr>
                        <tr>
                          <td align="left">Product Code :</td>
                          <td align="left" className="leftPad">${response.data.productCode}</td>
                        </tr>
                        <tr>
                          <td align="left">Batch Number :</td>
                          <td align="left" className="leftPad">${response.data.batchNumber}</td>
                        </tr>
                        <tr>
                          <td align="left">Item Number :</td>
                          <td align="left" className="leftPad">${response.data.itemNumber}</td>
                        </tr>
                        <tr>
                          <td align="left">Warranty Start Date :</td>
                          <td align="left" className="leftPad">${response.data.warrantyStartDate}</td>
                        </tr>
                        <tr>
                          <td align="left">Warranty End Date :</td>
                          <td align="left" className="leftPad">${response.data.warrantyEndDate}</td>
                        </tr>
                      </table>`
          });
        } else {
          Swal.fire("Error!", "Fail to created warranty!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error);
      });
  });
};

export const getWarrantyDetails = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/warranty/bulb/byuniqueserialnumber?uniqueSerialNumber=${sessionStorage.getItem(
          "serial"
        )}`
      )
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while loading record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const addValidate = (data, status, reason) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/warranty/bulb/validate", {
        id: data.id,
        sellerId: data.sellerId,
        sellDate: data.sellDate,
        warrantyStartDate: data.warrantyStartDate,
        warrantyEndDate: data.warrantyEndDate,
        warrantyValidateAuthorityUserId: sessionStorage.getItem("userId"),
        warrantyValidateStatus: status,
        warrantyValidateStatusReason: reason
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          Swal.fire(
            "Success!",
            "Message : Successfully added validate",
            "success"
          );
        } else {
          Swal.fire("Error!", "Fail to added validate!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error);
      });
  });
};

export const addIncident = (id, name, userId, status, reason) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/warranty/bulb/addincident", {
        bulbId: id,
        name: name,
        userId: userId,
        status: status,
        reason: reason
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
          Swal.fire(
            "Success!",
            "Message : Successfully added incident",
            "success"
          );
        } else {
          Swal.fire("Error!", "Fail to added incident!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        console.log(error.response);

        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error);
      });
  });
};

export const getActions = (userId, userType, serialNo) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/warranty/bulb/possibleactions?userId=${userId}&userTypeId=${userType}&uniqueSerialNumber=${serialNo}`
      )
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
        } else {
          Swal.fire("Error!", "Fail to load warranty action reasons!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error);
      });
  });
};
