const userTypesActionTypes = {
  FETCH_USER_TYPES_START: "FETCH_USER_TYPES_START",
  FETCH_USER_TYPES_SUCCESS: "FETCH_USER_TYPES_SUCCESS",
  FETCH_USER_TYPES_FAILED: "FETCH_USER_TYPES_FAILED",
  SAVE_USER_TYPE_START: "SAVE_USER_TYPE_START",
  SAVE_USER_TYPE_SUCCESS: "SAVE_USER_TYPE_SUCCESS",
  SAVE_USER_TYPE_FAILED: "SAVE_USER_TYPE_FAILED"
};

export default userTypesActionTypes;
