import React from 'react';
import { Card, Row, Col, Table, Button, Form, Select, Tag, Tooltip, Spin } from "antd";
import {Link} from 'react-router-dom'
import { getUserGroupsMappings, getAttachUserGroups, getUserGroups, addUserIntoUserGroup } from "../../../ApiCalls/usergroup-call";
import { getUserById } from "../../../ApiCalls/user-call";

const columns = [
  {
    title: 'Title',
    dataIndex: 'title'
  },
  {
    title: 'Value',
    dataIndex: 'value',
  }
];

let data = []

const {Option} = Select

class ViewUser extends React.Component {

  constructor(props) {
      super(props)
      this.state = {
        userData: this.props.userData,
        tags: [],
        userGroups: [],
        userId: '',
        userTypeId: ''
      }
  }

  handleSubmit = e => {
    e.preventDefault();
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          addUserIntoUserGroup(values.usergroup, sessionStorage.getItem("viewUserId"), sessionStorage.getItem("viewUserTypeId"))
          .then(res => {
              if (res.status === 200 || res.status === 201) {
                  if (res.data.length > 0) {
                    this.getUser(sessionStorage.getItem("viewUserId"), sessionStorage.getItem("viewUserTypeId"))
                  }
              }
              this.setState({loading: false})
          }).catch(error => {
              this.setState({loading: false})
          });
          }
      });
  };

  setIntoTable = (data) => {
    const userGroups = []
    for (let i = 0; i < data.length; i++) {
      userGroups.push(
          data[i].userGroup.name
      )         
    }

    this.setState({
      tags: userGroups
    });

    this.forceUpdate();
  }

  getUser = (userId, userTypeId) => {
    this.setState({ loading: true });
    var user = []
    data = []
    getUserById(userId, userTypeId)
    .then(response => {
      if (response.status === 200 || response.status === 201) {
        if (response.data.endCustomerList !== null) {
          user.push(response.data.endCustomerList[0])
        }
        
        if (response.data.authorityUserList !== null) {
          user.push(response.data.authorityUserList[0])
        }

        data.push(
          user[0].person.firstName || '',
          user[0].person.lastName || '',
          user[0].person.noNIC || '',
          user[0].person.noPassport || '',
          user[0].person.primaryEmail || '',
          user[0].person.secondaryEmail || '',
          user[0].person.primaryPhoneNumber || '',
          user[0].person.secondaryPhoneNumber || '',
          user[0].userName || '',
          user[0].userType.name || '',
        )        

        this.setState({userData: data, tags: user[0].attachedUserGroups})
      }
      this.setState({loading: false})
    })
    .catch(error => {
      this.setState({loading: false})
    });
  }

  getAllUserGroups = () => {
    getUserGroups()
    .then(res => {
          if (res.status === 200 || res.status === 201) {
              if (res.data.length > 0) {
                this.setState({userGroups: res.data})
              }
          }
          this.setState({loading: false})
      }
    ).catch(error => {
          this.setState({loading: false})
      }
    );
  }

  componentDidMount() {
    this.getUser(sessionStorage.getItem("viewUserId"), sessionStorage.getItem("viewUserTypeId"))    
    this.getAllUserGroups()
  }

  handleClose = removedTag => {
    console.log(removedTag);
    
   const tags = this.state.tags.filter(tag => tag !== removedTag);
   this.setState({ tags });
 };

  render() {
    let { userData, tags, userGroups } = this.state
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.state.loading}>
        <div>
        <Row type='flex' justify="center">
            <Col xs={24} sm={18}>
              <Card bordered={false}>
                <Row type='flex' justify='center'>
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item style={{}}>
                          {
                            sessionStorage.getItem("viewUserBack") === "userGroup" ? 
                            <Button type="primary" size="large" icon="arrow-left" className="login-form-button">
                                <Link to="/users/groups" style={{color: '#fff'}}>
                                &nbsp;&nbsp;Back
                                </Link>
                            </Button> :
                            <Button type="primary" size="large" icon="arrow-left" className="login-form-button">
                                <Link to="/users/search-users" style={{color: '#fff'}}>
                                &nbsp;&nbsp;Back
                                </Link>
                            </Button>
                          }
                            
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <p className="formTitle" style={{}}>VIEW USER</p>
                      </Col>
                </Row>
                <Table
                    className="ant-view-table"
                    columns={columns}
                    dataSource={!userData ?
                      [] :
                      [
                        {
                          key: 1,
                          title: '',
                          value: ''
                        },
                        {
                          key: 2,
                          title: 'First Name',
                          value: userData[0]
                        },
                        {
                          key: 3,
                          title: 'Last Name',
                          value: userData[1]
                        },
                        {
                          key: 4,
                          title: 'NIC No',
                          value: userData[2]
                        }
                        ,{
                          key: 5,
                          title: 'Passport No',
                          value: userData[3]
                        },
                        {
                          key: 6,
                          title: '',
                          value: ''
                        },
                        {
                          key: 7,
                          title: 'Primary Email',
                          value: userData[4]
                        },
                        {
                          key: 8,
                          title: 'Secondary Email',
                          value: userData[5]
                        },
                        {
                          key: 10,
                          title: 'Primary Phone No',
                          value: userData[6]
                        }
                        ,{
                          key: 11,
                          title: 'Secondary Phone No',
                          value: userData[7]
                        },
                        {
                          key: 12,
                          title: '',
                          value: ''
                        },
                        {
                          key: 13,
                          title: 'Username',
                          value: userData[8]
                        },
                        {
                          key: 14,
                          title: 'User Type',
                          value: userData[9]
                        }
                      ]
                    }
                    bordered
                    pagination={false}
                  />
                  <br/>
                  <Form onSubmit={this.handleSubmit} className="add-user-group">
                    <h3>Already Attach Groups</h3>
                    {tags.map((tag, index) => {
                      const isLongTag = tag.name.length > 20;
                      const tagElem = (
                        <Tag key={tag.id} closable={true} style={{fontSize: '13px'}} color="geekblue" onClose={() => this.handleClose(tag.id)}>
                          {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag.name} key={tag.id}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                    <Row>
                      <Col xs={24} sm={20}>
                          <Form.Item label="Attache User Groups">
                            {getFieldDecorator('usergroup', {
                              rules: [{ required: true, message: 'Please select a user group!' }],
                            })(
                              <Select size="large" placeholder="Please select a user group">
                              {userGroups.map((item, i) => (
                                <Option key={item.id} value={item.id}>{item.name}</Option>
                              ))}
                              </Select>,
                            )}
                          </Form.Item>
                      </Col>
                      <Col xs={24} sm={4}>
                          <Form.Item style={{paddingTop: '40px', paddingLeft: '40px'}}>
                              <Button type="primary" htmlType="submit" size="large" className="login-form-button">
                                Save
                              </Button>
                          </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

export default Form.create({})(ViewUser);
