import React from "react";
import { Table, Input, InputNumber, Form, Button, Spin } from "antd";
import uuid from "uuid/v4";
import axios from "axios";
import cogoToast from "cogo-toast";
import { createUserGroup, getUserGroups } from "../../../ApiCalls/usergroup-call";

const data = [];

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class UserGroupTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...data, { key: uuid() }],
      loading: false
    };

    this.rowCount = 0;

    this.columns = [
      {
        title: "User Group",
        dataIndex: "userGroup",
        width: '40%',
        editable: true
      },
      {
        title: "Description",
        dataIndex: "description",
        width: '40%',
        editable: true
      },
      {
        title: "Action",
        dataIndex: "operation",
        width: '30%',
        render: (text, record) => {
          if (!record.userGroup) {
            return (
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={() => this.save(form, record.key)}
                  >
                    Add
                  </Button>
                )}
              </EditableContext.Consumer>
            );
          } else {
            return (
              <div>
                {record.key !== 0 ? (
                  <Button
                    type="primary"
                    icon="setting"
                    onClick={() =>
                      this.props.viewUserGroups(
                        record.key,
                        record.userGroup,
                        record.description
                      )
                    }
                  >
                    View
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          }
        }
      }
    ];
  }

  setIntoTable = (data) => {
    console.log(data);
    
    const userGroups = [[...data, { key: uuid() }]]
    for (let i = 0; i < data.length; i++) {
      userGroups.push({
          key: data[i].id,
          userGroup: data[i].name,
          description: data[i].description
      })         
    }

    this.setState({
      data: userGroups
    });
  }

  componentWillMount() {
    this.setState({ loading: true });
    getUserGroups()
    .then(res => {
          if (res.status === 200 || res.status === 201) {
              if (res.data.length > 0) {
                this.setIntoTable(res.data)
              }
          }
          this.setState({loading: false})
      }
    ).catch(error => {
          this.setState({loading: false})
      }
    );
  }

  save(form, key) {
    this.setState({loading: true})
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newRow = { ...row, key };
      const previousData = [...this.state.data];
      const index = previousData.findIndex(item => key === item.key);

      previousData.splice(index, 1, newRow);

      createUserGroup(newRow)
      .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.props.form.resetFields()
            }
            this.setState({loading: false})
        }
      ).catch(error => {
            this.setState({loading: false})
        }
      );
    });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === "age" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: !record.userGroup ? true : false
        })
      };
    });

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <p className="formTitle">USER GROUPS</p>
            <EditableContext.Provider value={this.props.form}>
              <div className="table-container">
                <Table
                  components={components}
                  bordered
                  dataSource={this.state.data}
                  columns={columns}
                  rowClassName="UserGroupTable-editable-row"
                  scroll={{ x: 400 }}
                  pagination={{
                    defaultPageSize: 10
                  }}
                />
              </div>
            </EditableContext.Provider>
        </div>
      </Spin>
    );
  }
}

export default Form.create({})(UserGroupTable);
