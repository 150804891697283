import React from "react";
import { Card, Row, Col } from "antd";
import ViewActionStatus from "./ViewActionStatus";
import { AppContext } from "../../main/contexts/app.context";
import {
  getActionStatus,
  createActionStatus,
  getAllReason,
  deleteAction,
  updateActionStatus
} from "../../../ApiCalls/action-status-call";

class ActionStatus extends React.Component {
  static contextType = AppContext;

  state = {
    actionStatus: []
  };

  componentDidMount() {
    getActionStatus()
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          if (res.data.length > 0) {
            this.setState({ actionStatus: res.data });
          }
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <ViewActionStatus
              actionStatus={this.state.actionStatus}
              createActionStatus={createActionStatus}
              getAllReason={getAllReason}
              deleteAction={deleteAction}
              updateActionStatus={updateActionStatus}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
export default ActionStatus;
