import React from "react";
import { Card, Row, Col } from "antd";
import ViewWarrantyReports from "./ViewWarrantyReports";
import { AppContext } from "../../main/contexts/app.context";
import { getWarrantyCheckDate } from "../../../ApiCalls/warranty-call";

class WarrantyReports extends React.Component {
  static contextType = AppContext;

  state = {
    dateTags: []
  };

  componentDidMount() {
    getWarrantyCheckDate()
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          this.setState({ dateTags: res.data });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <ViewWarrantyReports dateTags={this.state.dateTags} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default WarrantyReports;
