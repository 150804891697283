export const transformToTableData = serverData => {
  return serverData
    .map(obj => {
      return {
        key: obj.id,
        state: obj.state,
        status: obj.stateText,
        reason: obj.reason
      };
    })
    .filter(obj => obj.state !== null && obj.status !== null);
};
