import React, { Component } from "react";
import { Form, Input, Row, Col, Button, Upload, Icon, Spin } from "antd";
import Swal from "sweetalert2";
import moment from "moment";
import { getBase64 } from "../../../Utils/common.util";
import { uploadProductData } from "../../../ApiCalls/product-call";

import {
  encode,
  decode,
  trim,
  isBase64,
  isUrlSafeBase64,
} from "url-safe-base64";

class ProductUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      showFileError: false,
    };
  }

  componentDidMount() {
    this.props.form.resetFields();
  }

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [file],
      showFileError: false,
    }));
    return false;
  };

  onRemove = (file) => {
    console.log("on remove");
    this.setState((state) => {
      return {
        fileList: [],
        showFileError: true,
      };
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { form } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const file = this.state.fileList[0];
        if (!file) {
          this.setState({
            showFileError: true,
          });
          return;
        }
        this.setState({
          uploading: true,
        });
        const base64Encoded = await getBase64(file);
        const base64result = base64Encoded.split(",")[1];
        const dataToSend = {
          type: values.type,
          code: values.code,
          serial: base64result,
        };
        console.log("data To send", dataToSend);
        try {
          this.setState({
            uploading: true,
          });
          const response = await uploadProductData(dataToSend);
          console.log(response);
          this.setState({
            uploading: false,
          });
          if (response.data === "success") {
            Swal.fire("Success!", "Upload Sucess", "success");
          } else {
            Swal.fire("Error!", "Something went wrong!", "error");
          }
        } catch (error) {
          console.log(error);
          this.setState({
            uploading: false,
          });
          Swal.fire("Error!", "Something went wrong!", "error");
        }
      }
    });
  };

  onFileChangeHandler = (e) => {
    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const { uploading, fileList, showFileError } = this.state;

    return (
      <Spin spinning={uploading}>
        <Form onSubmit={this.handleSubmit} encType="multipart/form-data">
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24}>
              <div className="form_separtion">Product upload</div>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Code">
                {getFieldDecorator("code", {
                  rules: [
                    {
                      required: true,
                      message: "Please input code!",
                    },
                  ],
                })(<Input autoFocus size="large" placeholder="Enter Code" />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Type">
                {getFieldDecorator("type", {
                  rules: [
                    {
                      required: true,
                      message: "Please input type!",
                    },
                  ],
                })(<Input autoFocus size="large" placeholder="Enter Type" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" justify="center" gutter={16}>
            <Col xs={12} md={12}>
              <Upload
                beforeUpload={this.beforeUpload}
                onRemove={this.onRemove}
                fileList={fileList}
                accept=".xlsx"
              >
                <Button className="ProductUploadForm-file-upload-btn">
                  <Icon type="upload" /> Select File
                </Button>
              </Upload>
              {showFileError && (
                <div class="ant-form-explain ProductUploadForm-upload-error">
                  Please select a file!
                </div>
              )}
            </Col>
            <Col xs={12} md={12}></Col>
          </Row>

          <Row type="flex" justify="start" gutter={16}>
            <Col xs={12} md={12}>
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="ProductUploadForm-save-btn"
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col xs={12} md={12}></Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

export default Form.create()(ProductUploadForm);
