import axios from "axios";
import Swal from "sweetalert2";

export const updateUserDetails = (
  {
    password,
    firstName,
    primaryPhoneNumber,
    secondaryPhoneNumber,
    primaryEmail,
    secondaryEmail,
    noNIC,
    noPassport,
    lastName,
    addressLine1,
    addressLine2,
    city,
    zipCode
  },
  uriEnding,
  userId,
  userTypeId,
  personId
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/account/user/${uriEnding}`, {
        activity: "update",
        id: userId,
        userType: userTypeId,
        personId: personId,
        password,
        firstName,
        primaryPhoneNumber,
        secondaryPhoneNumber,
        primaryEmail,
        secondaryEmail,
        noNIC,
        noPassport,
        lastName,
        addressLine1,
        addressLine2,
        city,
        zipCode
      })
      .then(function(response) {
        if (response.status === 200 || response.status === 201) {
        } else {
          Swal.fire("Error!", "Fail to load user types!", "error");
        }
        resolve(response);
      })
      .catch(function(error) {
        console.log(error);
        reject(error.response);
      });
  });
};
