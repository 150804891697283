import React from "react";
import { Route, Switch, NavLink, withRouter, Redirect } from "react-router-dom";
import { Layout, Menu, Icon, Dropdown, Row, Col } from "antd";
import axios from "axios";
import { withStyles } from "@material-ui/styles";
import { AppContext } from "../contexts/app.context";
import dimo_1000px from "../../../dlogopng.png";
import NotFound404 from "../not-found-404/NotFound404";
import { connect } from "react-redux";
import { logOut } from "../../../Redux/auth-user/auth-user.actions";

import LoadingBar from "react-top-loading-bar";

import Login from "../../main/login/Login";
import PhoneNo from "../../main/forgot-password/PhoneNo";
import CodeVarify from "../../main/varify-code/CodeVarify";
import PasswordReset from "../../main/reset-password/PasswordReset";
import Welcome from "../../main/welcome/Welcome";
import AddWarranty from "../add-warranty/CreateWarranty";

import AddPromotion from "../../promotion/add-promotion/CreatePromotion";
import ViewPromotion from "../../promotion/view-promotion/ViewPromotion";

import CreateUser from "../../users/add-user/CreateUser";
import SearchUser from "../../users/search-user/SearchUser";
import ViewUser from "../../users/view-user/ViewUser";
import UserTypes from "../../users/user-types/user-types";
import UserGroups from "../../users/user-groups/user-groups";
import UserReports from "../../users/user-reports/UserReports";

import CreateWarranty from "../../warranty/add-warranty/CreateWarranty";
import ViewWarranty from "../../warranty/view-warranty/ViewWarranty";
import ViewMine from "../../warranty/view-mine/ViewMine";
import WarrantyDetails from "../../warranty/warranty-details/WarrantyDetails";
import WarrantyReports from "../../warranty/warranty-reports/WarrantyReports";

import ViewProducts from "../../warranty-settings/products/Product";
import ViewActionStatus from "../../warranty-settings/action-status/ActionStatus";
import PromotionDetails from "../../promotion/view-promotion/Details/Details";

import MyDetails from "../../my-details/MyDetails";

import { simpleSearch } from "../../../ApiCalls/user-call";

import "./AppShell.scss";
import "./AppShellTab.css";
import "./AppShellMobile.css";

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

const styles = {
  pageHeader: {
    zIndex: "1000",
    padding: "8px 16px",
    position: "fixed",
    width: "100%"
  },
  sider: {},
  content: {
    margin: "24px 16px",
    padding: 0,
    height: "100%",
    marginTop: "80px"
  },
  appLogo: {
    height: " 60px",
    margin: "0",
    borderBottom: "1px solid #f0f2f5",
    backgroundImage: `url(${dimo_1000px})`
  },
  menu: {
    height: "100vh"
  }
};

class AppShell extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isShellHidden: false,
      opendSubmenus: [this.props.location.pathname.split("/")[1]],
      title: "Home",
      loggedIn: false,
      userMap: [],
      userData: null
    };
    sessionStorage.setItem("username", "");
    this.hideShell = this.hideShell.bind(this);
    this.showShell = this.showShell.bind(this);
    this.menuLinkClicked = this.menuLinkClicked.bind(this);
  }

  hideShell() {
    this.setState({
      isShellHidden: true
    });
  }

  goVarifyCode = () => {
    this.props.history.push("/varify-code");
  };

  goResetPassword = () => {
    this.props.history.push("/reset-password");
  };

  goLogin = () => {
    this.props.history.push("/login");
  };

  showShell() {
    this.setState({
      isShellHidden: false,
      opendSubmenus: [],
      usermap: null
    });
  }

  titleChange(title) {
    this.setState({ title: title });
  }

  menuLinkClicked() {
    const { startRouting } = this.context;
    startRouting();
    this.LoadingBar.continuousStart(10);
  }

  componentDidMount() {
    const userMap = JSON.parse(sessionStorage.getItem("usermap"));
    if (userMap !== null) {
      this.setState({ userMap: userMap });
    }

    const authToken = sessionStorage.getItem("auth");
    console.log(authToken);

    if (!authToken) {
      this.props.history.push("/login");
    }

    this.clearRouteListener = this.props.history.listen((...params) => {
      setTimeout(() => {
        this.LoadingBar.complete();
      }, 150);
      this.handleSideMenuOnResize();
    });

    window.addEventListener("resize", this.onResize.bind(this));
    this.onResize();
  }

  handleSideMenuOnResize = () => {
    if (window.innerWidth <= 992 && !this.state.collapsed) {
      this.setState({ collapsed: true });
      //This timeout is here to fix a bug in ant side menu.
      //The bug is showing a submenu of the current active menu
      //when changing state to collapsed.
      setTimeout(() => {
        const submenu = document.querySelector(".ant-menu-submenu-popup");
        if (submenu) {
          submenu.remove();
        }
      }, 0);
    }
  };

  onResize = () => {
    this.handleSideMenuOnResize();
    if (window.innerWidth > 992 && this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  };

  componentWillUnmount() {
    this.clearRouteListener();
  }

  getPermission = (propertyType, propertyName) => {
    const permission = this.state.userMap.find(element => {
      return (
        element.propertyType === propertyType &&
        element.propertyName === propertyName
      );
    });
    return permission === undefined || permission.status === "Enable"
      ? true
      : false;
  };

  toggle = () => {
    this.setState(st => {
      return {
        collapsed: !st.collapsed,
        opendSubmenus: !st.collapsed
          ? []
          : [this.props.location.pathname.split("/")[1]]
      };
    });
  };

  signOut = () => {
    console.log("Sign Out");
    sessionStorage.setItem("auth", "");
    this.props.logOut();
    this.props.history.push("/login");
  };

  render() {
    const { classes } = this.props;
    const { location } = this.props;
    const selectedLink = [location.pathname];
    const { userMap } = this.state;

    //console.log("menulinks", menuLinks);

    const menu = (
      <Menu>
        <Menu.Item>{sessionStorage.getItem("userType")}</Menu.Item>
        <Menu.Item>My Details</Menu.Item>
        <Menu.Item
          onClick={item => {
            this.signOut();
          }}
        >
          <Icon type="logout" />
          Sign Out
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout
        className={this.state.isShellHidden ? "AppShell-hidden" : "AppShell"}
      >
        <LoadingBar
          onRef={ref => (this.LoadingBar = ref)}
          height={3}
          color="red"
        />
        <Sider
          ref={this.myRef}
          className={classes.sider + " AppShell-sider"}
          theme="light"
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
        >
          <div className={classes.appLogo + " AppShell-logo"}></div>
          <Menu
            openKeys={this.state.opendSubmenus}
            onOpenChange={openKeys => {
              this.setState({ opendSubmenus: [openKeys.slice(-1)[0]] });
            }}
            selectedKeys={selectedLink}
            onClick={this.menuLinkClicked}
            className={classes.menu + " AppShell-side-menu"}
            theme="light"
            mode="inline"
            defaultSelectedKeys={["1"]}
          >
            {this.getPermission("UI Menu Item", "menu_users") && (
              <SubMenu
                key="users"
                className="menu_users"
                title={
                  <span>
                    <Icon type="user" />
                    <span>Users</span>
                  </span>
                }
              >
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_add_user"
                ) && (
                  <Menu.Item
                    key="/users/add-user"
                    onClick={() => this.titleChange("Add User")}
                    className="any"
                  >
                    <NavLink
                      to="/users/add-user"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="user-add" />
                      <span>Add User</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_search_users"
                ) && (
                  <Menu.Item
                    key="/users/search-users"
                    onClick={() => this.titleChange("Search User")}
                  >
                    <NavLink
                      to="/users/search-users"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="search" />
                      <span>Search Users</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_user_groups"
                ) && (
                  <Menu.Item
                    key="/users/groups"
                    onClick={() => this.titleChange("User Group")}
                    className="sub_menu_user_groups"
                  >
                    <NavLink
                      to="/users/groups"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="team" />
                      <span>Users Groups</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_user_types"
                ) && (
                  <Menu.Item
                    key="/users/types"
                    onClick={() => this.titleChange("User Types")}
                  >
                    <NavLink
                      to="/users/types"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="solution" />
                      <span>User Types</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_user_reports"
                ) && (
                  <Menu.Item
                    key="/users/user-reports"
                    onClick={() => this.titleChange("User Reports")}
                  >
                    <NavLink
                      to="/users/user-reports"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="solution" />
                      <span>User Reports</span>
                    </NavLink>
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            {this.getPermission("UI Menu Item", "menu_warranty") && (
              <SubMenu
                key="warranty"
                className="menu_warranty"
                title={
                  <span>
                    <Icon type="audit" />
                    <span>Warranty</span>
                  </span>
                }
              >
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_add_warranty"
                ) && (
                  <Menu.Item
                    key="/warranty/add-warranty"
                    onClick={() => this.titleChange("Add Warranty")}
                    className="any"
                  >
                    <NavLink
                      to="/warranty/add-warranty"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="file-done" />
                      <span>Add Warranty</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_search_warranty"
                ) && (
                  <Menu.Item
                    key="/warranty/view-warranty"
                    onClick={() => this.titleChange("View Warranty")}
                  >
                    <NavLink
                      to="/warranty/view-warranty"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="file-search" />
                      <span>View Warranty</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_view_warranty"
                ) && (
                  <Menu.Item
                    key="/warranty/view-mine"
                    onClick={() => this.titleChange("View Mine")}
                  >
                    <NavLink
                      to="/warranty/view-mine"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="file-search" />
                      <span>View Mine</span>
                    </NavLink>
                  </Menu.Item>
                )}
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_warranty_reports"
                ) && (
                  <Menu.Item
                    key="/warranty/warranty-report"
                    onClick={() => this.titleChange("Warranty Reports")}
                  >
                    <NavLink
                      to="/warranty/warranty-report"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="file-search" />
                      <span>Warranty Reports</span>
                    </NavLink>
                  </Menu.Item>
                )}
              </SubMenu>
            )}
            {this.getPermission("UI Menu Item", "menu_promotion") && (
              <SubMenu
                key="promotion"
                className="menu_promotion"
                title={
                  <span>
                    <Icon type="tag" />
                    <span>Promotion</span>
                  </span>
                }
              >
                {this.getPermission(
                  "UI Sub Menu Item",
                  "sub_menu_add_promotion"
                ) && (
                  <Menu.Item
                    key="/promotion/add-promotion"
                    onClick={() => this.titleChange("View Warranty")}
                    className="sub_menu_add_promotion"
                  >
                    <NavLink
                      to="/promotion/add-promotion"
                      activeClassName="Appshell-active-link"
                    >
                      <Icon type="file-add" />
                      <span>Add Promotion</span>
                    </NavLink>
                  </Menu.Item>
                )}
                <Menu.Item
                  key="/promotion/view-promotion"
                  onClick={() => this.titleChange("View Warranty")}
                >
                  <NavLink
                    to="/promotion/view-promotion"
                    activeClassName="Appshell-active-link"
                  >
                    <Icon type="tags" />
                    <span>View Promotion</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )}
            {this.getPermission("UI Menu Item", "menu_warranty_settings") && (
              <SubMenu
                key="warranty-settings"
                title={
                  <span>
                    <Icon type="exception" />
                    <span>Warranty Settings</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/warranty-settings/product"
                  onClick={() => this.titleChange("Product")}
                >
                  <NavLink
                    to="/warranty-settings/product"
                    activeClassName="Appshell-active-link"
                  >
                    <Icon type="gold" />
                    <span>Products</span>
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/warranty-settings/action"
                  onClick={() => this.titleChange("Action Status Reason")}
                >
                  <NavLink
                    to="/warranty-settings/action"
                    activeClassName="Appshell-active-link"
                  >
                    <Icon type="gold" />
                    <span>Action Status Reason</span>
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )}
            {/* {this.getPermission("UI Menu Item", "menu_system_settings") && (
              <Menu.Item
                key="/system-settings"
                onClick={() => this.titleChange("View Warranty")}
              >
                <NavLink
                  to="/system-settings"
                  activeClassName="Appshell-active-link"
                >
                  <Icon type="setting" />
                  <span>System Settings</span>
                </NavLink>
              </Menu.Item>
            )} */}
            <Menu.Item
              key="/my-deatils"
              onClick={() => this.titleChange("View Warranty")}
            >
              <NavLink to="/my-deatils" activeClassName="Appshell-active-link">
                <Icon type="tool" />
                <span>My Details</span>
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            className={
              this.state.collapsed
                ? "AppShell-header AppShell-header-collapsed"
                : "AppShell-header"
            }
          >
            <Icon
              className="AppShell-trigger"
              type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
              onClick={this.toggle}
            />
            <NavLink to="/home">
              <h2 className="ledTitle">DIMO LED MANAGEMENT PORTAL</h2>
            </NavLink>
            <Menu
              className="Appshell-hedader-menu"
              mode="horizontal"
              style={{ lineHeight: "64px" }}
            >
              <Menu.Item key="2">
                <Dropdown overlay={menu}>
                  <span
                    className="ant-dropdown-link"
                    style={{
                      display: "inline-block",
                      color: "#fff",
                      fontSize: "16px",
                      textTransform: "uppercase"
                    }}
                  >
                    {sessionStorage.getItem("userName")} <Icon type="down" />
                  </span>
                </Dropdown>
              </Menu.Item>
            </Menu>
          </Header>
          <Content className={classes.content + " AppShell-content"}>
            <div className="">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() =>
                    axios.defaults.headers.common["Authorization"] !== null ? (
                      <Redirect to="/home" />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/home"
                  render={() => <Welcome></Welcome>}
                ></Route>
                <Route
                  exact
                  path="/login"
                  render={() => (
                    <Login
                      hideShell={this.hideShell}
                      showShell={this.showShell}
                    ></Login>
                  )}
                ></Route>
                <Route
                  exact
                  path="/add-warranty"
                  render={() => <AddWarranty hideShell={this.hideShell} />}
                ></Route>
                <Route
                  exact
                  path="/forgot-password"
                  render={() => (
                    <PhoneNo
                      hideShell={this.hideShell}
                      goVarifyCode={this.goVarifyCode}
                    ></PhoneNo>
                  )}
                ></Route>
                <Route
                  exact
                  path="/varify-code"
                  render={() => (
                    <CodeVarify
                      hideShell={this.hideShell}
                      goResetPassword={this.goResetPassword}
                    ></CodeVarify>
                  )}
                ></Route>
                <Route
                  exact
                  path="/reset-password"
                  render={() => (
                    <PasswordReset
                      hideShell={this.hideShell}
                      goLogin={this.goLogin}
                    ></PasswordReset>
                  )}
                ></Route>
                <Route
                  exact
                  path="/users/add-user"
                  render={() => <CreateUser></CreateUser>}
                ></Route>
                <Route
                  exact
                  path="/users/search-users"
                  render={() => (
                    <SearchUser
                      userMap={userMap}
                      getPermission={this.getPermission}
                    ></SearchUser>
                  )}
                ></Route>
                <Route
                  exact
                  path="/users/view-user"
                  render={() => (
                    <ViewUser userData={this.state.userData}></ViewUser>
                  )}
                ></Route>
                <Route
                  exact
                  path="/users/groups"
                  render={() => <UserGroups></UserGroups>}
                ></Route>
                <Route
                  exact
                  path="/users/types"
                  render={() => <UserTypes></UserTypes>}
                ></Route>
                <Route
                  exact
                  path="/users/user-reports"
                  render={() => <UserReports />}
                ></Route>
                <Route
                  exact
                  path="/warranty/view-warranty"
                  render={() => (
                    <ViewWarranty
                      userMap={userMap}
                      getPermission={this.getPermission}
                    ></ViewWarranty>
                  )}
                ></Route>
                <Route
                  exact
                  path="/warranty/view-mine"
                  render={() => (
                    <ViewMine
                      userMap={userMap}
                      getPermission={this.getPermission}
                    ></ViewMine>
                  )}
                ></Route>
                <Route
                  exact
                  path="/warranty/warranty-report"
                  render={() => <WarrantyReports />}
                ></Route>
                <Route
                  exact
                  path="/warranty/add-warranty"
                  render={() => <CreateWarranty></CreateWarranty>}
                ></Route>
                <Route
                  exact
                  path="/warranty/view-warranty-details"
                  render={() => <WarrantyDetails></WarrantyDetails>}
                ></Route>
                <Route
                  exact
                  path="/promotion/add-promotion"
                  render={() => <AddPromotion></AddPromotion>}
                ></Route>
                <Route
                  exact
                  path="/promotion/view-promotion"
                  render={() => <ViewPromotion></ViewPromotion>}
                ></Route>
                <Route
                  exact
                  path="/promotion/view-promotion/details"
                  render={() => <PromotionDetails></PromotionDetails>}
                ></Route>
                <Route
                  exact
                  path="/warranty-settings/product"
                  render={() => <ViewProducts />}
                ></Route>
                <Route
                  exact
                  path="/warranty-settings/action"
                  render={() => <ViewActionStatus />}
                ></Route>
                {/* <Route
                  exact
                  path="/system-settings"
                  render={() => <h2>System Settings</h2>}
                ></Route> */}
                <Route
                  exact
                  path="/my-deatils"
                  render={() => <MyDetails />}
                ></Route>
                <Route
                  exact
                  render={() => (
                    <NotFound404
                      hideShell={this.hideShell}
                      showShell={this.showShell}
                    ></NotFound404>
                  )}
                ></Route>
              </Switch>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(logOut())
});
export default connect(
  null,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AppShell)));
