export const transformReportServerData = serverResult => {
  console.log("Server result", serverResult);
  const { content, totalElements, pageable } = serverResult;
  console.log("Available data", content);
  const transformedData = {
    data: [],
    pagination: {
      total: totalElements,
      current: totalElements ? pageable.pageNumber + 1 : 1
    }
  };
  transformedData.data = content;
  return transformedData;
};
