import React, { Component } from "react";
import { Table, Button, Input, Form, Select, Spin, Popconfirm } from "antd";
import uuid from "uuid/v4";
import Swal from "sweetalert2";
import { transformToTableData } from "./action-status.utils";

const data = [];
const { Option } = Select;

const EditableContext = React.createContext();

var state = "";

class EditableCell extends React.Component {
  getState = value => {
    var data = this.props.actionStatus.filter(item => item.stateText === value);
    if (data.length > 0) {
      state = data[0].state;
    } else {
      state = data.state;
    }
  };

  getInput = () => {
    if (this.props.dataIndex === "status") {
      const distinctValues = this.props.actionStatus.filter(
        ({ stateText }, index) => {
          return (
            this.props.actionStatus.findIndex(
              item => item.stateText === stateText
            ) === index
          );
        }
      );

      return (
        <Select
          style={{ width: 200 }}
          placeholder="Select a action status"
          size="large"
          onChange={this.getState}
        >
          {distinctValues.map((e, key) => {
            return (
              <Option key={e.id} value={e.stateText}>
                {e.stateText}
              </Option>
            );
          })}
        </Select>
      );
    }
    return <Input size="large" />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      adding,
      dataIndex,
      title,
      inputType,
      actionStatus,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    const formItemName = adding ? `adding_${dataIndex}` : dataIndex;

    return (
      <td {...restProps}>
        {editing || adding ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(formItemName, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`
                }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

class ViewActionStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ key: uuid() }],
      loading: false,
      productData: []
    };

    this.rowCount = 0;

    this.columns = [
      {
        title: "Action Status",
        dataIndex: "status",
        width: 250,
        editable: false,
        addable: true
      },
      {
        title: "Reason",
        dataIndex: "reason",
        width: 770,
        editable: true,
        addable: true
      },
      {
        title: "Action",
        dataIndex: "operation",
        fixed: "right",
        width: 140,
        render: (text, record) => {
          if (!record.status) {
            return (
              <EditableContext.Consumer>
                {form => (
                  <Button
                    type="primary"
                    icon="plus"
                    onClick={() => this.save(form, record.key)}
                  >
                    Add
                  </Button>
                )}
              </EditableContext.Consumer>
            );
          } else {
            const { editingKey } = this.state;
            const editable = this.isEditing(record);
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={() => this.update(form, record.key)}
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={() => this.cancel(record.key)}
                >
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <div>
                {/* <a onClick={() => this.edit(record.key)}>Edit</a>
                &nbsp;&nbsp;&nbsp;&nbsp; */}
                <a onClick={() => this.deleteItem(record.key)}>Delete</a>
              </div>
            );
          }
        }
      }
    ];
  }

  deleteItem = id => {
    this.props
      .deleteAction(id)
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          this.getAllReasons();
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  edit(key) {
    this.setState({ editingKey: key });
  }

  isEditing = record => record.key === this.state.editingKey;

  addNewItem = data => {
    this.setState({
      data: [
        ...this.state.data,
        {
          key: data.id,
          status: data.stateText,
          reason: data.reason
        }
      ]
    });
  };

  componentDidMount() {
    this.getAllReasons();
  }

  getAllReasons = () => {
    //this.setState({ data: [...data, { key: uuid() }] });
    this.props
      .getAllReason()
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          const transformedData = transformToTableData(res.data);
          this.setState({ data: [{ key: uuid() }, ...transformedData] });
          console.log("Transformed data", transformedData);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  update(form, key) {
    form.validateFields(["status", "reason"], {}, (error, row) => {
      if (error) {
        return;
      }
      this.setState({ loading: true });
      // const newRow = { ...row, key };
      // const previousData = [...this.state.data];
      // const index = previousData.findIndex(item => key === item.key);

      // previousData.splice(index, 1, newRow);

      // var data = this.state.data.filter(item => item.key === key);

      console.log("Edited Fields", row);

      this.props
        .updateActionStatus(key, data.state, row)
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            this.getAllReasons();
            form.resetFields();
            this.cancel();
          }
          this.setState({ loading: false });
        })
        .catch(error => {
          this.setState({ loading: false });
        });
    });
  }

  save(form, key) {
    form.validateFields(async (error, row) => {
      if (error) {
        return;
      }

      console.log("New Reson", row);

      const { actionStatus, createActionStatus } = this.props;

      const stateOption = actionStatus.filter(
        option => option.stateText === row.adding_status
      );

      console.log("State Option", stateOption);

      const statusData =
        stateOption.length > 0
          ? { ...stateOption[0], reason: row.adding_reason }
          : null;

      console.log("Adding Status data", statusData);
      form.resetFields();

      if (statusData) {
        this.setState({ loading: true });
        try {
          const createdState = await createActionStatus(statusData);
          this.getAllReasons();
          this.setState({ loading: false });
          console.log("Created Status", createdState);
          Swal.fire("Success!", "Status successfully added.", "success");
          return;
        } catch (error) {
          Swal.fire("Failed!", "Status add failed.", "error");
          console.error("Error Creating Action Status", error);
          this.setState({ loading: false });
          return;
        }
      }
      Swal.fire("Failed!", "Status add failed.", "error");
    });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell
      }
    };

    console.log("State data", this.state.data);

    const columns = this.columns.map(col => {
      if (!col.editable && !col.addable) {
        return col;
      }
      if (col.addable || col.editable) {
        return {
          ...col,
          onCell: record => ({
            record,
            inputType: col.dataIndex === "status" ? "dropdown" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            actionStatus: this.props.actionStatus,
            editing: this.isEditing(record) && col.editable,
            adding: !record.state && col.addable ? true : false
          })
        };
      }
    });

    console.log("Action Status", this.props.actionStatus);

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <p className="formTitle">ACTION STATUS REASON</p>
          <EditableContext.Provider
            value={this.props.form}
            actionStatus={this.props.actionStatus}
          >
            <div className="table-container">
              <Table
                components={components}
                bordered
                dataSource={this.state.data}
                columns={columns}
                rowClassName="UserTypeTable-editable-row"
                scroll={{ x: 500 }}
                pagination={{
                  defaultPageSize: 10
                }}
              />
            </div>
          </EditableContext.Provider>
        </div>
      </Spin>
    );
  }
}

export default Form.create({})(ViewActionStatus);
