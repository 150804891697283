import React from "react";
import { Card, Row, Col } from "antd";
import ViewWarrantyTable from "./ViewWarrantyTable";
import { AppContext } from "../../main/contexts/app.context";

class ViewWarranty extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { userMap, getPermission } = this.props;
    return (
      <Row type="flex" justify="center">
        <Col xs={24} md={18}>
          <Card bordered={false}>
            <ViewWarrantyTable userMap={userMap} getPermission={getPermission} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ViewWarranty;
