import React, { Component } from "react";
import axios from "axios";
import { Form, Input, Select, Row, Col, Button, DatePicker, Spin } from "antd";
import Swal from "sweetalert2";
import moment from "moment";

const { Option } = Select;

class AddPromotionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  state = {
    file: []
  };

  componentDidMount() {
    this.props.form.resetFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    var self = this;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        const formData = new FormData();
        formData.append("title", values.title);
        formData.append(
          "startDate",
          moment.utc(values.startDate).format("YYYY-MM-DD")
        );
        formData.append(
          "endDate",
          moment.utc(values.endDate).format("YYYY-MM-DD")
        );
        formData.append("description", values.description);
        formData.append("file", this.state.selectedFile);
        axios
          .post(`/api/promotion/promotion/create`, formData)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              console.log(res.data);
              this.props.form.resetFields();
              self.setState({ loading: false });
              Swal.fire({
                icon: "success",
                title: "Successfully Added Promotion!",
                html: `<table className="viewTable" cellpadding="5" cellspacing="5">
                                <tr>
                                  <td align="left">Title :</td>
                                  <td align="left" className="leftPad">${
                                    values.title
                                  }</td>
                                </tr>
                                <tr>
                                  <td align="left">Start Date :</td>
                                  <td align="left" className="leftPad">${moment
                                    .utc(values.startDate)
                                    .format("YYYY-MM-DD")}</td>
                                </tr>
                                <tr>
                                  <td align="left">End Date :</td>
                                  <td align="left" className="leftPad">${moment
                                    .utc(values.startDate)
                                    .format("YYYY-MM-DD")}</td>
                                </tr>
                                <tr>
                                  <td align="left">Description :</td>
                                  <td align="left" className="leftPad">${
                                    values.description
                                  }</td>
                                </tr>
                            </table>`
              });
            } else {
              Swal.fire("Error!", "Fail to created promotion!", "error");
            }
          })
          .catch(err => {
            self.setState({ loading: false });
            console.log(err.response);
            Swal.fire("Error!", "Server Failed!", "error");
          });
      }
    });
  };

  onFileChangeHandler = e => {
    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0]
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} encType="multipart/form-data">
        <Row type="flex" justify="center" gutter={16}>
          <Col xs={24}>
            <p className="formTitle">CREATE PROMOTION</p>
            <div className="form_separtion">Promotion Details</div>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col xs={24}>
            <Form.Item label="Title">
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Please input title!"
                  }
                ]
              })(<Input autoFocus size="large" placeholder="Enter title" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Promotion Start Date">
              {getFieldDecorator("startDate", {
                rules: [
                  {
                    required: true,
                    message: "Please input promotion start date!"
                  }
                ]
              })(<DatePicker size="large" />)}
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Promotion End Date">
              {getFieldDecorator("endDate", {
                rules: [
                  {
                    required: true,
                    message: "Please input promotion end date!"
                  }
                ]
              })(<DatePicker size="large" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="center" gutter={16}>
          <Col xs={24}>
            <Form.Item label="Description">
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: "Please input promotion description!"
                  }
                ]
              })(<Input size="large" placeholder="Enter description" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="center" gutter={16}>
          <Col xs={12} md={12}>
            <Form.Item>
              {getFieldDecorator("image", {
                rules: [
                  {
                    required: true,
                    message: "Please upload a image!"
                  }
                ]
              })(
                <Input
                  type="file"
                  name="file"
                  size="large"
                  onChange={this.onFileChangeHandler}
                  accept=".png, .jpg, .jpeg"
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={12} md={12}></Col>
        </Row>
        <Row type="flex" justify="start" gutter={16}>
          <Col xs={12} md={12}>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
          <Col xs={12} md={12}></Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(AddPromotionForm);
