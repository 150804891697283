import axios from "axios";
import Swal from "sweetalert2";
import { templateStringToUrl } from "../../Utils/common.util";

export const reportSearch = (
  values,
  tagName,
  month,
  year,
  pageSize,
  pageNumber
) => {
  const uri = `/api/warranty/report/search?dateTagValue=${values.tagValue}&dateTagName=${tagName}&year=${year}&month=${month}&sortBy=""
&pageSize=${pageSize}&pageNumber=${pageNumber}`;

  return new Promise((resolve, reject) => {
    axios
      .get(templateStringToUrl(uri))
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        Swal.fire(
          "Error!",
          "Message : " + error.response.data.message,
          "error"
        );
        reject(error.response);
      });
  });
};
