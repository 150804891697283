import React from "react";
import { Row, Col, Button, Modal } from "antd";
import { reportSearch } from "../../../ApiCalls/warranty/warranty-report-call";
import { CSVLink } from "react-csv";
import { transformReportServerData } from "./warranty-report.utils";

class WarrantyReportCSV extends React.Component {
  perPage = 10;
  reDownloadTimeout = null;

  constructor(props) {
    super(props);
    this.state = {
      csvHeader: [],
      csvData: [],
      pagination: { current: 1 },
      csvReady: false,
      csvTitle: null,
      showModal: false,
      modalText: null,
      cancelDownload: false
    };
  }

  flattenObject = (
    obj,
    skipFields = ["id", "invoiceImageName", "incidents"]
  ) => {
    const flattened = {};

    Object.keys(obj).forEach(key => {
      if (skipFields.includes(key)) return;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        Object.assign(flattened, this.flattenObject(obj[key]));
      } else {
        flattened[key] = obj[key];
      }
    });
    return flattened;
  };

  transformToCSVHeader = dataObj => {
    let header = [];
    const fieldsArray = Object.keys(dataObj);
    fieldsArray.forEach(field => {
      const result = field.replace(/([A-Z])/g, " $1");
      const redableField = result.charAt(0).toUpperCase() + result.slice(1);
      const headeObj = {
        label: redableField,
        key: field
      };
      header = [...header, headeObj];
    });
    return header;
  };

  searchReport = async (
    params,
    currentPage = 1,
    perPage = this.props.perPage || this.perPage
  ) => {
    console.log("Warranty report parrams", params);
    try {
      const { values, tagName, month, year } = params;
      const searchResult = await reportSearch(
        values,
        tagName,
        month,
        year,
        perPage,
        currentPage
      );

      console.log("Report server result", searchResult);
      const transformedData = transformReportServerData(searchResult.data);
      const pagination = transformedData.pagination;
      const pager = this.updatePagination(
        pagination.current,
        pagination.total,
        perPage
      );
      console.log("Pagination", pager);
      console.log("Transformed Data", transformedData.data);
      if (transformedData.data.length > 0) {
        const flatternedDataObjects = transformedData.data.map(obj =>
          this.flattenObject(obj)
        );
        console.log("Flat Objects", flatternedDataObjects);
        const header = this.transformToCSVHeader(flatternedDataObjects[0]);
        console.log("Headers", header);
        this.setState(
          {
            showModal: true,
            csvHeader: header,
            csvData: flatternedDataObjects,
            csvReady: true,
            csvTitle: `warranty_${pager.rangeText}.csv`
          },
          () => {
            console.log("State", this.state);
            this.fireClickOnCSV();
          }
        );

        if (
          pager.current * perPage < pager.total &&
          !this.state.cancelDownload
        ) {
          this.reDownloadTimeout = setTimeout(() => {
            this.setState(
              { csvHeader: [], csvData: [], csvReady: false },
              () => {
                this.searchReport(this.props.searchParams, pager.current + 1);
              }
            );
          }, 1500);
        } else {
          this.setState({
            csvHeader: [],
            csvData: [],
            csvReady: false,
            showModal: false
          });
          clearTimeout(this.reDownloadTimeout);
          if (!this.state.cancelDownload) {
            Modal.success({
              content: "Dwonload completed."
            });
          }
        }
      }
    } catch (error) {
      console.error("Error in advance user search csv", error);
    }
  };

  updatePagination = (current = 1, total, perPage) => {
    const pager = { ...this.state.pagination };
    pager.current = current;
    pager.total = total;
    pager.numOfPages = Math.ceil(total / perPage);
    pager.rangeText = `page ${current} of ${pager.numOfPages}`;
    return pager;
  };

  fireClickOnCSV = () => {
    this.csvLink.link.click();
  };

  onDownloadCSV = event => {
    event.stopPropagation();
    this.setState({ csvReady: false, cancelDownload: false }, () => {
      console.log("Csv Link", this.csvLink);
      const { searchParams } = this.props;
      const { pagination } = this.state;
      console.log("CSV searchparams", searchParams);
      this.searchReport(searchParams, pagination.current);
    });
  };

  componentWillUnmount = () => {
    clearTimeout(this.reDownloadTimeout);
  };

  cancelDownload = () => {
    this.setState({ showModal: false, cancelDownload: true });
    clearTimeout(this.reDownloadTimeout);
  };

  render() {
    const { csvHeader, csvData, csvReady, csvTitle, showModal } = this.state;

    return (
      <>
        <Row type="flex" justify="end">
          <Col>
            <Button
              size="large"
              type="primary"
              icon="download"
              htmlType="submit"
              onClick={this.onDownloadCSV}
            >
              Download CSV
            </Button>
            <CSVLink
              style={{ display: "none" }}
              headers={csvHeader}
              data={csvData}
              filename={csvTitle}
              onClick={event => {
                console.log("You click the link");
                if (csvReady) return true;
                return false;
              }}
              ref={csvLink => (this.csvLink = csvLink)}
            ></CSVLink>
          </Col>
        </Row>
        <Modal
          title="Downloading csv"
          visible={showModal}
          destroyOnClose={true}
          closable={false}
          footer={null}
        >
          <p>Downloading {csvTitle}</p>
          <Row type="flex" justify="end">
            <Col>
              <Button
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.cancelDownload}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default WarrantyReportCSV;
