import React from 'react';
import { Result, Button } from 'antd';
import { NavLink } from 'react-router-dom';

class NotFound404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.hideShell();
  }

  componentWillUnmount() {
    this.props.showShell();
  }

  render() {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <NavLink to='/'>
            <Button type='primary'>Back Home</Button>
          </NavLink>
        }
      />
    );
  }
}

export default NotFound404;
