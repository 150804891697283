import React from "react";
import "./warranty-more.styles.scss";
import { Modal, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import { fetchWarrantyMoreStartAsync } from "../../../Redux/warranty-more/warranty-more.actions";
import { Divider } from "antd";
import uuid from "uuid/v4";

class WarrantyMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, serialNo: null };
  }
  componentDidMount() {
    this.props.onRef(this);
    console.log(this.props);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  showModal(serialNo) {
    console.log("Show Modal", serialNo);
    this.props.fetchWarrantyMoreStartAsync(serialNo);
    this.setState({
      showModal: true,
      serialNo: serialNo
    });
  }

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  transformFields(warrantyMore) {
    const transformedData = warrantyMore.map(dataObj => {
      const preparedObj = {};
      const fieldsArray = Object.keys(dataObj);
      fieldsArray.forEach(field => {
        const result = field.replace(/([A-Z])/g, " $1");
        const redableField = result.charAt(0).toUpperCase() + result.slice(1);
        if (Array.isArray(dataObj[field])) {
          preparedObj[redableField] = this.transformFields(dataObj[field]);
        } else {
          preparedObj[redableField] = dataObj[field] || "N/A";
        }
      });
      return preparedObj;
    });
    return transformedData;
  }

  componentDidUpdate(prevProps, prevState) {
    const warrantyMore = this.props.warrantyMore;
    if (warrantyMore.length > 0 && this.state.showModal) {
      this.transformFields(warrantyMore);
    }
  }

  render() {
    const warrantyMore = this.props.warrantyMore;
    let warrantyMoreReadable = [];
    if (warrantyMore.length > 0 && this.state.showModal) {
      warrantyMoreReadable = this.transformFields(warrantyMore);
    }

    console.log(warrantyMoreReadable);

    const listToShow = warrantyMoreReadable.map((obj, index) => {
      return Object.keys(obj).map((keyName, keyIndex) => {
        if (!Array.isArray(obj[keyName])) {
          return (
            <li key={uuid()} className="warranty-more-list-item">
              {keyName}: <span className="warranty-more-spacer"></span>
              {obj[keyName]}
              <Divider key={uuid()} className="warranty-more-divider"></Divider>
            </li>
          );
        } else {
          const parentName = (
            <li key={uuid()} className="warranty-more-list-item">
              {keyName}:
            </li>
          );
          const list = obj[keyName].map((nestedObj, keyIndex) => {
            const singleList = Object.keys(nestedObj).map(
              (nestedkeyName, keyIndex) => {
                return (
                  <li key={uuid()} className="warranty-more-list-item">
                    <span className="warranty-more-spacer"></span>
                    {nestedkeyName}:{" "}
                    <span className="warranty-more-spacer"></span>
                    {nestedObj[nestedkeyName]}
                    <Divider
                      key={uuid()}
                      className="warranty-more-divider"
                      dashed={true}
                    ></Divider>
                  </li>
                );
              }
            );
            return [
              singleList,
              <Divider
                key={uuid()}
                className="warranty-more-divider child-divider"
              ></Divider>
            ];
          });
          console.log("List", list);
          return [
            parentName,
            list,
            <Divider
              key={uuid()}
              className="warranty-more-divider parent-divider"
            ></Divider>
          ];
        }
      });
    });

    const { serialNo, showModal } = this.state;

    return (
      <Modal
        title={`${serialNo} more details`}
        visible={showModal}
        destroyOnClose={true}
        closable={false}
        footer={null}
      >
        {listToShow}
        <Row type="flex" justify="end">
          <Col>
            <Button
              size="large"
              type="primary"
              htmlType="button"
              onClick={this.closeModal}
            >
              Ok
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = ({ warrantyMore }) => ({
  warrantyMore: warrantyMore.data,
  isFetching: warrantyMore.isFetching,
  fetchError: warrantyMore.fetchError
});

const mapDispatchToProps = dispatch => ({
  fetchWarrantyMoreStartAsync: serialNo => {
    dispatch(fetchWarrantyMoreStartAsync(serialNo));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyMore);
