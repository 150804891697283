import userTypesActionTypes from "./user-types.types";
import axios from "axios";

export const fetchUserTypesStart = () => ({
  type: userTypesActionTypes.FETCH_USER_TYPES_START
});

export const fetchUserTypesSuccess = (types = []) => ({
  type: userTypesActionTypes.FETCH_USER_TYPES_SUCCESS,
  payload: types
});

export const fetchUserTypesFailed = (errorMsg = undefined) => ({
  type: userTypesActionTypes.FETCH_USER_TYPES_FAILED,
  payload: errorMsg
});

export const saveUserTypeStart = () => ({
  type: userTypesActionTypes.SAVE_USER_TYPE_START
});

export const saveUserTypeSuccess = (newUserType = []) => ({
  type: userTypesActionTypes.SAVE_USER_TYPE_SUCCESS,
  payload: newUserType
});

export const saveUserTypeFailed = (errorMsg = undefined) => ({
  type: userTypesActionTypes.SAVE_USER_TYPE_FAILED,
  payload: errorMsg
});

const userTypeTransformer = serverData => {
  sessionStorage.setItem("allUserTypes", JSON.stringify(serverData));
  const UserTypes = serverData.map(receivedItem => ({
    key: receivedItem.id,
    permission: receivedItem.permission,
    userType: receivedItem.name,
    description: receivedItem.description,
    parentId: receivedItem.parentName,
    parent: receivedItem.parentId
  }));
  return UserTypes;
};

export const fetchUserTypesStartAsync = () => {
  return dispatch => {
    dispatch(fetchUserTypesStart());
    const userTypeData =
      JSON.parse(sessionStorage.getItem("userTypeData")) || {};
    axios
      .get(
        `/api/account/usertype/allwith?permission=${userTypeData.permission}`
      )
      .then(function(response) {
        dispatch(fetchUserTypesSuccess(userTypeTransformer(response.data)));
      })
      .catch(function(error) {
        console.log(error);
        dispatch(fetchUserTypesFailed("Failed loading user types."));
      });
  };
};

export const saveUserTypeStartAsync = userTypeData => {
  return dispatch => {
    dispatch(saveUserTypeStart());
    axios
      .post(
        "/api/account/usertype/create?name=" +
          userTypeData.userType +
          "&description=" +
          userTypeData.description +
          "&parentId=" +
          parseInt(userTypeData.parentId)
      )
      .then(function(response) {
        console.log("Saved from Redux", response.data);
        dispatch(saveUserTypeSuccess(userTypeTransformer([response.data])));
      })
      .catch(function(error) {
        dispatch(saveUserTypeFailed("Failed saving user type."));
      });
  };
};
