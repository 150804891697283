import React, { Component } from "react";
import { Table, Button, Form, Spin, Popconfirm } from "antd";
import uuid from "uuid/v4";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getProducts,
  createProduct,
  deleteProduct,
  updateProduct,
} from "../../../ApiCalls/product-call";
import { transformToTableData } from "./product.utils";
import { EditableContext, EditableCell } from "./product-edit.context";

class ViewProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ key: uuid() }],
      loading: true,
    };

    this.rowCount = 0;

    this.columns = [
      {
        title: "Type",
        dataIndex: "type",
        width: 200,
        editable: true,
      },
      {
        title: "Code",
        dataIndex: "code",
        width: 200,
        editable: true,
      },
      {
        title: "Bussiness Unit",
        dataIndex: "bussiness",
        width: 150,
        editable: true,
      },
      {
        title: "Reference No",
        dataIndex: "refference",
        width: 150,
        editable: true,
      },
      {
        title: "Warranty Period (in months)",
        dataIndex: "period",
        width: 150,
        editable: true,
      },
      {
        title: "Activate Warranty by Default",
        dataIndex: "status",
        width: 150,
        editable: true,
      },
      {
        title: "Action",
        dataIndex: "operation",
        fixed: "right",
        width: 150,
        render: (text, record) => {
          if (!record.type) {
            return (
              <EditableContext.Consumer>
                {(form) => (
                  <Button
                    type="primary"
                    icon="plus"
                    className="submit-btn"
                    onClick={() => this.addNewProduct(form, record.key)}
                  >
                    Add
                  </Button>
                )}
              </EditableContext.Consumer>
            );
          } else {
            const { editingKey } = this.state;
            const editable = this.isEditing(record);
            return editable ? (
              <span>
                <EditableContext.Consumer>
                  {(form) => (
                    <a
                      onClick={() => this.update(form, record.key)}
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </a>
                  )}
                </EditableContext.Consumer>
                <Popconfirm
                  title="Sure to cancel?"
                  onConfirm={() => this.cancel(record.key)}
                >
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <div>
                <a onClick={() => this.edit(record.key)}>Edit</a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a onClick={() => this.deleteItem(record.key)}>Delete</a>
              </div>
            );
          }
        },
      },
    ];
  }

  loadProducts = async () => {
    try {
      const fetchedProducts = await getProducts();
      const transformedData = transformToTableData(fetchedProducts);
      console.log("Transformed Data", transformedData);
      this.setState({
        data: [{ key: uuid() }, ...transformedData],
        loading: false,
      });
    } catch (error) {
      this.setState({ data: { key: uuid() }, loading: false });
      console.log(error);
    }
  };

  addNewProduct = (form, key) => {
    form.validateFieldsAndScroll(
      [
        "adding_type",
        "adding_code",
        // "adding_bussiness",
        "adding_refference",
        "adding_period",
        "adding_status",
      ],
      {},
      async (errors, values) => {
        console.log("Errors", errors);
        console.log("Values", values);
        if (errors) return;
        try {
          this.setState({ loading: true });
          const createdProduct = await createProduct(values);
          form.resetFields();
          this.addNewProductToTable(createdProduct);
          this.setState({ loading: false });
          Swal.fire("Added!", "New product has been added.", "success");
          console.log("createdProduct", createdProduct);
        } catch (error) {
          this.setState({ loading: false });
          Swal.fire("Error!", "Failed adding new product.", "error");
          console.error("Error creating Product", error);
        }
      }
    );
  };

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  edit(key) {
    this.setState({ editingKey: key });
  }

  isEditing = (record) => record.key === this.state.editingKey;

  addNewProductToTable = (newProduct) => {
    this.setState((state, props) => ({
      data: [
        ...state.data,
        {
          key: newProduct.id,
          type: newProduct.type,
          code: newProduct.code,
          bussiness: newProduct.businessUnit,
          refference: newProduct.referenceNumber,
          period: newProduct.warrantyPeriod,
          status: "" + newProduct.activateWarrantyByDefault,
        },
      ],
    }));
  };

  deleteItem = (key) => {
    deleteProduct(key)
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
          this.loadProducts();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.loadProducts();
  }

  update(form, key) {
    console.log("Update", form);
    form.validateFieldsAndScroll(
      ["type", "code", "refference", "period", "status"],
      {},
      async (errors, values) => {
        if (errors) return;
        console.log("Values", values, key);
        try {
          await updateProduct(key, values);
          this.loadProducts();
          this.setState({ editingKey: "" });
        } catch (error) {
          console.error("Failed upading product", error);
          this.setState({ editingKey: "" });
        }
      }
    );
  }

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      this.setState({ loading: true });
      const newRow = { ...row, key };
      const previousData = [...this.state.data];
      const index = previousData.findIndex((item) => key === item.key);

      previousData.splice(index, 1, newRow);

      createProduct(newRow)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.addNewItem(res.data);
            form.resetFields();
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    });
  }

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    console.log("Props on vewProduct", this.props);

    const inputTypeMap = {
      period: "number",
      status: "check",
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => {
          const inputType = inputTypeMap[col.dataIndex] || "text";
          return {
            record,
            inputType: inputType,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: this.isEditing(record),
            adding: !record.type ? true : false,
          };
        },
      };
    });

    return (
      <Spin spinning={this.state.loading}>
        <div>
          <p className="formTitle">PRODUCTS</p>
          <EditableContext.Provider value={this.props.form}>
            <div className="table-container">
              <Table
                components={components}
                bordered
                dataSource={this.state.data}
                columns={columns}
                rowClassName="UserTypeTable-editable-row"
                scroll={{ x: 500 }}
                pagination={{
                  defaultPageSize: 10,
                }}
              />
            </div>
          </EditableContext.Provider>
        </div>
      </Spin>
    );
  }
}

export default Form.create({})(ViewProducts);
