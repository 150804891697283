import React, { Component } from "react";
import { Form, Input, Select, Row, Col, Button, Spin } from "antd";
import { addUser, addCustomer } from "../../../ApiCalls/user-call";
import { fetchUserTypesStartAsync } from "../../../Redux/user-types/user-types.actions";
import { connect } from "react-redux";
import axios from "axios";

const { Option } = Select;

class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      userTypes: [],
      loading: false
    };
  }

  componentDidMount() {
    this.props.form.resetFields();
    axios.defaults.headers.common["Authorization"] = sessionStorage.getItem(
      "auth"
    );
    this.props.fetchUserTypesStartAsync();
  }

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const allUserTypes = JSON.parse(sessionStorage.getItem("allUserTypes"));

        var entCustomer = allUserTypes.filter(function(el) {
          return el.name === "End Customer";
        });

        if (values.userType == entCustomer[0].id) {
          addCustomer(values, "create")
            .then(res => {
              if (res.status === 201 || res.status === 200) {
                this.setState({ loading: false });
                this.props.form.resetFields();
              } else {
                this.setState({ loading: false });
              }
            })
            .catch(error => {
              console.log(error);
              this.setState({ loading: false });
            });
        } else {
          addUser(values, "create")
            .then(res => {
              if (res.status === 201 || res.status === 200) {
                this.setState({ loading: false });
                this.props.form.resetFields();
              } else {
                this.setState({ loading: false });
              }
            })
            .catch(error => {
              this.setState({ loading: false });
            });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { userTypes } = this.props;

    return (
      <Spin spinning={this.state.loading}>
        <Form onSubmit={this.handleSubmit}>
          <Row type="flex" justify="center">
            <Col xs={24}>
              <p className="formTitle">CREATE USER</p>
              <div className="form_separtion">User Details</div>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="First Name">
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your first name!"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Please input characters only"
                    }
                  ]
                })(
                  <Input
                    autoFocus
                    size="large"
                    placeholder="Enter first name"
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Last Name">
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your last name!"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Please input characters only"
                    }
                  ]
                })(<Input size="large" placeholder="Enter last name" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="NIC No">
                {getFieldDecorator("noNIC", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your nic no!"
                    },
                    {
                      min: 10,
                      max: 12,
                      message: "Invalid nic no!"
                    }
                  ]
                })(<Input size="large" placeholder="Enter nic no" />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Passport No">
                {getFieldDecorator(
                  "noPassport",
                  {}
                )(<Input size="large" placeholder="Enter passport no" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={24}>
              <Form.Item label="Address 1">
                {getFieldDecorator("addressLine1", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your address 1!"
                    }
                  ]
                })(<Input size="large" placeholder="Enter address 1" />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item label="Address 2">
                {getFieldDecorator(
                  "addressLine2",
                  {}
                )(<Input size="large" placeholder="Enter address 2" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="City">
                {getFieldDecorator("city", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your city!"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Please input characters only"
                    }
                  ]
                })(<Input size="large" placeholder="Enter city" />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Zip Code">
                {getFieldDecorator("zipCode", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your zip code!"
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input numbers only"
                    }
                  ]
                })(<Input size="large" placeholder="Enter zip code" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24}>
              <div className="form_separtion">Other Details</div>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Primary Phone No">
                {getFieldDecorator("primaryPhoneNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your primary phone no!"
                    },
                    {
                      len: 10,
                      message: "Phone Number must be exactly 10 digits"
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input numbers only"
                    }
                  ]
                })(
                  <Input
                    size="large"
                    placeholder="Enter phone no"
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Secondary Phone No">
                {getFieldDecorator("secondaryPhoneNumber", {
                  pattern: new RegExp(/^[A-Za-z]+$/),
                  message: "Please input characters only"
                })(
                  <Input
                    size="large"
                    placeholder="Enter phone no"
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Primary Email">
                {getFieldDecorator("primaryEmail", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your primary email address!"
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!"
                    }
                  ]
                })(<Input size="large" placeholder="Enter email address" />)}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Secondary Email">
                {getFieldDecorator("secondaryEmail", {
                  rules: [
                    {
                      type: "email",
                      message: "The input is not valid E-mail!"
                    }
                  ]
                })(<Input size="large" placeholder="Enter email address" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="User Type">
                {getFieldDecorator("userType", {
                  rules: [
                    {
                      required: true,
                      message: "Please select your user type!"
                    }
                  ]
                })(
                  <Select size="large" placeholder="Please select a user type">
                    {this.props.userTypes.map(userType => (
                      <Option key={userType.key}>{userType.userType}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}></Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!"
                    },
                    {
                      min: 6,
                      message: "Password must be at least 6 characters"
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z0-9._-]+$/),
                      message:
                        "Password should only include a-z, A-Z, 0-9, _, -, . characters only."
                    },
                    {
                      validator: this.validateToNextPassword
                    }
                  ]
                })(
                  <Input.Password size="large" placeholder="Enter password" />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Confirm Password">
                {getFieldDecorator("confirm", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password!"
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(
                  <Input.Password
                    size="large"
                    placeholder="Enter password"
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}></Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = ({ userTypes }) => ({
  userTypes: userTypes.types
});
const mapDispatchToProps = dispatch => ({
  fetchUserTypesStartAsync: () => {
    dispatch(fetchUserTypesStartAsync());
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddUserForm));
