import React from "react";
import { Card, Row, Col, Form, Input, Button, Spin } from "antd";
import { getCode } from "../../../ApiCalls/forgot-password-call";
import { Link } from "react-router-dom";

import logo from "../../../Assests/Images/d_logo_png.png";
import backTop from "../../../Assests/Images/910.png";

class PhoneNo extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.props.hideShell();
    sessionStorage.setItem("cusPhone", "");
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        getCode(values.phoneNo)
          .then(res => {
            if (res.data === "Successful") {
              this.setState({ loading: false });
              sessionStorage.setItem("vaifyPhoneNo", values.phoneNo);
              this.props.goVarifyCode();
            }
          })
          .catch(error => {
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.loading}>
        <Row
          gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 24]}
          type="flex"
          justify="center"
          className="valign loginbg"
          style={{ paddingLeft: "-100px" }}
        >
          <Col xs={24} sm={24} md={24} lg={19}>
            <div className="topBar">
              <img src={backTop} alt="backTop" className="backTopImage" />
              <Link to="/login">
                <h2 className="dimotitle">
                  DIMO - Diesel & Motor Engineering PLC
                </h2>
              </Link>
              <Link to="/login">
                <img src={logo} alt="logo" className="logoImage" />
              </Link>
            </div>
            <br />
            <Row type="flex" justify="center">
              <Col xs={8} sm={8}>
                <Link to="/login">
                  <div className="heading">
                    <h1 style={{ fontSize: "22px" }}>
                      LED Warranty Management Portal
                    </h1>
                  </div>
                </Link>

                <Card bordered={true} className="login-card2">
                  <Row type="flex" justify="start">
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <h2>Reset password</h2>
                      <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item label="Enter your phone number : ">
                          {getFieldDecorator("phoneNo", {
                            rules: [
                              {
                                required: true,
                                message: "Please input your phone number!"
                              },
                              {
                                len: 10,
                                message:
                                  "Phone number must be exactly 10 characters"
                              }
                            ]
                          })(
                            <Input
                              size="large"
                              placeholder="Phone No"
                              className="inputLogin"
                            />
                          )}
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="login-form-button"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                    <Link to="/login">
                      Go Back to Login
                    </Link>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default Form.create()(PhoneNo);
