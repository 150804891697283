import React from "react";
import { Card, Row, Col, Modal } from "antd";
import AddWarrantyForm from "./AddWarrantyForm";
import { AppContext } from "../../main/contexts/app.context";
import ScanQR from "./ScanQR";

class CreateWarranty extends React.Component {
  static contextType = AppContext;
  constructor() {
    super();
    this.state = {
      scanqr: false,
      serialNo: "",
      warrantyData: null,
      isDone: false,
      dataUri: ""
    };
  }

  qrOpen() {
    this.setState({ scanqr: true });
  }

  qrResult(serial) {
    this.setState({ serialNo: serial, scanqr: false });
  }

  render() {
    //finishRouting();
    const { scanqr } = this.state;
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <Modal
              title="Scan QR"
              centered
              visible={scanqr}
              onOk={() => this.setState({ scanqr: false })}
              onCancel={() => this.setState({ scanqr: false })}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              {scanqr && <ScanQR qrResult={this.qrResult.bind(this)} />}
            </Modal>
            <AddWarrantyForm
              addWarranty={this.props.addWarranty}
              qrOpen={this.qrOpen.bind(this)}
              serialNo={this.state.serialNo}
              loaddingShow={this.loaddingShow}
              loaddingHide={this.loaddingHide}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default CreateWarranty;
