import React, { createContext, Component } from 'react';

export const AppContext = createContext();

export class AppContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeLoaded: false
    };
    this.finishRouting = this.finishRouting.bind(this);
    this.startRouting = this.startRouting.bind(this);
  }

  startRouting() {
    this.setState({
      routeLoaded: false
    });
  }

  finishRouting() {
    this.setState({
      routeLoaded: true
    });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          startRouting: this.startRouting,
          finishRouting: this.finishRouting
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
