export const templateStringToUrl = (templateString) => {
  return templateString.replace(/\s/g, "");
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const ObjectFieldsToReadable = (warrantyMore) => {
  const transformedData = warrantyMore.map((dataObj) => {
    const preparedObj = {};
    const fieldsArray = Object.keys(dataObj);
    fieldsArray.forEach((field) => {
      const result = field.replace(/([A-Z])/g, " $1");
      const redableField = result.charAt(0).toUpperCase() + result.slice(1);
      if (Array.isArray(dataObj[field])) {
        preparedObj[redableField] = ObjectFieldsToReadable(dataObj[field]);
      } else {
        preparedObj[redableField] = dataObj[field] || "N/A";
      }
    });
    return preparedObj;
  });
  return transformedData;
};
