import React, { Component } from 'react'
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    Spin
  } from 'antd';

class ReplaceBulb extends Component {
      state = {
          sellingDate: '',
          batchNo: '',
          serialNo: '',
          itemNo: '',
          warrantyDetails: null,
          loading: false
      };

      handleSubmit = e => {
          e.preventDefault();
          this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({loading: true})
              this.props.addReplaceBulb(values, this.props.warrantyData.id, this.props.warrantyData.uniqueSerialNumber)
              .then(res => {
                    this.setState({loading: false})
                }
              ).catch(error => {
                    this.setState({loading: false})
                }
              );
            }
          });
      };

      sellingDate(date, dateString) {
        this.setState({sellingDate: date._d})
      }

      startDate(date, dateString) {
        this.setState({startDate: date._d})
      }

      splitSerial = (e) => {
          var serialNo = e.target.value;
          this.setState({
              serialNo: serialNo
          });
      }

      render() {
        const { getFieldDecorator } = this.props.form;

        return (
        <Spin spinning={this.state.loading}>
            <Form onSubmit={this.handleSubmit}>
                <Row type='flex' justify='center'>
                    <Col className="formcol" xs={24} sm={21} md={21} lg={21}>
                        <p className="formTitle">REPLACE BULB</p>
                        <div className="form_separtion">Item Details</div>
                    </Col>
                </Row>
                <Row type='flex' justify='center'>
                    <Col className="formcol" xs={24} sm={21} md={21} lg={21}>
                        <Button block icon="qrcode" size="large" type="primary" onClick={this.props.qrOpen}>Scan QR</Button>
                    </Col>
                </Row>
                <Row type='flex' justify='center'>
                    <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                        <Form.Item  label="Serial No">
                        {getFieldDecorator('serialNo', {
                            initialValue: this.state.serialNo || this.props.serialNo,
                            rules: [
                            {
                                required: true,
                                message: 'Please input serial number!',
                            },
                            {
                                len: 15,
                                message: 'Serial number must be exactly 15 characters'
                            },
                            ],
                        })(<Input size="large" placeholder="Enter serial no" onChange={this.splitSerial} />)}
                        </Form.Item>
                    </Col>
                    <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                        <Form.Item  label="Old Serial No">
                        {getFieldDecorator('oldSerial', {
                            initialValue: this.props.warrantyData.uniqueSerialNumber,
                            rules: [
                            {
                                required: true,
                                message: 'Please select selling date!',
                            },
                            ],
                        })(<Input size="large" disabled={true} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row type='flex' justify='center'>
                    <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                        <Form.Item  label="Customer Phone No">
                        {getFieldDecorator('phoneNo', {
                            initialValue: this.props.warrantyData.endCustomerPhoneNumber,
                            rules: [
                            {
                                required: true,
                                message: 'Please input phone no!',
                            },
                            ],
                        })(<Input size="large" placeholder="Enter phone no" disabled={true} />)}
                        </Form.Item>
                    </Col>
                    <Col className="formcol" xs={24} sm={21} md={10} lg={10}>
                        
                    </Col>
                </Row>
                <Row type='flex' justify='center'>
                    <Col className="formcol" xs={12} sm={10} md={10} lg={10}>
                        <Form.Item>
                        <Button size="large" type="primary" htmlType="submit">
                            Save
                        </Button>
                        </Form.Item>
                    </Col>
                    <Col className="formcol" xs={12} sm={10} md={10} lg={10}>

                    </Col>
                </Row>
            </Form>
          </Spin>
        );
      }
    }

export default Form.create()(ReplaceBulb);
