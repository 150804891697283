import userTypesActionTypes from "./user-types.types";
import uuid from "uuid/v4";

const INITIAL_STATE = {
  isFetching: false,
  isSaving: false,
  saveSuccess: false,
  types: [],
  typesForUserTypeTable: [{ key: uuid() }],
  fetchError: undefined,
  saveError: undefined
};

const userTypesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypesActionTypes.FETCH_USER_TYPES_START:
      return { ...state, isFetching: true, fetchError: undefined };
    case userTypesActionTypes.FETCH_USER_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        types: action.payload,
        typesForUserTypeTable: [{ key: uuid() }, ...action.payload]
      };
    case userTypesActionTypes.FETCH_USER_TYPES_FAILED:
      return { ...state, isFetching: false, fetchError: action.payload };
    case userTypesActionTypes.SAVE_USER_TYPE_START:
      return {
        ...state,
        isSaving: true,
        fetchError: undefined,
        saveSuccess: false,
        saveError: undefined
      };
    case userTypesActionTypes.SAVE_USER_TYPE_SUCCESS:
      return {
        ...state,
        isSaving: false,
        saveSuccess: true,
        saveError: undefined,
        typesForUserTypeTable: state.typesForUserTypeTable.concat(
          action.payload
        )
      };
    case userTypesActionTypes.SAVE_USER_TYPE_FAILED:
      return {
        ...state,
        isSaving: false,
        saveSuccess: false,
        saveError: action.payload
      };
    default:
      return state;
  }
};

export default userTypesReducer;
