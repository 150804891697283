import React from "react";

class NotFound404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <h2>Welcome</h2>
      </div>
    );
  }
}

export default NotFound404;
