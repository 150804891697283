import React from "react";
import { Card, Row, Col } from "antd";
import UpdateMyDetails from "./UpdateMyDetails";
import { AppContext } from "../main/contexts/app.context";

class MyDetails extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
              <UpdateMyDetails/>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default MyDetails;
