import axios from 'axios'
import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2'

export const createPropType = (userTypeId, newRow) => {
  return new Promise((resolve, reject) => {
    axios.post('/api/account/usertype/createmapping?userTypeId='+userTypeId+'&propertyType='+newRow.propType+'&propertyName='+newRow.propName+'&status='+newRow.status)
    .then(function (response) {
        resolve(response)
    })
    .catch(function (error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (error.response.data.message !== undefined && error.response.data.message !== null) {
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data.message,
              'error'
            )
          }else{
            Swal.fire(
              'Error!',
              'Message : '+ error.response.data,
              'error'
            )
          }
        }else {
          Swal.fire(
            'Error!',
            'Message : Unknown error while adding record',
            'error'
          )
        }
        reject(error.response)
    });
  })
}

export const getPropertyTypes = (userTypeId) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/account/usertype/allmappingsbyusertypeid?userTypeId='+userTypeId)
    .then(function (response) {
        resolve(response)
    })
    .catch(function (error) {
      if (error.response.data !== undefined && error.response.data !== null) {
        if (error.response.data.message !== undefined && error.response.data.message !== null) {
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data.message,
            'error'
          )
        }else{
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data,
            'error'
          )
        }
      }else {
        Swal.fire(
          'Error!',
          'Message : Unknown error while loading record',
          'error'
        )
      }
        reject(error.response)
    });
  })
}


export const deleteProps = (propId) => {
  return new Promise((resolve, reject) => {
    axios.delete('/api/account/usertype/deletemappingbyid?userTypeMappingId='+parseInt(propId))
    .then(function (response) {
      if (response.status === 200 || response.status === 201) {
        Swal.fire(
          'Deleted!',
          'Property type has been deleted.',
          'success'
        )
      }else{
        Swal.fire(
          'Failed!',
          'Fail to deleted property type.',
          'error'
        )
      }
      resolve(response)
    })
    .catch(function (error) {
        console.log(error.response);
        
        if (error.response === undefined || error.response.data === null) {
          Swal.fire(
            'Error!',
            'Message : Server fail.',
            'error'
          )
        }else{
          if (error.response.data !== undefined || error.response.data !== null) {
            if (error.response.data.message !== undefined && error.response.data.message !== null) {
              Swal.fire(
                'Error!',
                'Message : '+ error.response.data.message,
                'error'
              )
            }else{
              Swal.fire(
                'Error!',
                'Message : '+ error.response.data,
                'error'
              )
            }
          }else {
            Swal.fire(
              'Error!',
              'Message : Unknown error while loading record',
              'error'
            )
          }
        }
        reject(error.response)
    });
  })
}
