import authUserActionTypes from "./auth-user.types";
import axios from "axios";

export const authenticationStart = () => ({
  type: authUserActionTypes.AUTHENTICATION_START
});

export const authenticationSuccess = (types = []) => ({
  type: authUserActionTypes.AUTHENTICATION_SUCCESS,
  payload: types
});

export const authenticationFailed = (errorMsg = undefined) => ({
  type: authUserActionTypes.AUTHENTICATION_FAILED,
  payload: errorMsg
});

export const logOut = () => ({
  type: authUserActionTypes.LOG_OUT
});

const setupAxios = () => {
  axios.defaults.baseURL = "https://damp-peak-37237.herokuapp.com";
  axios.defaults.headers.common["Authorization"] = sessionStorage.getItem(
    "auth"
  );
  axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers["crossDomain"] = true;
};


export const authenticationStartAsync = userCredentials => {
  return dispatch => {
    dispatch(authenticationStart());
    axios
      .post("/authenticate", {
        userName: userCredentials.userName,
        password: userCredentials.password
      })
      .then(function(response) {
        if (response.status === 200) {
          var usermap = response.data.userTypeMappings;
          sessionStorage.setItem("usermap", JSON.stringify(usermap));
          if (response.data.authorityUser === null) {
            sessionStorage.setItem(
              "userName",
              response.data.endCustomer.userName
            );
            sessionStorage.setItem("userId", response.data.endCustomer.id);
            sessionStorage.setItem(
              "cusPhone",
              response.data.endCustomer.person.primaryPhoneNumber
            );
          } else {
            sessionStorage.setItem(
              "userName",
              response.data.authorityUser.userName
            );
            sessionStorage.setItem("userId", response.data.authorityUser.id);
            sessionStorage.setItem(
              "cusPhone",
              response.data.authorityUser.person.primaryPhoneNumber
            );
          }
          sessionStorage.setItem("userType", response.data.userType.name);
          sessionStorage.setItem("userTypeId", response.data.userType.id);
          sessionStorage.setItem("auth", "Bearer " + response.data.token);
          setupAxios();
          dispatch(authenticationSuccess());
        } else {
          dispatch(authenticationFailed("Login Failed."));
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(authenticationFailed("Login Failed."));
      });
  };
};
