import axios from "axios";
import cogoToast from "cogo-toast";
import Swal from "sweetalert2";

export const getActionStatus = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/warranty/actionstate/all")
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while loading record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const getAllReason = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/warranty/actionstate/reason/all")
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while loading record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const createActionStatus = ({ state, stateText, reason }) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/warranty/actionstate/reason/add", {
        state,
        stateText,
        reason
      })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while adding record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const updateActionStatus = (id, state, values) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/warranty/actionstate/reason/update", {
        id: id,
        state: state,
        stateText: values.status,
        reason: values.reason
      })
      .then(function(response) {
        resolve(response);
      })
      .catch(function(error) {
        if (error.response.data !== undefined && error.response.data !== null) {
          if (
            error.response.data.message !== undefined &&
            error.response.data.message !== null
          ) {
            Swal.fire(
              "Error!",
              "Message : " + error.response.data.message,
              "error"
            );
          } else {
            Swal.fire("Error!", "Message : " + error.response.data, "error");
          }
        } else {
          Swal.fire(
            "Error!",
            "Message : Unknown error while updating record",
            "error"
          );
        }
        reject(error.response);
      });
  });
};

export const deleteAction = id => {
  return new Promise((resolve, reject) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        axios
          .delete(`/api/warranty/actionstate/reason/delete?id=${id}`)
          .then(function(response) {
            if (response.status === 201 || response.status === 200) {
              Swal.fire("Deleted!", "Your action has been deleted.", "success");
            } else {
              Swal.fire("Failed!", "Fail to deleted action.", "error");
            }
            resolve(response);
          })
          .catch(function(error) {
            if (
              error.response.data !== undefined &&
              error.response.data !== null
            ) {
              if (
                error.response.data.message !== undefined &&
                error.response.data.message !== null
              ) {
                Swal.fire(
                  "Error!",
                  "Message : " + error.response.data.message,
                  "error"
                );
              } else {
                Swal.fire(
                  "Error!",
                  "Message : " + error.response.data,
                  "error"
                );
              }
            } else {
              Swal.fire(
                "Error!",
                "Message : Unknown error while deleting record",
                "error"
              );
            }
            reject(error.response);
          });
      } else {
        reject("Error");
      }
    });
  });
};
