import React, { Component } from "react";
import { Card, Icon, Button, Spin } from "antd";
import { Link } from "react-router-dom";
import {
  Form,
  Row,
  Col
} from "antd";
const { Meta } = Card;

class DetailsView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: JSON.parse(sessionStorage.getItem("itemdetails"))
    }
  }

  render() {
    console.log(this.state.data);
    return (
      
      <div>
        {(
          <Row type='flex' justify='center'>
          <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item style={{paddingTop: '40px', paddingLeft: '40px'}}>
                  <Button type="primary" size="large" icon="arrow-left" className="login-form-button" href="/promotion/view-promotion">
                        &nbsp;Back
                  </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <p className="formTitle" style={{paddingTop: '40px'}}>PROMOTION DETAILS</p>
            </Col>
          </Row>
        )}
        <br />
        <div className="table-container">
          <Card
            cover={
              <img
                width="600"
                height="350"
                alt="image"
                src={"https://damp-peak-37237.herokuapp.com/api/promotion/promotion"+this.state.data.fileDownloadUri}
              />
            }
          >
            <p><h1>{this.state.data.title}</h1></p>
            <p><br /></p>
            <p><h1>Description :- </h1>{this.state.data.description}</p>
            <p><h1>Promotion Validity :- </h1>Promotion Valid from {this.state.data.startDate} to {this.state.data.endDate}</p>
            <p>Added on {this.state.data.addedDate}</p>
          </Card>
        </div>
        <br />
      </div>
    
    );
  }
}

export default Form.create()(DetailsView);
