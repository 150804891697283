export const simpleFormValuesToServerParams = values => {
  return {
    endCustomerPhoneNumber: values.cusPhoneNo || "",
    uniqueSerialNumber: values.uniqueSerialNumber || ""
  };
};

export const advanceFormValuesToServerParams = values => {
  const checkDate = {
    addedDateTime: {
      dateTagName: "addedDateTime",
      dateTagValue: "System Added Date"
    },
    lastModifiedDateTime: {
      dateTagName: "lastModifiedDateTime",
      dateTagValue: "Last Modified Date"
    }
  };
  return {
    endCustomerPhoneNumber: values.cusPhoneNo,
    warrantyState: values.warrantyStatus,
    dateTagName: values.checkDate
      ? checkDate[values.checkDate].dateTagName
      : null,
    dateTagValue: values.checkDate
      ? checkDate[values.checkDate].dateTagValue
      : null,
    startDate: values.startDate ? values.startDate.format("YYYY-MM-DD") : null,
    endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null
  };
};

export const transformSearchServerData = serverResult => {
  console.log("Server result", serverResult);
  const { content, totalElements, pageable } = serverResult;
  console.log("Available data", content);
  const transformedData = {
    data: [],
    pagination: {
      total: totalElements,
      current: totalElements ? pageable.pageNumber + 1 : 1
    }
  };
  transformedData.data = content;
  return transformedData;
};

export const transformToTableData = unpresentableData => {
  console.log("Unpresentable data", unpresentableData);
  return unpresentableData.map(obj => {
    return {
      key: obj.id,
      serial: obj.uniqueSerialNumber,
      cusPhone: obj.endCustomerPhoneNumber,
      selling: obj.sellDate,
      start: obj.warrantyStartDate,
      expire: obj.warrantyEndDate
    };
  });
};
