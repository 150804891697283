import React from "react";
import { Card, Row, Col } from "antd";
import AddUserForm from "./AddUserForm";
import { AppContext } from "../../main/contexts/app.context";

class CreateUser extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super(props);
    this.state = {
      userTypes: [],
      isDone: false,
      loading: false
    };
  }

  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={18}>
          <Card bordered={false}>
            <AddUserForm />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default CreateUser;
