import { combineReducers } from "redux";

import userReducer from "./user/user.reducer";
import userTypesReducer from "./user-types/user.types.reducer";
import warrantyMoreReducer from "./warranty-more/warranty-more.reducer";

export default combineReducers({
  user: userReducer,
  userTypes: userTypesReducer,
  warrantyMore: warrantyMoreReducer
});
