import React, { Component } from "react";
import { InputNumber, Input, Form, Checkbox } from "antd";

export const EditableContext = React.createContext();

export class EditableCell extends Component {
  getInput = (isChecked = false) => {
    if (this.props.inputType === "number") {
      return <InputNumber min={0} />;
    } else if (this.props.inputType === "check") {
      return <Checkbox></Checkbox>;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      adding,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;

    const formFieldName = adding ? `adding_${dataIndex}` : dataIndex;

    return (
      <td {...restProps}>
        {editing || adding ? (
          <Form.Item style={{ margin: 0 }}>
            {dataIndex !== "status"
              ? getFieldDecorator(formFieldName, {
                  rules: [
                    {
                      required: true,
                      message: `Please Input ${title}!`
                    }
                  ],
                  initialValue: record[dataIndex]
                })(this.getInput())
              : getFieldDecorator(formFieldName, {
                  valuePropName: "checked",
                  initialValue: record[dataIndex] === "true" ? true : false,
                  rules: []
                })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}
