import axios from "axios";
import cogoToast from "cogo-toast";
import Swal from 'sweetalert2'

export const getCode = phoneNo => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/account/user/lostpassword/sendcode?phoneNumber=${phoneNo}`)
    .then(function(response) {
      if (response.status === 201 || response.status === 200) {
      } else {
        Swal.fire(
          'Error!',
          'Wrong phone number.!',
          'error'
        )
      }
      resolve(response)
    })
    .catch(function(error) {
      if (error.response.data !== undefined && error.response.data !== null) {
        if (error.response.data.message !== undefined && error.response.data.message !== null) {
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data.message,
            'error'
          )
        }else{
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data,
            'error'
          )
        }
      }else {
        Swal.fire(
          'Error!',
          'Message : Unknown error while searching record',
          'error'
        )
      }
      reject(error)
    });
  })
};

export const sendCode = (code) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/account/user/lostpassword/evaluatecode?phoneNumber=${sessionStorage.getItem('vaifyPhoneNo')}&code=${code}`)
    .then(function(response) {
      if (response.status === 201 || response.status === 200) {

      } else {
        Swal.fire(
          'Error!',
          'Wrong code.!',
          'error'
        )
      }
      resolve(response)
    })
    .catch(function(error) {
      if (error.response.data !== undefined && error.response.data !== null) {
        if (error.response.data.message !== undefined && error.response.data.message !== null) {
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data.message,
            'error'
          )
        }else{
          Swal.fire(
            'Error!',
            'Message : '+ error.response.data,
            'error'
          )
        }
      }else {
        Swal.fire(
          'Error!',
          'Message : Unknown error while adding record',
          'error'
        )
      }
      reject(error)
    });
  })
};

export const resetPassword = (password) => {
  console.log(sessionStorage.getItem('newAuth'));
  return new Promise((resolve, reject) => {
    axios.post(`/api/account/user/lostpassword/reset?phoneNumber=${sessionStorage.getItem('vaifyPhoneNo')}&token=${sessionStorage.getItem('newAuth')}&password=${password}`,{
      headers: {
        common : {
            Authorization: "Bearer " +sessionStorage.getItem("newAuth")
        }
      }
    })
    .then(function(response) {
      if (response.status === 201 || response.status === 200) {

      } else {
        cogoToast.error("Wrong phone number.");
      }
      resolve(response)
    })
    .catch(function(error) {
      cogoToast.error("Server failed");
      console.log(error.response);
      reject(error)
    });
  })
};
