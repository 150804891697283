import React, { Component } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Spin,
  Upload,
  Icon,
  Card,
} from "antd";
import {
  addWarranty,
  checkIfSerialExists,
} from "../../../ApiCalls/warranty-call";
import "./AddWarrantyForm.styles.scss";
import { connect } from "react-redux";
import moment from "moment";
import { fetchUserTypesStartAsync } from "../../../Redux/user-types/user-types.actions";

class AddWarrantyForm extends Component {
  constructor(props) {
    super(props);

    this.serialInput = React.createRef();

    this.state = {
      sellingDate: "",
      loading: false,
      isPhoneDissabled: true,
      phoneFieldChecked: false,
      file: undefined,
      fileType: undefined,
      uploading: false,
      previewImage: undefined,
      showCamModal: false,
      isSerialValid: true,
    };
  }

  componentDidMount = () => {
    this.setState({ phoneFieldChecked: false });
    this.props.fetchUserTypesStartAsync();
    const authUserPhone = sessionStorage.getItem("cusPhone");
    this.props.form.setFieldsValue({
      phoneNo: authUserPhone,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  beforeUpload = async (file, fileList) => {
    console.log("File", file);
    if (!file.url && !file.preview) {
      try {
        file.preview = await this.getBase64(file);
        this.setState({ previewImage: file.preview });
        console.log("Preview ready", file.preview);
      } catch (error) {
        console.log("Preview Error", error);
      }
    }
    this.setState({ file: file, fileType: file.type.split("/")[1] });
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) return;
      if (!this.state.isSerialValid) return;
      this.setState({ loading: true });
      console.log("Form Values", values);
      console.log("File type", this.state.fileType);
      const { file } = this.state;
      const formData = new FormData();
      formData.append("invoiceImageFile", file);
      formData.append("uniqueSerialNumber", values.serialNo);
      formData.append("endCustomerPhoneNumber", values.phoneNo);
      formData.append("sellerId", sessionStorage.getItem("userId"));
      formData.append("sellDate", values.sellingDate.format("YYYY-MM-DD"));
      console.log("Form Data", formData.get("sellDate"));
      try {
        const addedWarranty = await addWarranty(formData);
        console.log("Saved Warranty", addedWarranty);
        this.setState({
          loading: false,
          file: undefined,
          previewImage: undefined,
        });
        form.resetFields();
      } catch (error) {
        console.error("Warranty add failed", error);
        this.setState({ loading: false });
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { userTypes } = this.props;
    const { phoneFieldChecked } = prevState;
    if (userTypes.length > 0 && !phoneFieldChecked) {
      this.setState({ phoneFieldChecked: true });
      this.setPoneFieldOnPermissionChange(userTypes);
    }
  };

  setPoneFieldOnPermissionChange = (userTypes) => {
    const endCustomer = userTypes.find(
      (userType) => userType.userType === "End Customer"
    );

    const authUserType = JSON.parse(sessionStorage.getItem("userTypeData"));

    console.log("authUserType", authUserType);
    console.log("endCustomer", endCustomer);

    if (endCustomer.permission <= authUserType.permission) {
      const authUserPhone = sessionStorage.getItem("cusPhone");
      this.props.form.setFieldsValue({
        phoneNo: authUserPhone,
      });
      this.setState({ isPhoneDissabled: true });
    } else {
      this.props.form.setFieldsValue({
        phoneNo: "",
      });
      this.setState({ isPhoneDissabled: false });
    }
  };

  setSerialInvalid = () => {
    const serialInputElm = this.serialInput.current.input;
    const containerDiv = serialInputElm.parentNode.parentNode;
    containerDiv.classList.remove("has-success");
    containerDiv.classList.add("has-error");
    this.setState({
      isSerialValid: false,
    });
  };

  setSerialvalid = () => {
    const serialInputElm = this.serialInput.current.input;
    const containerDiv = serialInputElm.parentNode.parentNode;
    containerDiv.classList.remove("has-error");
    containerDiv.classList.add("has-success");
    this.setState({
      isSerialValid: true,
    });
  };

  onSerialBlur = async (e) => {
    const { form } = this.props;
    const serialValue = form.getFieldValue("serialNo");

    console.log(serialValue);
    if (serialValue !== "") {
      try {
        const response = await checkIfSerialExists(serialValue);
        console.log(response);
        if (response.data === "No Result Found") {
          this.setSerialInvalid();
        } else {
          this.setSerialvalid();
        }
      } catch (error) {
        console.log(error);
        //alert("failed");
      }
    }
  };

  serialOnChange = () => {
    console.log("Serial Changed");
    this.setSerialvalid();
  };

  disabledDate = (current) => {
    return current > moment().endOf("day");
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isPhoneDissabled, previewImage, isSerialValid } = this.state;
    const serialInvalidMsg = "Serial not available!";

    return (
      <Spin spinning={this.state.loading}>
        <Form onSubmit={this.handleSubmit} className="AddWarrantyForm">
          <Row type="flex" justify="center">
            <Col xs={24}>
              <p className="formTitle">ADD WARRANTY</p>
              <div className="form_separtion">Item Details</div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24}>
              <Button
                block
                icon="qrcode"
                size="large"
                type="primary"
                onClick={this.props.qrOpen}
              >
                Scan QR
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Serial No" className="seriel-num-form-item">
                {getFieldDecorator("serialNo", {
                  initialValue: this.state.serialNo || this.props.serialNo,
                  rules: [
                    {
                      required: true,
                      message: "Please input serial number!",
                    },
                  ],
                })(
                  <Input
                    ref={this.serialInput}
                    onBlur={this.onSerialBlur}
                    onChange={this.serialOnChange}
                    size="large"
                    placeholder="Enter serial no"
                  />
                )}
                {!isSerialValid && (
                  <div className="ant-form-explain AddWarrantyForm-serialvalid-msg">
                    {serialInvalidMsg}
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Selling Date">
                {getFieldDecorator("sellingDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please select selling date!",
                    },
                  ],
                })(
                  <DatePicker
                    size="large"
                    style={{ width: "100%" }}
                    disabledDate={this.disabledDate}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="center" gutter={16}>
            <Col xs={24} md={12}>
              <Row>
                <Col>
                  <Form.Item label="Customer Phone No">
                    {getFieldDecorator("phoneNo", {
                      rules: [
                        {
                          required: true,
                          message: "Please input phone no!",
                        },
                        {
                          len: 10,
                          message: "Phone Number must be exactly 10 digits",
                        },
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input numbers only",
                        },
                      ],
                    })(
                      <Input
                        size="large"
                        placeholder="Enter phone no"
                        disabled={isPhoneDissabled}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="start" gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item>
                    <Upload
                      beforeUpload={this.beforeUpload}
                      showUploadList={false}
                    >
                      <Button type="primary" size="large">
                        <Icon type="upload" /> Select File
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="start">
                <Col xs={24}>
                  <Form.Item>
                    <Button size="large" type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Card
                className="AddWarrantyForm-img-preview"
                style={{ width: "100%" }}
              >
                {previewImage && (
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </Form>
      </Spin>
    );
  }
}

const mapStateToProps = ({ userTypes }) => ({
  userTypes: userTypes.types,
});
const mapDispatchToProps = (dispatch) => ({
  fetchUserTypesStartAsync: () => {
    dispatch(fetchUserTypesStartAsync());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(AddWarrantyForm));
