import React, { Component } from "react";
import {
  Form,
  Input,
  Icon,
  Row,
  Col,
  Button,
  Table,
  Select,
  DatePicker,
  Spin
} from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import {
  warrantyAdvanceSearch,
  warrantySimpleSearch
} from "../../../ApiCalls/warranty-call";

import {
  simpleFormValuesToServerParams,
  advanceFormValuesToServerParams,
  transformSearchServerData,
  transformToTableData
} from "./view-warranty.util";

import ViewWarrantyCSV from "./view-warranty-csv.component";

const { Option } = Select;

const columns = [
  {
    title: "Serial No",
    dataIndex: "serial",
    width: 120,
    key: "serial"
  },
  {
    title: "Customer Phone Number",
    dataIndex: "cusPhone",
    key: "cusPhone"
  },
  {
    title: "Selling Date",
    dataIndex: "selling",
    key: "selling"
  },
  {
    title: "Start Date",
    dataIndex: "start",
    key: "start"
  },
  {
    title: "Expire Date",
    dataIndex: "expire",
    key: "expire"
  },
  {
    title: "Action",
    key: "action",
    fixed: "right",
    width: 150,
    render: (text, record) => (
      <span>
        <NavLink
          to="/warranty/view-warranty-details"
          onClick={() => setSerialNo(record.serial)}
        >
          Change Status
        </NavLink>
      </span>
    )
  }
];

function setSerialNo(serial) {
  sessionStorage.setItem("serial", serial)
  sessionStorage.setItem("viewWarrantyBack", "searchWarranty")
}

class ViewWarrantyForm extends Component {
  state = {
    isSimple: true,
    isAdvance: false,
    data: [],
    loading: false,
    pagination: {},
    searchParams: {},
    isTableLoading: false
  };

  resetPagination = () => {
    this.setState({ pagination: { total: 0 } });
  };

  updatePagination = (current = 1, total) => {
    const pager = { ...this.state.pagination };
    pager.current = current;
    pager.total = total;
    pager.showTotal = (total, range) =>
      `${range[0]}-${range[1]} of ${total} items`;
    this.setState({
      pagination: pager
    });
  };

  searchAdvanceWarranty = async (values, currentPage = 1, perPage = 10) => {
    this.setState({ isTableLoading: true });
    console.log("Advanced User search Values", values);
    try {
      const searchResult = await warrantyAdvanceSearch(
        values,
        perPage,
        currentPage
      );
      const transformedData = transformSearchServerData(searchResult);
      const pagination = transformedData.pagination;
      this.updatePagination(pagination.current, pagination.total);
      console.log("Pagination", pagination);
      const tableData = transformToTableData(transformedData.data);
      this.setState({
        data: tableData || [],
        searchParams: { type: "advance", params: values },
        isTableLoading: false
      });
      console.log(tableData);
    } catch (error) {
      this.updatePagination(0);
      this.setState({
        data: [],
        advanceSearchParams: {},
        isTableLoading: false
      });
    }
  };

  searchSimpleWarranty = async (values, currentPage = 1, perPage = 10) => {
    this.setState({ isTableLoading: true });
    console.log("Simple User search Values", values);
    try {
      const searchResult = await warrantySimpleSearch(
        values,
        perPage,
        currentPage
      );
      const transformedData = transformSearchServerData(searchResult);
      console.log("Simple Search Result", searchResult);
      const pagination = transformedData.pagination;
      this.updatePagination(pagination.current, pagination.total);
      const tableData = transformToTableData(transformedData.data);
      this.setState({
        data: tableData || [],
        searchParams: { type: "simple", params: values },
        isTableLoading: false
      });
      console.log(tableData);
    } catch (error) {
      console.log("Simple Search Error", error);
      this.updatePagination(0);
      this.setState({
        data: [],
        searchParams: {},
        isTableLoading: false
      });
    }
  };

  onAdvanceWarrantySearch = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      console.log(values);
      const params = advanceFormValuesToServerParams(values);
      console.log("Parrams", params);
      this.searchAdvanceWarranty(params);
    });
  };

  onSimpleSearch = event => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      console.log(values);
      const params = simpleFormValuesToServerParams(values);
      console.log("Parrams", params);
      this.searchSimpleWarranty(params);
    });
  };

  updateTable = (pagination, filters, sorter) => {
    console.log("Updated Pagination", pagination);
    const { searchParams } = this.state;
    if (searchParams.type === "advance") {
      this.searchAdvanceWarranty(searchParams.params, pagination.current);
    } else if (searchParams.type === "simple") {
      this.searchSimpleWarranty(searchParams.params, pagination.current);
    }
  };

  openAdvance = e => {
    e.preventDefault();
    this.setState({ isAdvance: true, isSimple: false });
  };

  openSimple = e => {
    e.preventDefault();
    this.setState({ isAdvance: false, isSimple: true });
  };

  onCheckDateChange = value => {
    this.props.form.setFieldsValue({
      startDate: moment(new Date(), "YYYY-MM-DD").subtract(1, "months"),
      endDate: moment(new Date(), "YYYY-MM-DD")
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { getPermission } = this.props;

    const { searchParams } = this.state;

    function disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf("day");
    }

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <Form onSubmit={this.onSimpleSearch}>
            {getPermission("UI Pane", "warranty_search_simple") && (
              <Row type="flex" justify="center">
                <Col xs={24}>
                  <p className="formTitle">SEARCH WARRANTY</p>
                  {this.state.isSimple ? (
                    <div>
                      <div
                        className="form_separtion"
                        style={{ cursor: "pointer" }}
                      >
                        <Icon type="caret-down" /> Simple Search
                      </div>
                      <Row type="flex" justify="center" gutter={16}>
                        <Col xs={24} md={10}>
                          <Form.Item label="Phone No">
                            {getFieldDecorator(
                              "cusPhoneNo",
                              {}
                            )(
                              <Input
                                autoFocus
                                size="large"
                                placeholder="Enter phone no"
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={10}>
                          <Form.Item label="Unique Serial ID">
                            {getFieldDecorator(
                              "uniqueSerialNumber",
                              {}
                            )(
                              <Input
                                size="large"
                                placeholder="Enter serial id"
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex" justify="center">
                        <Col>
                          <Button
                            size="large"
                            type="primary"
                            icon="search"
                            htmlType="submit"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      className="form_separtion"
                      style={{ cursor: "pointer" }}
                      onClick={this.openSimple.bind(this)}
                    >
                      <Icon type="caret-right" /> Simple Search
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Form>
          {getPermission("UI Pane", "warranty_search_advance") && (
            <Form onSubmit={this.onAdvanceWarrantySearch}>
              <Row type="flex" justify="center">
                <Col xs={24}>
                  {this.state.isAdvance ? (
                    <div>
                      <div
                        className="form_separtion"
                        style={{ cursor: "pointer" }}
                      >
                        <Icon type="caret-down" /> Advance Search
                      </div>
                      <Row gutter={16}>
                        <Col xs={24} md={12}>
                          <Form.Item label="End Customer Phone No">
                            {getFieldDecorator(
                              "cusPhoneNo",
                              {}
                            )(
                              <Input
                                autoFocus
                                size="large"
                                placeholder="Enter phone no"
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                          <Form.Item label="Warranty Status">
                            {getFieldDecorator(
                              "warrantyStatus",
                              {}
                            )(
                              <Select
                                size="large"
                                placeholder="Please select a warranty status"
                                // onChange={this.setWarrantyState}
                              >
                                <Option value="REQUESTED_CLAIM">
                                  REQUESTED_CLAIM
                                </Option>
                                <Option value="VERIFIED">VERIFIED</Option>
                                <Option value="ACTIVATED">ACTIVATED</Option>
                                <Option value="VALIDATED_CLAIM">
                                  VALIDATED_CLAIM
                                </Option>
                                <Option value="CLAIMED">CLAIMED</Option>
                                <Option value="PENDING">PENDING</Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col xs={24} md={8}>
                          <Form.Item label="Check Date">
                            {getFieldDecorator("checkDate", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please select a check date."
                                }
                              ]
                            })(
                              <Select
                                size="large"
                                placeholder="Select check date"
                                onChange={this.onCheckDateChange}
                              >
                                <Option key="addedDateTime">
                                  System Added Date
                                </Option>
                                <Option key="lastModifiedDateTime">
                                  Last Modified Date
                                </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.Item label="Start Date">
                            {getFieldDecorator("startDate", {
                              required: true,
                              message: "Please select a start date."
                            })(
                              <DatePicker
                                size="large"
                                onChange={this.onChangeStartDate}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                          <Form.Item label="End Date">
                            {getFieldDecorator("endDate", {
                              required: true,
                              message: "Please select an end date."
                            })(
                              <DatePicker
                                size="large"
                                disabledDate={disabledDate}
                                onChange={this.onChangeEndDate}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row type="flex" justify="end">
                        <Col>
                          <Button
                            size="large"
                            type="primary"
                            icon="search"
                            htmlType="submit"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      className="form_separtion"
                      style={{ cursor: "pointer" }}
                      onClick={this.openAdvance.bind(this)}
                    >
                      <Icon type="caret-right" /> Advance Search
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          )}
          <Row type="flex" justify="center">
            <Col xs={24}>
              <br />
              <div className="table-container">
                <Table
                  columns={columns}
                  dataSource={this.state.data}
                  bordered
                  className="table-header"
                  loading={this.state.isTableLoading}
                  scroll={{ x: 1500 }}
                  pagination={this.state.pagination}
                  onChange={this.updateTable}
                />
              </div>
              <br />
            </Col>
          </Row>
        </Spin>
        <ViewWarrantyCSV searchParams={searchParams}></ViewWarrantyCSV>
      </div>
    );
  }
}

export default Form.create()(ViewWarrantyForm);
