export const transformAdvanceSearchServerData = serverResult => {
  console.log("Server result", serverResult);
  const { authorityUserPage, endCustomerPage } = serverResult;
  const dataPage = authorityUserPage || endCustomerPage;
  if (!dataPage) return null;
  console.log("Available data", dataPage);
  const transformedData = {
    data: [],
    pagination: {
      total: dataPage.totalElements,
      current: dataPage.pageable.pageNumber + 1 || 1
    }
  };
  transformedData.data = dataPage.content;
  return transformedData;
};

export const transformToTableData = unpresentableData => {
  console.log("Unpresentable data", unpresentableData);

  const presentableData = unpresentableData.map(obj => {
    return {
      key: obj.id,
      name: obj.person.firstName,
      phone: obj.person.primaryPhoneNumber,
      NIC: obj.person.noNIC
    };
  });

  return presentableData;
};
